const flags = [
  {
     "flagImagePos":"-1px -3180px",
     "alpha2":"af",
     "alpha3":"afg",
     "center":{
        "coords":[
           33,
           65
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1310px",
     "alpha2":"al",
     "alpha3":"alb",
     "center":{
        "coords":[
           41,
           20
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -681px",
     "alpha2":"dz",
     "alpha3":"dza",
     "center":{
        "coords":[
           28,
           3
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2058px",
     "alpha2":"as",
     "alpha3":"asm",
     "center":{
        "coords":[
           -14.3333,
           -170
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -766px",
     "alpha2":"ad",
     "alpha3":"and",
     "center":{
        "coords":[
           42.5,
           1.6
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2636px",
     "alpha2":"ao",
     "alpha3":"ago",
     "center":{
        "coords":[
           -12.5,
           18.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2687px",
     "alpha2":"ai",
     "alpha3":"aia",
     "center":{
        "coords":[
           18.25,
           -63.1667
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1140px",
     "alpha2":"ag",
     "alpha3":"atg",
     "center":{
        "coords":[
           17.05,
           -61.8
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3282px",
     "alpha2":"ar",
     "alpha3":"arg",
     "center":{
        "coords":[
           -34,
           -64
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -205px",
     "alpha2":"am",
     "alpha3":"arm",
     "center":{
        "coords":[
           40,
           45
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1021px",
     "alpha2":"aw",
     "alpha3":"abw",
     "center":{
        "coords":[
           12.5,
           -69.9667
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -86px",
     "alpha2":"ac",
     "alpha3":"asc",
     "center":{
        "coords":[
           33,
           65
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2279px",
     "alpha2":"au",
     "alpha3":"aus",
     "center":{
        "coords":[
           -27,
           133
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1735px",
     "alpha2":"at",
     "alpha3":"aut",
     "center":{
        "coords":[
           47.3333,
           13.3333
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1599px",
     "alpha2":"az",
     "alpha3":"aze",
     "center":{
        "coords":[
           40.5,
           47.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -460px",
     "alpha2":"bs",
     "alpha3":"bhs",
     "center":{
        "coords":[
           24.25,
           -76
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1956px",
     "alpha2":"bh",
     "alpha3":"bhr",
     "center":{
        "coords":[
           26,
           50.55
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2364px",
     "alpha2":"bd",
     "alpha3":"bgd",
     "center":{
        "coords":[
           24,
           90
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2075px",
     "alpha2":"bb",
     "alpha3":"brb",
     "center":{
        "coords":[
           13.1667,
           -59.5333
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1412px",
     "alpha2":"by",
     "alpha3":"blr",
     "center":{
        "coords":[
           53,
           28
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1px",
     "alpha2":"be",
     "alpha3":"bel",
     "center":{
        "coords":[
           50.8333,
           4
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -613px",
     "alpha2":"bz",
     "alpha3":"blz",
     "center":{
        "coords":[
           17.25,
           -88.75
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1684px",
     "alpha2":"bj",
     "alpha3":"ben",
     "center":{
        "coords":[
           9.5,
           2.25
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2585px",
     "alpha2":"bm",
     "alpha3":"bmu",
     "center":{
        "coords":[
           32.3333,
           -64.75
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2483px",
     "alpha2":"bt",
     "alpha3":"btn",
     "center":{
        "coords":[
           27.5,
           90.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2177px",
     "alpha2":"bo",
     "alpha3":"bol",
     "center":{
        "coords":[
           -17,
           -65
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2092px",
     "alpha2":"ba",
     "alpha3":"bih",
     "center":{
        "coords":[
           44,
           18
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3724px",
     "alpha2":"bw",
     "alpha3":"bwa",
     "center":{
        "coords":[
           -22,
           24
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1004px",
     "alpha2":"br",
     "alpha3":"bra",
     "center":{
        "coords":[
           -10,
           -55
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -86px",
     "alpha2":"io",
     "alpha3":"iot",
     "center":{
        "coords":[
           -6,
           71.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1854px",
     "alpha2":"vg",
     "alpha3":"vgb",
     "center":{
        "coords":[
           18.5,
           -64.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2228px",
     "alpha2":"bn",
     "alpha3":"brn",
     "center":{
        "coords":[
           4.5,
           114.6667
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3537px",
     "alpha2":"bg",
     "alpha3":"bgr",
     "center":{
        "coords":[
           43,
           25
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -953px",
     "alpha2":"bf",
     "alpha3":"bfa",
     "center":{
        "coords":[
           13,
           -2
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2551px",
     "alpha2":"bi",
     "alpha3":"bdi",
     "center":{
        "coords":[
           -3.5,
           30
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -290px",
     "alpha2":"kh",
     "alpha3":"khm",
     "center":{
        "coords":[
           13,
           105
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2806px",
     "alpha2":"cm",
     "alpha3":"cmr",
     "center":{
        "coords":[
           6,
           12
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1803px",
     "alpha2":"ca",
     "alpha3":"can",
     "center":{
        "coords":[
           60,
           -95
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3639px",
     "alpha2":"cv",
     "alpha3":"cpv",
     "center":{
        "coords":[
           16,
           -24
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3741px",
     "alpha2":"bq",
     "alpha3":"bes",
     "center":{
        "coords":[
           37,
           -95
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -375px",
     "alpha2":"ky",
     "alpha3":"cym",
     "center":{
        "coords":[
           19.5,
           -80.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2466px",
     "alpha2":"cf",
     "alpha3":"caf",
     "center":{
        "coords":[
           7,
           21
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1055px",
     "alpha2":"td",
     "alpha3":"tcd",
     "center":{
        "coords":[
           15,
           19
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1752px",
     "alpha2":"cl",
     "alpha3":"chl",
     "center":{
        "coords":[
           -30,
           -71
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1072px",
     "alpha2":"cn",
     "alpha3":"chn",
     "center":{
        "coords":[
           35,
           105
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -409px",
     "alpha2":"co",
     "alpha3":"col",
     "center":{
        "coords":[
           4,
           -72
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1871px",
     "alpha2":"km",
     "alpha3":"com",
     "center":{
        "coords":[
           -12.1667,
           44.25
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2398px",
     "alpha2":"cg",
     "alpha3":"cog",
     "center":{
        "coords":[
           -1,
           15
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1990px",
     "alpha2":"cd",
     "alpha3":"cod",
     "center":{
        "coords":[
           0,
           25
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3112px",
     "alpha2":"ck",
     "alpha3":"cok",
     "center":{
        "coords":[
           -21.2333,
           -159.7667
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2857px",
     "alpha2":"cr",
     "alpha3":"cri",
     "center":{
        "coords":[
           10,
           -84
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2194px",
     "alpha2":"ci",
     "alpha3":"civ",
     "center":{
        "coords":[
           8,
           -5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1174px",
     "alpha2":"hr",
     "alpha3":"hrv",
     "center":{
        "coords":[
           45.1667,
           15.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -987px",
     "alpha2":"cu",
     "alpha3":"cub",
     "center":{
        "coords":[
           21.5,
           -80
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3758px",
     "alpha2":"cw",
     "alpha3":"cuw",
     "center":{
        "coords":[
           36,
           -95
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -732px",
     "alpha2":"cy",
     "alpha3":"cyp",
     "center":{
        "coords":[
           35,
           33
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3095px",
     "alpha2":"cz",
     "alpha3":"cze",
     "center":{
        "coords":[
           49.75,
           15.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1820px",
     "alpha2":"dk",
     "alpha3":"dnk",
     "center":{
        "coords":[
           56,
           10
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2874px",
     "alpha2":"dj",
     "alpha3":"dji",
     "center":{
        "coords":[
           11.5,
           43
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3350px",
     "alpha2":"dm",
     "alpha3":"dma",
     "center":{
        "coords":[
           15.4167,
           -61.3333
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2007px",
     "alpha2":"do",
     "alpha3":"dom",
     "center":{
        "coords":[
           19,
           -70.6667
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1531px",
     "alpha2":"ec",
     "alpha3":"ecu",
     "center":{
        "coords":[
           -2,
           -77.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3027px",
     "alpha2":"eg",
     "alpha3":"egy",
     "center":{
        "coords":[
           27,
           30
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2160px",
     "alpha2":"sv",
     "alpha3":"slv",
     "center":{
        "coords":[
           13.8333,
           -88.9167
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1973px",
     "alpha2":"gq",
     "alpha3":"gnq",
     "center":{
        "coords":[
           2,
           10
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -936px",
     "alpha2":"er",
     "alpha3":"eri",
     "center":{
        "coords":[
           15,
           39
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3333px",
     "alpha2":"ee",
     "alpha3":"est",
     "center":{
        "coords":[
           59,
           26
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3129px",
     "alpha2":"sz",
     "alpha3":"swz",
     "center":{
        "coords":[
           -26.5,
           31.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3367px",
     "alpha2":"et",
     "alpha3":"eth",
     "center":{
        "coords":[
           8,
           38
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3809px",
     "alpha2":"fk",
     "alpha3":"flk",
     "center":{
        "coords":[
           -51.75,
           -59
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1429px",
     "alpha2":"fo",
     "alpha3":"fro",
     "center":{
        "coords":[
           62,
           -7
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2500px",
     "alpha2":"fj",
     "alpha3":"fji",
     "center":{
        "coords":[
           -18,
           175
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2568px",
     "alpha2":"fi",
     "alpha3":"fin",
     "center":{
        "coords":[
           64,
           26
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -324px",
     "alpha2":"fr",
     "alpha3":"fra",
     "center":{
        "coords":[
           46,
           2
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -324px",
     "alpha2":"gf",
     "alpha3":"guf",
     "center":{
        "coords":[
           4,
           -53
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2262px",
     "alpha2":"pf",
     "alpha3":"pyf",
     "center":{
        "coords":[
           -15,
           -140
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1157px",
     "alpha2":"ga",
     "alpha3":"gab",
     "center":{
        "coords":[
           -1,
           11.75
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -817px",
     "alpha2":"gm",
     "alpha3":"gmb",
     "center":{
        "coords":[
           13.4667,
           -16.5667
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1123px",
     "alpha2":"ge",
     "alpha3":"geo",
     "center":{
        "coords":[
           42,
           43.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3452px",
     "alpha2":"de",
     "alpha3":"deu",
     "center":{
        "coords":[
           51,
           9
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2891px",
     "alpha2":"gh",
     "alpha3":"gha",
     "center":{
        "coords":[
           8,
           -2
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -341px",
     "alpha2":"gi",
     "alpha3":"gib",
     "center":{
        "coords":[
           36.1833,
           -5.3667
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -188px",
     "alpha2":"gr",
     "alpha3":"grc",
     "center":{
        "coords":[
           39,
           22
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2347px",
     "alpha2":"gl",
     "alpha3":"grl",
     "center":{
        "coords":[
           72,
           -40
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3316px",
     "alpha2":"gd",
     "alpha3":"grd",
     "center":{
        "coords":[
           12.1167,
           -61.6667
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -511px",
     "alpha2":"gp",
     "alpha3":"glp",
     "center":{
        "coords":[
           16.25,
           -61.5833
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3265px",
     "alpha2":"gu",
     "alpha3":"gum",
     "center":{
        "coords":[
           13.4667,
           144.7833
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1208px",
     "alpha2":"gt",
     "alpha3":"gtm",
     "center":{
        "coords":[
           15.5,
           -90.25
        ],
        "zoom":12
     }
  },
  {
     "flagImagePos":"-1px -3520px",
     "alpha2":"gn",
     "alpha3":"gin",
     "center":{
        "coords":[
           11,
           -10
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2602px",
     "alpha2":"gw",
     "alpha3":"gnb",
     "center":{
        "coords":[
           12,
           -15
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1038px",
     "alpha2":"gy",
     "alpha3":"guy",
     "center":{
        "coords":[
           5,
           -59
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -392px",
     "alpha2":"ht",
     "alpha3":"hti",
     "center":{
        "coords":[
           19,
           -72.4167
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2959px",
     "alpha2":"hn",
     "alpha3":"hnd",
     "center":{
        "coords":[
           15,
           -86.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3707px",
     "alpha2":"hk",
     "alpha3":"hkg",
     "center":{
        "coords":[
           22.25,
           114.1667
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -902px",
     "alpha2":"hu",
     "alpha3":"hun",
     "center":{
        "coords":[
           47,
           20
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2704px",
     "alpha2":"is",
     "alpha3":"isl",
     "center":{
        "coords":[
           65,
           -18
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2245px",
     "alpha2":"in",
     "alpha3":"ind",
     "center":{
        "coords":[
           20,
           77
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2653px",
     "alpha2":"id",
     "alpha3":"idn",
     "center":{
        "coords":[
           -5,
           120
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2738px",
     "alpha2":"ir",
     "alpha3":"irn",
     "center":{
        "coords":[
           32,
           53
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -851px",
     "alpha2":"iq",
     "alpha3":"irq",
     "center":{
        "coords":[
           33,
           44
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2670px",
     "alpha2":"ie",
     "alpha3":"irl",
     "center":{
        "coords":[
           53,
           -8
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -426px",
     "alpha2":"il",
     "alpha3":"isr",
     "center":{
        "coords":[
           31.5,
           34.75
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -154px",
     "alpha2":"it",
     "alpha3":"ita",
     "center":{
        "coords":[
           42.8333,
           12.8333
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2296px",
     "alpha2":"jm",
     "alpha3":"jam",
     "center":{
        "coords":[
           18.25,
           -77.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -528px",
     "alpha2":"jp",
     "alpha3":"jpn",
     "center":{
        "coords":[
           36,
           138
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1905px",
     "alpha2":"jo",
     "alpha3":"jor",
     "center":{
        "coords":[
           31,
           36
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1565px",
     "alpha2":"kz",
     "alpha3":"kaz",
     "center":{
        "coords":[
           48,
           68
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3605px",
     "alpha2":"ke",
     "alpha3":"ken",
     "center":{
        "coords":[
           1,
           38
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -477px",
     "alpha2":"ki",
     "alpha3":"kir",
     "center":{
        "coords":[
           1.4167,
           173
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3860px",
     "alpha2":"xk",
     "alpha3":"xxk",
     "center":{
        "coords":[
           42,
           21
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3435px",
     "alpha2":"kw",
     "alpha3":"kwt",
     "center":{
        "coords":[
           29.3375,
           47.6581
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2143px",
     "alpha2":"kg",
     "alpha3":"kgz",
     "center":{
        "coords":[
           41,
           75
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -562px",
     "alpha2":"la",
     "alpha3":"lao",
     "center":{
        "coords":[
           18,
           105
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2619px",
     "alpha2":"lv",
     "alpha3":"lva",
     "center":{
        "coords":[
           57,
           25
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1616px",
     "alpha2":"lb",
     "alpha3":"lbn",
     "center":{
        "coords":[
           33.8333,
           35.8333
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3010px",
     "alpha2":"ls",
     "alpha3":"lso",
     "center":{
        "coords":[
           -29.5,
           28.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2823px",
     "alpha2":"lr",
     "alpha3":"lbr",
     "center":{
        "coords":[
           6.5,
           -9.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -137px",
     "alpha2":"ly",
     "alpha3":"lby",
     "center":{
        "coords":[
           25,
           17
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1276px",
     "alpha2":"li",
     "alpha3":"lie",
     "center":{
        "coords":[
           47.1667,
           9.5333
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1446px",
     "alpha2":"lt",
     "alpha3":"ltu",
     "center":{
        "coords":[
           56,
           24
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1922px",
     "alpha2":"lu",
     "alpha3":"lux",
     "center":{
        "coords":[
           49.75,
           6.1667
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3554px",
     "alpha2":"mo",
     "alpha3":"mac",
     "center":{
        "coords":[
           22.1667,
           113.55
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1667px",
     "alpha2":"mg",
     "alpha3":"mdg",
     "center":{
        "coords":[
           -20,
           47
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2942px",
     "alpha2":"mw",
     "alpha3":"mwi",
     "center":{
        "coords":[
           -13.5,
           34
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2517px",
     "alpha2":"my",
     "alpha3":"mys",
     "center":{
        "coords":[
           2.5,
           112.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -800px",
     "alpha2":"mv",
     "alpha3":"mdv",
     "center":{
        "coords":[
           3.25,
           73
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3469px",
     "alpha2":"ml",
     "alpha3":"mli",
     "center":{
        "coords":[
           17,
           -4
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2041px",
     "alpha2":"mt",
     "alpha3":"mlt",
     "center":{
        "coords":[
           35.8333,
           14.5833
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1463px",
     "alpha2":"mh",
     "alpha3":"mhl",
     "center":{
        "coords":[
           9,
           168
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -239px",
     "alpha2":"mq",
     "alpha3":"mtq",
     "center":{
        "coords":[
           14.6667,
           -61
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -307px",
     "alpha2":"mr",
     "alpha3":"mrt",
     "center":{
        "coords":[
           20,
           -12
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2993px",
     "alpha2":"mu",
     "alpha3":"mus",
     "center":{
        "coords":[
           -20.2833,
           57.55
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2755px",
     "alpha2":"mx",
     "alpha3":"mex",
     "center":{
        "coords":[
           23,
           -102
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2313px",
     "alpha2":"fm",
     "alpha3":"fsm",
     "center":{
        "coords":[
           6.9167,
           158.25
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3690px",
     "alpha2":"md",
     "alpha3":"mda",
     "center":{
        "coords":[
           47,
           29
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1191px",
     "alpha2":"mc",
     "alpha3":"mco",
     "center":{
        "coords":[
           43.7333,
           7.4
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3503px",
     "alpha2":"mn",
     "alpha3":"mng",
     "center":{
        "coords":[
           46,
           105
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2976px",
     "alpha2":"me",
     "alpha3":"mne",
     "center":{
        "coords":[
           42,
           19
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -749px",
     "alpha2":"ms",
     "alpha3":"msr",
     "center":{
        "coords":[
           16.75,
           -62.2
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3214px",
     "alpha2":"ma",
     "alpha3":"mar",
     "center":{
        "coords":[
           32,
           -5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -834px",
     "alpha2":"mz",
     "alpha3":"moz",
     "center":{
        "coords":[
           -18.25,
           35
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -18px",
     "alpha2":"mm",
     "alpha3":"mmr",
     "center":{
        "coords":[
           22,
           98
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2534px",
     "alpha2":"na",
     "alpha3":"nam",
     "center":{
        "coords":[
           -22,
           17
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2330px",
     "alpha2":"nr",
     "alpha3":"nru",
     "center":{
        "coords":[
           -0.5333,
           166.9167
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -120px",
     "alpha2":"np",
     "alpha3":"npl",
     "center":{
        "coords":[
           28,
           84
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1888px",
     "alpha2":"nl",
     "alpha3":"nld",
     "center":{
        "coords":[
           52.5,
           5.75
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1650px",
     "alpha2":"nc",
     "alpha3":"ncl",
     "center":{
        "coords":[
           -21.5,
           165.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2024px",
     "alpha2":"nz",
     "alpha3":"nzl",
     "center":{
        "coords":[
           -41,
           174
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -171px",
     "alpha2":"ni",
     "alpha3":"nic",
     "center":{
        "coords":[
           13,
           -85
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -715px",
     "alpha2":"ne",
     "alpha3":"ner",
     "center":{
        "coords":[
           16,
           8
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3418px",
     "alpha2":"ng",
     "alpha3":"nga",
     "center":{
        "coords":[
           10,
           8
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2840px",
     "alpha2":"nu",
     "alpha3":"niu",
     "center":{
        "coords":[
           -19.0333,
           -169.8667
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -256px",
     "alpha2":"nf",
     "alpha3":"nfk",
     "center":{
        "coords":[
           -29.0333,
           167.95
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2415px",
     "alpha2":"kp",
     "alpha3":"prk",
     "center":{
        "coords":[
           40,
           127
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1769px",
     "alpha2":"mk",
     "alpha3":"mkd",
     "center":{
        "coords":[
           41.8333,
           22
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -919px",
     "alpha2":"mp",
     "alpha3":"mnp",
     "center":{
        "coords":[
           15.2,
           145.75
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1089px",
     "alpha2":"no",
     "alpha3":"nor",
     "center":{
        "coords":[
           62,
           10
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3384px",
     "alpha2":"om",
     "alpha3":"omn",
     "center":{
        "coords":[
           21,
           57
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2772px",
     "alpha2":"pk",
     "alpha3":"pak",
     "center":{
        "coords":[
           30,
           70
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -273px",
     "alpha2":"pw",
     "alpha3":"plw",
     "center":{
        "coords":[
           7.5,
           134.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1548px",
     "alpha2":"ps",
     "alpha3":"pse",
     "center":{
        "coords":[
           32,
           35.25
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1106px",
     "alpha2":"pa",
     "alpha3":"pan",
     "center":{
        "coords":[
           9,
           -80
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1939px",
     "alpha2":"pg",
     "alpha3":"png",
     "center":{
        "coords":[
           -6,
           147
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3231px",
     "alpha2":"py",
     "alpha3":"pry",
     "center":{
        "coords":[
           -23,
           -58
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1225px",
     "alpha2":"pe",
     "alpha3":"per",
     "center":{
        "coords":[
           -10,
           -76
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2432px",
     "alpha2":"ph",
     "alpha3":"phl",
     "center":{
        "coords":[
           13,
           122
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1514px",
     "alpha2":"pl",
     "alpha3":"pol",
     "center":{
        "coords":[
           52,
           20
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -664px",
     "alpha2":"pt",
     "alpha3":"prt",
     "center":{
        "coords":[
           39.5,
           -8
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -596px",
     "alpha2":"pr",
     "alpha3":"pri",
     "center":{
        "coords":[
           18.25,
           -66.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -579px",
     "alpha2":"qa",
     "alpha3":"qat",
     "center":{
        "coords":[
           25.5,
           51.25
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -324px",
     "alpha2":"re",
     "alpha3":"reu",
     "center":{
        "coords":[
           -21.1,
           55.6
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -885px",
     "alpha2":"ro",
     "alpha3":"rou",
     "center":{
        "coords":[
           46,
           25
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -868px",
     "alpha2":"ru",
     "alpha3":"rus",
     "center":{
        "coords":[
           60,
           100
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3673px",
     "alpha2":"rw",
     "alpha3":"rwa",
     "center":{
        "coords":[
           -2,
           30
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3163px",
     "alpha2":"ws",
     "alpha3":"wsm",
     "center":{
        "coords":[
           -13.5833,
           -172.3333
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2908px",
     "alpha2":"sm",
     "alpha3":"smr",
     "center":{
        "coords":[
           43.7667,
           12.4167
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3299px",
     "alpha2":"st",
     "alpha3":"stp",
     "center":{
        "coords":[
           1,
           7
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -52px",
     "alpha2":"sa",
     "alpha3":"sau",
     "center":{
        "coords":[
           25,
           45
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2925px",
     "alpha2":"sn",
     "alpha3":"sen",
     "center":{
        "coords":[
           14,
           -14
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3401px",
     "alpha2":"rs",
     "alpha3":"srb",
     "center":{
        "coords":[
           44,
           21
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1327px",
     "alpha2":"sc",
     "alpha3":"syc",
     "center":{
        "coords":[
           -4.5833,
           55.6667
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -970px",
     "alpha2":"sl",
     "alpha3":"sle",
     "center":{
        "coords":[
           8.5,
           -11.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -35px",
     "alpha2":"sg",
     "alpha3":"sgp",
     "center":{
        "coords":[
           1.3667,
           103.8
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3826px",
     "alpha2":"sx",
     "alpha3":"sxm",
     "center":{
        "coords":[
           18,
           -63
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3044px",
     "alpha2":"sk",
     "alpha3":"svk",
     "center":{
        "coords":[
           48.6667,
           19.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1582px",
     "alpha2":"si",
     "alpha3":"svn",
     "center":{
        "coords":[
           46,
           15
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1361px",
     "alpha2":"sb",
     "alpha3":"slb",
     "center":{
        "coords":[
           -8,
           159
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1786px",
     "alpha2":"so",
     "alpha3":"som",
     "center":{
        "coords":[
           10,
           49
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3248px",
     "alpha2":"za",
     "alpha3":"zaf",
     "center":{
        "coords":[
           -29,
           24
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3078px",
     "alpha2":"kr",
     "alpha3":"kor",
     "center":{
        "coords":[
           37,
           127.5
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3775px",
     "alpha2":"ss",
     "alpha3":"ssd",
     "center":{
        "coords":[
           36,
           -95
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1480px",
     "alpha2":"es",
     "alpha3":"esp",
     "center":{
        "coords":[
           40,
           -4
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3622px",
     "alpha2":"lk",
     "alpha3":"lka",
     "center":{
        "coords":[
           7,
           81
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -324px",
     "alpha2":"bl",
     "alpha3":"blm",
     "center":{
        "coords":[
           17,
           -62
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -630px",
     "alpha2":"sh",
     "alpha3":"shn",
     "center":{
        "coords":[
           -15.9333,
           -5.7
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -103px",
     "alpha2":"kn",
     "alpha3":"kna",
     "center":{
        "coords":[
           17.3333,
           -62.75
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1837px",
     "alpha2":"lc",
     "alpha3":"lca",
     "center":{
        "coords":[
           13.8833,
           -61.1333
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -86px",
     "alpha2":"mf",
     "alpha3":"maf",
     "center":{
        "coords":[
           18,
           -63
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1378px",
     "alpha2":"pm",
     "alpha3":"spm",
     "center":{
        "coords":[
           46.8333,
           -56.3333
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3588px",
     "alpha2":"vc",
     "alpha3":"vct",
     "center":{
        "coords":[
           13.25,
           -61.2
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -443px",
     "alpha2":"sd",
     "alpha3":"sdn",
     "center":{
        "coords":[
           15,
           30
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3656px",
     "alpha2":"sr",
     "alpha3":"sur",
     "center":{
        "coords":[
           4,
           -56
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -494px",
     "alpha2":"se",
     "alpha3":"swe",
     "center":{
        "coords":[
           62,
           15
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1718px",
     "alpha2":"ch",
     "alpha3":"che",
     "center":{
        "coords":[
           47,
           8
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2449px",
     "alpha2":"sy",
     "alpha3":"syr",
     "center":{
        "coords":[
           35,
           38
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -647px",
     "alpha2":"tw",
     "alpha3":"twn",
     "center":{
        "coords":[
           23.5,
           121
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -222px",
     "alpha2":"tj",
     "alpha3":"tjk",
     "center":{
        "coords":[
           39,
           71
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3146px",
     "alpha2":"tz",
     "alpha3":"tza",
     "center":{
        "coords":[
           -6,
           35
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1242px",
     "alpha2":"th",
     "alpha3":"tha",
     "center":{
        "coords":[
           15,
           100
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3843px",
     "alpha2":"tl",
     "alpha3":"tls",
     "center":{
        "coords":[
           -8.55,
           125.5167
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -783px",
     "alpha2":"tg",
     "alpha3":"tgo",
     "center":{
        "coords":[
           8,
           1.1667
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3792px",
     "alpha2":"tk",
     "alpha3":"tkl",
     "center":{
        "coords":[
           -9,
           -172
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1395px",
     "alpha2":"to",
     "alpha3":"ton",
     "center":{
        "coords":[
           -20,
           -175
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -545px",
     "alpha2":"tt",
     "alpha3":"tto",
     "center":{
        "coords":[
           11,
           -61
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -698px",
     "alpha2":"tn",
     "alpha3":"tun",
     "center":{
        "coords":[
           34,
           9
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2126px",
     "alpha2":"tr",
     "alpha3":"tur",
     "center":{
        "coords":[
           39,
           35
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3486px",
     "alpha2":"tm",
     "alpha3":"tkm",
     "center":{
        "coords":[
           40,
           60
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1701px",
     "alpha2":"tc",
     "alpha3":"tca",
     "center":{
        "coords":[
           21.75,
           -71.5833
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -358px",
     "alpha2":"tv",
     "alpha3":"tuv",
     "center":{
        "coords":[
           -8,
           178
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2381px",
     "alpha2":"vi",
     "alpha3":"vir",
     "center":{
        "coords":[
           18.3333,
           -64.8333
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1497px",
     "alpha2":"ug",
     "alpha3":"uga",
     "center":{
        "coords":[
           1,
           32
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2721px",
     "alpha2":"ua",
     "alpha3":"ukr",
     "center":{
        "coords":[
           49,
           32
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3061px",
     "alpha2":"ae",
     "alpha3":"are",
     "center":{
        "coords":[
           24,
           54
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -86px",
     "alpha2":"gb",
     "alpha3":"gbr",
     "center":{
        "coords":[
           54,
           -2
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -69px",
     "alpha2":"us",
     "alpha3":"usa",
     "center":{
        "coords":[
           38,
           -97
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3571px",
     "alpha2":"uy",
     "alpha3":"ury",
     "center":{
        "coords":[
           -33,
           -56
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1293px",
     "alpha2":"uz",
     "alpha3":"uzb",
     "center":{
        "coords":[
           41,
           64
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1633px",
     "alpha2":"vu",
     "alpha3":"vut",
     "center":{
        "coords":[
           -16,
           167
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -3197px",
     "alpha2":"va",
     "alpha3":"vat",
     "center":{
        "coords":[
           41.9,
           12.45
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1344px",
     "alpha2":"ve",
     "alpha3":"ven",
     "center":{
        "coords":[
           8,
           -66
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -1259px",
     "alpha2":"vn",
     "alpha3":"vnm",
     "center":{
        "coords":[
           16,
           106
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -324px",
     "alpha2":"wf",
     "alpha3":"wlf",
     "center":{
        "coords":[
           -13.3,
           -176.2
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2211px",
     "alpha2":"ye",
     "alpha3":"yem",
     "center":{
        "coords":[
           15,
           48
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2109px",
     "alpha2":"zm",
     "alpha3":"zmb",
     "center":{
        "coords":[
           -15,
           30
        ],
        "zoom":8
     }
  },
  {
     "flagImagePos":"-1px -2789px",
     "alpha2":"zw",
     "alpha3":"zwe",
     "center":{
        "coords":[
           -20,
           30
        ],
        "zoom":8
     }
  }
];


export const getFlagPosition = (iso) => flags.find(({alpha2,alpha3}) =>  alpha2=== iso.toLowerCase()||alpha3=== iso.toLowerCase())?.flagImagePos;
export const getCenterCountry = (iso) => flags.find(({alpha2,alpha3}) =>  alpha2=== iso.toLowerCase()||alpha3=== iso.toLowerCase())?.center;

