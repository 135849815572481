import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

export const getRoles = createAsyncThunk(
  'selectsData/roles/getRoles',
  async (params, { dispatch, getState }) => {
    const response = await axios.get(`${ApiRoutes.rolesListAll(params.wsId)}all/`);
    const data = await response.data;

    dispatch(setIsRolesLoaded(true));

    return data;
  }
);

const rolesAdapter = createEntityAdapter({});

export const selectSearchText = ({ selectsData }) => selectsData.roles.searchText;

export const selectRoles = ({
  selectsData: {
    roles: { entities },
  },
}) => Object.keys(entities).map((key) => entities[key]);

export const { selectById: selectRoleById } = rolesAdapter.getSelectors(
  ({ selectsData }) => selectsData.roles
);

const initialState = rolesAdapter.getInitialState({
  isRolesLoaded: false,
});

const rolesSlice = createSlice({
  name: 'selectsData/roles',
  initialState,
  reducers: {
    setIsRolesLoaded: (state, action) => {
      state.isRolesLoaded = action.payload;
    },
    resetRolesState: () => initialState,
  },
  extraReducers: {
    [getRoles.fulfilled]: rolesAdapter.setAll,
  },
});

export const { setIsRolesLoaded, resetRolesState } = rolesSlice.actions;

export default rolesSlice.reducer;
