import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import { format, parseISO } from 'date-fns';
import { roleChecker } from 'app/store/userSlice';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function InformationTab(props) {
  const methods = useFormContext();
  const { control, formState, getValues } = methods;
  const { errors } = formState;
  const { t } = useTranslation('managementApp');
  const { roleId } = useParams();
  const checkRole = useSelector(roleChecker);

  return (
    <div className="flex flex-wrap p-24">
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="role-name"
            className="mb-24"
            label={t('NAME')}
            autoFocus
            error={!!errors.name}
            helperText={errors?.name?.message}
            variant="outlined"
            required
            fullWidth
          />
        )}
      />

      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <TextField {...field} label={t('DESCRIPTION')} multiline variant="outlined" fullWidth />
        )}
      />

      {roleId !== 'new' && checkRole('platform_support') && (
        <div className="py-8">
          <div className="w-full py-10">
            <Divider />
          </div>
          {getValues('date_created') && (
            <div>
              <Typography variant="caption" className="font-medium">
                {t('CREATION_DATE')}:
              </Typography>
              <Typography variant="caption">
                {format(parseISO(getValues('date_created')), 'dd/MM/y hh:mm aaaa ')}
              </Typography>
            </div>
          )}
          {getValues('date_edited') && (
            <div>
              <Typography variant="caption" className="font-medium">
                {t('EDITION_DATE')}:
              </Typography>
              <Typography variant="caption">
                {format(parseISO(getValues('date_edited')), 'dd/MM/y hh:mm aaaa ')}
              </Typography>
            </div>
          )}
          <div>
            <Typography variant="caption" className="font-medium">
              ID:
            </Typography>
            <Typography variant="caption">{getValues('id')}</Typography>
          </div>
        </div>
      )}
    </div>
  );
}

export default InformationTab;
