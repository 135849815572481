/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRoutes from '../services/ApiRoutes';
import { authRoles } from '../auth';
import { checkAccess } from '../auth/authRoles';

const initialState = { is_superuser: false, permissions: [] };

export const getuserWorkspacePermissions = createAsyncThunk(
  'userWorkspacePermissions/getuserWorkspacePermissions',
  async (ws, { dispatch, getState }) => {
    const {
      user: { role },
    } = getState();

    if (authRoles.org.includes(role) || authRoles.platform.includes(role)) {
      // the user does not need permissions
      return initialState;
    }

    const response = await axios.get(ApiRoutes.getMyPermissions(ws));

    const data = await response.data;

    return data;
  }
);

export const permissionChecker =
  ({ userWorkspacePermissions, user: { role } }) =>
  (permission) =>
    checkAccess(permission, userWorkspacePermissions, role);

export const selectuserWorkspacePermissions = ({ userWorkspacePermissions }) =>
  userWorkspacePermissions;

const userWorkspacePermissionsSlice = createSlice({
  name: 'userWorkspacePermissions',
  initialState,
  reducers: {
    resetuserWorkspacePermissions: (state, action) => initialState,
  },
  extraReducers: {
    [getuserWorkspacePermissions.fulfilled]: (state, action) => action.payload,
  },
});

export const { resetuserWorkspacePermissions } = userWorkspacePermissionsSlice.actions;

export default userWorkspacePermissionsSlice.reducer;
