/* eslint-disable camelcase */
/**
 * Authorization Roles
 */
const authRoles = {
  user: ['workspace'],
  org: ['org_read', 'org_admin', 'org_super_admin'],
  platform: ['platform_admin', 'platform_support'],
  all: [
    'workspace',
    'org_read',
    'org_admin',
    'org_super_admin',
    'platform_admin',
    'platform_support',
  ],
};

/**
 *  this is a funtion to know if we have a permmision ,evaluating ours permissions list or role we have
 * @param {Array<string>|string} permissionsRequired
 * @param {{is_superuser:boolean, permissions:Array<string> }} permissions
 * @param {string} userRole
 * @returns
 */
export const checkAccess = (permissionsRequired, { is_superuser, permissions }, userRole) => {
  // verify when a permmission require
  if (permissionsRequired) {
    if (is_superuser) return true;

    // check if you have a role up that 'workspace'
    if (authRoles.org.includes(userRole) || authRoles.platform.includes(userRole)) {
      if (userRole === 'org_read') {
        if (Array.isArray(permissionsRequired))
          return !permissionsRequired.some((p) => !p.startsWith('view'));

        if (userRole === 'org_read' && typeof permissionsRequired === 'string')
          return permissionsRequired.startsWith('view');
      }

      return true;
    }

    if (permissionsRequired) {
      if (Array.isArray(permissionsRequired))
        return permissions.some((r) => permissionsRequired.indexOf(r) >= 0);
      return permissions.includes(permissionsRequired);
    }
  }
  return true;
};

export default authRoles;
