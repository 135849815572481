/* eslint-disable camelcase */
import AppBar from '@mui/material/AppBar';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Box, IconButton } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

function SelectTypeField({ onCancel, setStep }) {
  const { t } = useTranslation('assignmentsApp');
  const { reset, getValues } = useFormContext();

  const selectTypeField = (type) => {
    const id = getValues('id');
    const show = getValues('show');
    const title = getValues('title');
    const required = getValues('required');

    switch (type) {
      case 'text':
        reset({
          id,
          show,
          title,
          type: 'text',
          value: '',
          editable: true,
          required,
          input_type: 'text',
          max_length: 100,
          use_for_stats: false,
        });
        break;
      case 'multi-text':
        reset({
          id,
          show,
          title,
          type: 'multi-text',
          values: [''],
          editable: true,
          required,
          input_type: 'text',
          max_length: 100,
        });
        break;
      case 'select':
        reset({
          id,
          show,
          title,
          required,
          type: 'select',
          value: '',
          select_values: [
            {
              id: uuidv4().substring(0, 8),
              idx: 1,
              value: '',
            },
          ],
          select_default_value: 0,
        });
        break;
      case 'select-multiple':
        reset({
          id,
          show,
          title,
          type: 'select-multiple',
          values: [],
          required,
          select_values: [
            {
              id: uuidv4().substring(0, 8),
              value: '',
              checked: false,
            },
          ],
        });
        break;
      case 'ternary':
        reset({
          id,
          required,
          show,
          type: 'ternary',
          value: null,
        });
        break;
      case 'photo':
        reset({
          id,
          show,
          title,
          type: 'photo',
          value: uuidv4(),
          required,
        });
        break;
      case 'gps':
        reset({
          id,
          show,
          title,
          type: 'gps',
          value: {
            lat: null,
            lng: null,
            accuracy: null,
          },
          required,
        });
        break;
      case 'date':
        reset({
          id,
          show,
          title,
          type: 'date',
          value: null,
          required,
        });
        break;
      case 'signature':
        reset({ id, title, required, show, type: 'signature', value: '' });
        break;

      case 'barcode':
        reset({
          id,
          show,
          title,
          type: 'barcode',
          value: {
            text: null,
            format: null,
          },
          required,
        });
        break;
      case 'print-ticket':
        reset({
          id,
          show,
          title,
          type: 'print-ticket',
          value: '',
          required,
        });
        break;
      case 'section':
        reset({
          id,
          show,
          title,
          description: '',
          type: 'section',
        });
        break;

      default:
        reset({ id });
        break;
    }

    setStep(1);
  };

  return (
    <div className="h-360 flex flex-col">
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full justify-between">
          <div>
            <Typography variant="subtitle1" color="inherit">
              {t('NEW_FIELD_TITLE')}
            </Typography>
            <Typography variant="caption" color="inherit">
              {t('NEW_FIELD_DESP')}
            </Typography>
          </div>
          <IconButton onClick={onCancel} aria-label="delete" color="inherit">
            <FuseSvgIcon className="text-48" size={20}>
              material-solid:close
            </FuseSvgIcon>
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className="flex-auto h-1 w-full overflow-y-auto">
        <Box
          className="flex justify-between w-full h-60 p-5 hover:bg-grey-300"
          onClick={() => selectTypeField('text')}
        >
          <Typography variant="subtitle1">{t('TEXT_TITLE')}</Typography>
          <img src="assets/images/fields/text.png" alt="text" />
        </Box>
        <Box
          className="flex justify-between w-full h-60 p-5 hover:bg-grey-300"
          onClick={() => selectTypeField('multi-text')}
        >
          <Typography variant="subtitle1">{t('MULTI_TEXT_TITLE')}</Typography>
          <img src="assets/images/fields/multi-text.png" alt="multi-text" />
        </Box>
        <Box
          className="flex justify-between w-full h-60 p-5 hover:bg-grey-300"
          onClick={() => selectTypeField('select')}
        >
          <Typography variant="subtitle1">{t('SELECT_TITLE')}</Typography>
          <img src="assets/images/fields/select.png" alt="select" />
        </Box>
        <Box
          className="flex justify-between w-full h-60 p-5 hover:bg-grey-300"
          onClick={() => selectTypeField('select-multiple')}
        >
          <Typography variant="subtitle1">{t('SELECT_MULTIPLE_TITLE')}</Typography>
          <img src="assets/images/fields/select-multiple.png" alt="select-multiple" />
        </Box>
        <Box
          className="flex justify-between w-full h-60 p-5 hover:bg-grey-300"
          onClick={() => selectTypeField('ternary')}
        >
          <Typography variant="subtitle1">{t('TERNARY_TITLE')}</Typography>
          <img src="assets/images/fields/ternary.png" alt="ternary" />
        </Box>
        <Box
          className="flex justify-between w-full h-60 p-5 hover:bg-grey-300"
          onClick={() => selectTypeField('photo')}
        >
          <Typography variant="subtitle1">{t('PHOTO_TITLE')}</Typography>
          <img src="assets/images/fields/photo.png" alt="" />
        </Box>
        <Box
          className="flex justify-between w-full h-60 p-5 hover:bg-grey-300"
          onClick={() => selectTypeField('gps')}
        >
          <Typography variant="subtitle1">{t('GPS_TITLE')}</Typography>
          <img src="assets/images/fields/gps.png" alt="gps" />
        </Box>
        <Box
          className="flex justify-between w-full h-60 p-5 hover:bg-grey-300"
          onClick={() => selectTypeField('date')}
        >
          <Typography variant="subtitle1">{t('DATE_TITLE')}</Typography>
          <img src="assets/images/fields/date.png" alt="date" />
        </Box>
        <Box
          className="flex justify-between w-full h-60 p-5 hover:bg-grey-300"
          onClick={() => selectTypeField('signature')}
        >
          <Typography variant="subtitle1">{t('SIGNATURE_TITLE')}</Typography>
          <img src="assets/images/fields/signature.png" alt="signature" />
        </Box>
        <Box
          className="flex justify-between w-full h-60 p-5 hover:bg-grey-300"
          onClick={() => selectTypeField('barcode')}
        >
          <Typography variant="subtitle1">{t('BARCODE_TITLE')}</Typography>
          <img src="assets/images/fields/barcode.png" alt="barcode" />
        </Box>
        <Box
          className="flex justify-between w-full h-60 p-5 hover:bg-grey-300"
          onClick={() => selectTypeField('print-ticket')}
        >
          <Typography variant="subtitle1">{t('PRINT_TICKET_TITLE')}</Typography>
          <img src="assets/images/fields/print-ticket.png" alt="print-ticket" />
        </Box>
        <Box
          className="flex justify-between w-full h-60 p-5 py-16 hover:bg-grey-300"
          onClick={() => selectTypeField('section')}
        >
          <Typography variant="subtitle1">{t('SECTION_TITLE')}</Typography>
          <img src="assets/images/fields/section.png" alt="section" />
        </Box>
      </div>
    </div>
  );
}

export default SelectTypeField;
