import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

export const getUsersAll = createAsyncThunk(
  'selectsData/users/getUsersAll',
  async (wsId, { dispatch, getState }) => {
    const { isUsersLoaded } = getState().selectsData.users;
    if (!isUsersLoaded) {
      const response = await axios.get(ApiRoutes.usersEndPoint(wsId));
      const data = await response.data;
      dispatch(setIsUsersLoaded(true));
      return data;
    }
    return [];
  }
);

const usersAdapter = createEntityAdapter({});

export const selectUserSelector = (state) => state.selectsData.users;

export const { selectAll: selectUsers, selectById: selectUserById } =
  usersAdapter.getSelectors(selectUserSelector);

const initialState = usersAdapter.getInitialState({
  isUsersLoaded: false,
});

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setIsUsersLoaded: (state, action) => {
      state.isUsersLoaded = action.payload;
    },
    resetUserState: () => initialState,
  },
  extraReducers: {
    [getUsersAll.fulfilled]: usersAdapter.addMany,
  },
});

export const { setIsUsersLoaded, resetUserState } = userSlice.actions;

export default userSlice.reducer;
