import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  position: { coords: [9.939782463103057, -84.09855067041535], zoom: 8 },
  // points: [],
  // FilterItemsMap: {
  //   props: {
  //     open: true,
  //   },
  // },
  geofenceFiguresEditor: { active: false, figureType: null, status: null },
  sideBarProps: {
    tabType: '',
  },
  ListPointMapModal: {
    open: false,
    clickCoords: null,
  },
  removeCheckInOutFiltersModal: {
    open: false,
  },
  removeRoutesModal: {
    open: false,
    userId: '',
  },
  removeGeofenceModal: {
    open: false,
    geofenceId: '',
  },
  saveWithoutAlertModal: {
    open: false,
  },
  isLoadingModal: {
    open: true,
  },
  geoAlertsModal: {
    open: false,
    geoAlertId: null,
  },
  selectClientModal: {
    open: false,
  },
};

const mapDataSlice = createSlice({
  name: 'LocationApp/MapData',
  initialState,
  reducers: {
    openFilterItemsMap: (state, action) => {
      state.FilterItemsMap = {
        props: {
          open: true,
        },
      };
    },
    closeFilterItemsMap: (state, action) => {
      state.FilterItemsMap = {
        props: {
          open: false,
        },
      };
    },
    setListPointsProps: (state, action) => {
      state.ListPointMapModal = action.payload;
    },

    openSaveWithoutAlertModal: (state, action) => {
      state.saveWithoutAlertModal = {
        open: true,
      };
    },

    closeSaveWithoutAlertModal: (state, action) => {
      state.saveWithoutAlertModal = {
        open: false,
      };
    },

    openIsLoading: (state, action) => {
      state.isLoadingModal = {
        open: true,
      };
    },
    closeIsLoading: (state, action) => {
      state.isLoadingModal = {
        open: false,
      };
    },

    openRemoveCheckInOutFiltersModal: (state, action) => {
      state.removeCheckInOutFiltersModal = {
        open: true,
      };
    },
    closeRemoveCheckInOutFiltersModal: (state, action) => {
      state.removeCheckInOutFiltersModal = {
        open: false,
      };
    },
    openRemoveRoutesModal: (state, action) => {
      state.removeRoutesModal = {
        open: true,
        userId: action.payload,
      };
    },
    closeRemoveRoutesModal: (state, action) => {
      state.removeRoutesModal = {
        open: false,
        userId: '',
      };
    },
    openRemoveGeofenceModal: (state, action) => {
      state.removeGeofenceModal = {
        open: true,
        geofenceId: action.payload,
      };
    },
    closeRemoveGeofenceModal: (state, action) => {
      state.removeGeofenceModal = {
        open: false,
        geofenceId: '',
      };
    },
    openGeoAlertsModal: (state, action) => {
      state.geoAlertsModal = {
        open: true,
        geoAlertId: action.payload,
      };
    },
    closeGeoAlertsModal: (state, action) => {
      state.geoAlertsModal = {
        open: false,
        geoAlertId: null,
      };
    },
    openSelectClientModal: (state, action) => {
      state.selectClientModal = {
        open: true,
      };
    },
    closeSelectClientModal: (state, action) => {
      state.selectClientModal = {
        open: false,
      };
    },
    changeMap: (state, action) => {
      state.mapType = action.payload;
    },
    setPosition: (state, action) => {
      state.position = action.payload;
    },
    setSideBarContent: (state, action) => {
      state.sideBarProps.tabType = action.payload;
    },
    setSideBarData: (state, action) => {
      state.sideBarProps = { ...state.sideBarProps, ...action.payload };
    },
    openGeofenceFiguresEditor: (state, action) => {
      state.geofenceFiguresEditor.active = true;
      state.geofenceFiguresEditor.figureType = action.payload;
    },
    closeGeofenceFiguresEditor: (state, action) => {
      state.geofenceFiguresEditor.active = false;
      state.geofenceFiguresEditor.figureType = null;
    },
    setGeofenceFiguresType: (state, action) => {
      state.geofenceFiguresEditor.figureType = action.payload;
    },
    setGeofenceFiguresStatus: (state, action) => {
      state.geofenceFiguresEditor.status = action.payload;
    },
    resetMapDataState: () => initialState,
  },
});

// eslint-disable-next-line prettier/prettier
export const selectSideBarProps = ({ LocationApp }) => LocationApp.MapData.sideBarProps;
export const selectGeofenceFigureEditorProps = ({ LocationApp }) =>
  LocationApp.MapData.geofenceFiguresEditor;
export const selectModalPointsProps = ({ LocationApp }) => LocationApp.MapData.ListPointMapModal;
export const selectModalRemoveFiltersCheckInOutProps = ({ LocationApp }) =>
  LocationApp.MapData.removeCheckInOutFiltersModal;
export const selectModalRemoveRoutesProps = ({ LocationApp }) =>
  LocationApp.MapData.removeRoutesModal;

export const selectModalRemoveGeofenceProps = ({ LocationApp }) =>
  LocationApp.MapData.removeGeofenceModal;

export const selectModalSaveWithoutAlertModalProps = ({ LocationApp }) =>
  LocationApp.MapData.saveWithoutAlertModal;
export const {
  openFilterItemsMap,
  closeFilterItemsMap,
  openIsLoading,
  closeIsLoading,
  setListPointsProps,
  changeMap,
  setPosition,
  setSideBarContent,
  setSideBarData,
  openRemoveCheckInOutFiltersModal,
  closeRemoveCheckInOutFiltersModal,
  openRemoveRoutesModal,
  closeRemoveRoutesModal,
  openGeofenceFiguresEditor,
  closeGeofenceFiguresEditor,
  setGeofenceFiguresType,
  resetMapDataState,
  setGeofenceFiguresStatus,
  openGeoAlertsModal,
  closeGeoAlertsModal,
  openSaveWithoutAlertModal,
  closeSaveWithoutAlertModal,
  openRemoveGeofenceModal,
  closeRemoveGeofenceModal,
  openSelectClientModal,
  closeSelectClientModal,
} = mapDataSlice.actions;

export default mapDataSlice.reducer;
