import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { format, parseISO } from 'date-fns';
import _ from '@lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import { selectOrganization, setOrganization } from 'app/store/organizationSlice';
import { useEffect, useState } from 'react';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import { getCountriesAll } from 'app/store/selectsData/countrySlice';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import { roleChecker } from 'app/store/userSlice';

import CountrySelector from '@fuse/core/FuseCountry/CountrySelector';

function imageExists(imageSrc) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageSrc;
    img.onload = resolve;
    img.onerror = reject;
  });
}
const Content = styled('div')(({ theme }) => ({
  width: '100%',
  height: '90%',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  overflowY: 'auto',
}));

const Footer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  gap: 8,
  paddingLeft: 8,
  paddingRight: 8,
  width: '100%',
  height: '10%',
}));

const Outlinebox = styled(TextField)(() => ({
  '.MuiOutlinedInput-input': {
    width: 0,
  },
}));

function InformationTab(props) {
  const organization = useSelector(selectOrganization);
  const checkRole = useSelector(roleChecker);
  const dispatch = useDispatch();

  const { t } = useTranslation('organizationApp');

  const [isLoading, setIsLoading] = useState(true);

  const schema = yup.object().shape({
    name: yup.string().required(t('NAME_CANNOT_EMPTY')),
    logo_url: yup.string(),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: organization,
  });
  const { control, formState, getValues, reset, setError, setValue, clearErrors, watch, register } =
    methods;
  const { errors } = formState;

  const onChangeImage = async ({ target: { value: url } }) => {
    setValue('logo_url', url);
    if (url === '') {
      clearErrors('logo_url');
      return;
    }

    if (!/^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url)) {
      setError('logo_url', { type: 'invalid image', message: t('INVALID_IMAGEN_URL') });
      console.log('url error');
      return;
    }

    try {
      await imageExists(url);
    } catch (error) {
      console.log(error);
      setError('logo_url', { type: 'invalid image', message: t('INVALID_IMAGEN_URL') });
      return;
    }
    clearErrors('logo_url');
  };

  const saveOrganization = () => dispatch(setOrganization(getValues()));

  useEffect(() => {
    dispatch(getCountriesAll()).then(() => setIsLoading(false));
  }, [dispatch]);

  if (isLoading) {
    return (
      <div className="file-details flex flex-col flex-auto  h-fullVH w-fullVW sm:h-full sm:w-full">
        <FuseLoading />
      </div>
    );
  }

  return (
    <>
      <Content>
        <form
          name="organizationsettingForm"
          noValidate
          className="flex flex-col justify-center w-full pt-32 px-12"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="org-name"
                className="pb-24"
                label={t('NAME')}
                autoFocus
                error={!!errors.name}
                helperText={errors?.name?.message}
                variant="outlined"
                inputProps={{ maxLength: 255 }}
                required
                fullWidth
              />
            )}
          />

          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="pb-24"
                label={t('DESCRIPTION')}
                multiline
                variant="outlined"
                inputProps={{ maxLength: 500 }}
                fullWidth
              />
            )}
          />
          <Controller
            control={control}
            name="country"
            render={({ field: _field }) => (
              <Outlinebox
                label={t('COUNTRY')}
                className="pb-24"
                nonce="true"
                InputProps={{
                  startAdornment: <CountrySelector {..._field} />,
                  style: {
                    boxSizing: 'border-box',
                  },
                }}
              />
            )}
          />
          <div className=" flex items-center justify-center w-full">
            <Controller
              name="logo_url"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={onChangeImage}
                  label={t('IMAGE_URL')}
                  error={!!errors.logo_url}
                  helperText={errors?.logo_url?.message}
                  multiline
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <div className="w-1/6 h-full flex items-center justify-center">
                        {errors.logo_url || !watch('logo_url') ? (
                          <FuseSvgIcon className="w-full h-full" color="action">
                            material-solid:broken_image
                          </FuseSvgIcon>
                        ) : (
                          <img
                            style={{ width: '100%', height: 'auto' }}
                            src={watch('logo_url')}
                            alt=""
                          />
                        )}
                      </div>
                    ),
                  }}
                />
              )}
            />
          </div>
          {checkRole('platform_support') && (
            <div className="py-8">
              <div className="w-full py-10">
                <Divider />
              </div>
              {getValues('date_created') && (
                <div>
                  <Typography variant="caption" className="font-medium">
                    {t('CREATION_DATE')}:
                  </Typography>
                  <Typography variant="caption">
                    {format(parseISO(getValues('date_created')), 'dd/MM/y hh:mm aaaa ')}
                  </Typography>
                </div>
              )}
              {getValues('date_edited') && (
                <div>
                  <Typography variant="caption" className="font-medium">
                    {t('EDITION_DATE')}:
                  </Typography>
                  <Typography variant="caption">
                    {format(parseISO(getValues('date_edited')), 'dd/MM/y hh:mm aaaa ')}
                  </Typography>
                </div>
              )}
              <div>
                <Typography variant="caption" className="font-medium">
                  ID:
                </Typography>
                <Typography variant="caption">{getValues('id')}</Typography>
              </div>
            </div>
          )}
        </form>
      </Content>
      <Footer>
        <Button
          className="flex-auto "
          color="secondary"
          variant="contained"
          disabled={!_.isEmpty(errors)}
          onClick={saveOrganization}
        >
          {t('SAVE')}
        </Button>
      </Footer>
    </>
  );
}

export default InformationTab;
