import i18next from 'i18next';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import en from './i18n/en';
import es from './i18n/es';

i18next.addResourceBundle('en', 'errorApp', en);
i18next.addResourceBundle('es', 'errorApp', es);

const Error404Page = lazy(() => import('./Error404Page'));
const Error404WsPage = lazy(() => import('./Error404WsPage'));
const Error500Page = lazy(() => import('./Error500Page'));

const errorAppConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'ws-404/',
      element: <Error404Page />,
    },
    {
      path: 'error-404/',
      element: <Error404Page />,
    },
    {
      path: 'ws/:ws/pages/error',

      children: [
        {
          path: '',
          element: <Navigate to="404" />,
        },
        {
          path: '404',
          element: <Error404WsPage />,
        },
        {
          path: '500',
          element: <Error500Page />,
        },
      ],
    },
  ],
};

export default errorAppConfig;
