import i18next from 'i18next';
import { authRoles } from 'src/app/auth';
import { lazy } from 'react';
import en from './i18n/en';
import es from './i18n/es';
import OrganizationView from './organization/OrganizationSidebar/OrganizationSidebarContent';

i18next.addResourceBundle('en', 'organizationApp', en);
i18next.addResourceBundle('es', 'organizationApp', es);

const Organizations = lazy(() => import('./organizations/Organizations'));
const Organization = lazy(() => import('./organization/OrganizationApp'));
const OrganizationsAppConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: true,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'organizations',
      element: <Organizations />,
      roleRequired: authRoles.platform,
    },
    {
      path: 'org/:orgId',
      element: <Organization />,
      roleRequired: [...authRoles.org, ...authRoles.platform],

      children: [
        {
          path: ':settings',
          element: <OrganizationView />,
        },
      ],
    },
  ],
};

export default OrganizationsAppConfig;
