import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import { forwardRef, memo, useImperativeHandle, useRef } from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';
import FusePageCardedHeader from './FusePageCardedHeader';
import FusePageCardedSidebar from './FusePageCardedSidebar';

const Root = styled('div')(({ theme, ...props }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
  minHeight: '100%',
  position: 'relative',
  flex: '1 1 auto',
  width: '100%',
  height: 'auto',
  backgroundColor: theme.palette.background.default,

  '& .FusePageCarded-scroll-content': {
    height: '100%',
  },

  '& .FusePageCarded-wrapper': {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    zIndex: 2,
    maxWidth: '100%',
    minWidth: 0,
    height: '100%',
    backgroundColor: theme.palette.background.paper,

    ...(props.scroll === 'content' && {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      overflow: 'hidden',
    }),
  },

  '& .FusePageCarded-header': {
    display: 'flex',
    flex: '0 0 auto',
  },

  '& .FusePageCarded-contentWrapper': {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    zIndex: 9999,
  },

  '& .FusePageCarded-toolbar': {
    height: toolbarHeight,
    minHeight: toolbarHeight,
    display: 'flex',
    alignItems: 'center',
  },

  '& .FusePageCarded-content': {
    flex: '1 0 auto',
  },

  '& .FusePageCarded-sidebarWrapper': {
    overflow: 'hidden',
    backgroundColor: 'transparent',
    position: 'absolute',
    '&.permanent': {
      [theme.breakpoints.up('lg')]: {
        position: 'relative',
        marginLeft: 0,
        marginRight: 0,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        '&.closed': {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),

          '&.FusePageCarded-leftSidebar': {
            marginLeft: -props.leftsidebarwidth,
          },
          '&.FusePageCarded-rightSidebar': {
            marginRight: -props.rightsidebarwidth,
          },
        },
      },
    },
  },

  '& .FusePageCarded-sidebar': {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,

    '&.permanent': {
      [theme.breakpoints.up('lg')]: {
        position: 'relative',
      },
    },
    maxWidth: '100%',
    height: '100%',
  },

  '& .FusePageCarded-leftSidebar': {
    width: props.leftsidebarwidth,

    [theme.breakpoints.up('lg')]: {
      // borderRight: `1px solid ${theme.palette.divider}`,
      // borderLeft: 0,
    },
  },

  '& .FusePageCarded-rightSidebar': {
    width: props.rightsidebarwidth,

    [theme.breakpoints.up('lg')]: {
      // borderLeft: `1px solid ${theme.palette.divider}`,
      // borderRight: 0,
    },
  },

  '& .FusePageCarded-sidebarHeader': {
    height: headerHeight,
    minHeight: headerHeight,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },

  '& .FusePageCarded-sidebarHeaderInnerSidebar': {
    backgroundColor: 'transparent',
    color: 'inherit',
    height: 'auto',
    minHeight: 'auto',
  },

  '& .FusePageCarded-sidebarContent': {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },

  '& .FusePageCarded-backdrop': {
    position: 'absolute',
  },
}));

const headerHeight = 120;
const toolbarHeight = 64;

const FusePageCarded = forwardRef(
  (
    {
      classes = {},
      scroll = 'page',
      leftSidebarOpen = false,
      rightSidebarOpen = false,
      rightSidebarWidth = 240,
      leftSidebarWidth = 240,
      className,
      header,
      leftSidebarContent,
      leftSidebarVariant,
      leftSidebarOnClose,
      content,
      rightSidebarContent,
      rightSidebarVariant,
      rightSidebarOnClose,
    },
    ref
  ) => {
    // console.info("render::FusePageCarded");
    const leftSidebarRef = useRef(null);
    const rightSidebarRef = useRef(null);
    const rootRef = useRef(null);

    useImperativeHandle(ref, () => ({
      rootRef,
      toggleLeftSidebar: (val) => {
        leftSidebarRef.current.toggleSidebar(val);
      },
      toggleRightSidebar: (val) => {
        rightSidebarRef.current.toggleSidebar(val);
      },
    }));

    return (
      <>
        <GlobalStyles
          styles={(theme) => ({
            ...(scroll !== 'page' && {
              '#fuse-toolbar': {
                position: 'static',
              },
              '#fuse-footer': {
                position: 'static',
              },
            }),
            ...(scroll === 'page' && {
              '#fuse-toolbar': {
                position: 'sticky',
                top: 0,
              },
              '#fuse-footer': {
                position: 'sticky',
                bottom: 0,
              },
            }),
          })}
        />
        <Root
          className={clsx('FusePageCarded-root', `FusePageCarded-scroll-${scroll}`, className)}
          ref={rootRef}
          scroll={scroll}
          leftsidebarwidth={leftSidebarWidth}
          rightsidebarwidth={rightSidebarWidth}
        >
          {header && <FusePageCardedHeader header={header} />}

          <div className="flex flex-auto flex-col container z-10 h-full shadow-1 relative overflow-hidden">
            <div className="FusePageCarded-wrapper">
              {leftSidebarContent && (
                <FusePageCardedSidebar
                  position="left"
                  content={leftSidebarContent}
                  variant={leftSidebarVariant || 'permanent'}
                  ref={leftSidebarRef}
                  rootRef={rootRef}
                  open={leftSidebarOpen}
                  onClose={leftSidebarOnClose}
                />
              )}
              <FuseScrollbars
                className="FusePageCarded-contentWrapper"
                enable={scroll === 'content'}
              >
                {content && <div className={clsx('FusePageCarded-content')}>{content}</div>}
              </FuseScrollbars>
              {rightSidebarContent && (
                <FusePageCardedSidebar
                  position="right"
                  content={rightSidebarContent}
                  variant={rightSidebarVariant || 'permanent'}
                  ref={rightSidebarRef}
                  rootRef={rootRef}
                  open={rightSidebarOpen}
                  onClose={rightSidebarOnClose}
                />
              )}
            </div>
          </div>
        </Root>
      </>
    );
  }
);

FusePageCarded.propTypes = {
  leftSidebarHeader: PropTypes.node,
  leftSidebarContent: PropTypes.node,
  leftSidebarVariant: PropTypes.node,
  rightSidebarContent: PropTypes.node,
  rightSidebarVariant: PropTypes.node,
  header: PropTypes.node,
  content: PropTypes.node,
  contentToolbar: PropTypes.node,
  scroll: PropTypes.oneOf(['normal', 'page', 'content']),
  leftSidebarOpen: PropTypes.bool,
  rightSidebarOpen: PropTypes.bool,
  leftSidebarWidth: PropTypes.number,
  rightSidebarWidth: PropTypes.number,
  rightSidebarOnClose: PropTypes.func,
  leftSidebarOnClose: PropTypes.func,
};

export default memo(styled(FusePageCarded)``);
