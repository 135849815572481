/* eslint-disable prefer-template */
/* eslint-disable no-case-declarations */

import {
  getIconPersonPath,
  getIconClientPath,
  getIconPlacePath,
} from '@assets/images/markers/IconVectors';
import FuseUtils from '@fuse/utils/FuseUtils';
import GoogleMap from 'google-map-react';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete';
import { useTranslation } from 'react-i18next';

const GoogleMaps = forwardRef((props, ref) => {
  const { t } = useTranslation('managementApp');

  const [isMapLoaded, setIsMaploaded] = useState(false);
  const {
    positionMap,
    setPositionMap,
    locationMarker,
    labelMarker,
    iconType,
    isEditing,
    extraMakers,
    scrollable,
    showPlacesMakers,
  } = props;

  const options = {
    maxZoom: 18,
    restriction: {
      latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
      strictBounds: true,
    },
    scrollwheel: scrollable,
    fullscreenControl: false,
    styles: [
      {
        featureType: 'all',
        elementType: 'labels',
        stylers: [{ visibility: showPlacesMakers ? 'on' : 'off' }],
      },
    ],
  };

  const [locationIns, setLocationIns] = useState(null);

  const handleApiLoaded = (map, maps) => {
    if (locationIns) return;

    const getIcon = (type) => {
      switch (type) {
        case 'user':
          return {
            path: getIconPersonPath(),
            strokeColor: '#00000',
            strokeWeight: 1,
            fillColor: isEditing ? '#FFF' : '#000',
            fillOpacity: 1,
            scale: 0.05,
            anchor: new maps.Point(200, 800),
          };

        case 'client':
          return {
            path: getIconClientPath(),
            strokeColor: '#00000',
            strokeWeight: 1,
            fillColor: '#2C57EE',
            fillOpacity: 1,
            scale: 0.05,
            anchor: new maps.Point(350, 800),
          };
        case 'branch_office':
        case 'warehouse':
        case 'headquarters':
        case 'auxiliary_office':
        case 'factory':
        case 'store':
        case 'parking_lot':
        case 'construction_sites':
        case 'other':
          return {
            path: getIconPlacePath(type),
            strokeColor: '#00000',
            strokeWeight: 0.25,
            fillColor: '#2C57EE',
            fillOpacity: 1,
            scale: 0.05,
            anchor: new maps.Point(350, 800),
          };

        default:
          return {
            url: 'assets/images/markers/locationPin.png',
            scaledSize: new maps.Size(18, 30),
          };
      }
    };

    const icon = getIcon(iconType);

    const item = {};

    if (locationMarker) {
      item.locationMarker = new maps.Marker({
        id: 'locationMarker',
        position: { lat: locationMarker[0], lng: locationMarker[1] },
        label: labelMarker
          ? {
              text: labelMarker,
              color: '#ff790d',
              className: 'markerLabel mb-32 ml-16',
            }
          : null,
        map,
        icon,
      });

      if (labelMarker) {
        item.popUpGeneral = new maps.InfoWindow({
          content: '<h2><b>' + labelMarker + '<b/><h2/>',
        });

        item.popUpGeneral.open({
          anchor: item.locationMarker,
          map,
        });

        maps.event.addListener(map, 'click', (event) => item.popUpGeneral.close());

        item.locationMarker.addListener('click', () => {
          item.popUpGeneral.setContent('<h2>' + labelMarker + '<h2/>');
          item.popUpGeneral.open({
            anchor: item.locationMarker,
            map,
          });
        });
      }
    }

    item.extraMakers = [];
    extraMakers.forEach((marker, index) => {
      item.extraMakers.push(
        new maps.Marker({
          id: index,
          position: { lat: marker.coords[0], lng: marker.coords[1] },
          label: {
            text: marker.label,
            color: '#ff790d',
            className: 'markerLabel mb-32 ml-16',
          },
          map,
          icon: getIcon(marker.type),
        })
      );
    });
    setLocationIns(item);

    setIsMaploaded(true);
  };
  // };

  const onChangeMap = ({ center, zoom }) => {
    setPositionMap({
      coords: [center.lat(), center.lng()],
      zoom,
    });
  };
  useImperativeHandle(ref, () => ({}));

  // eslint-disable-next-line camelcase
  const onSelectPlace = async ({ value: { place_id }, label }) => {
    const [results] = await geocodeByPlaceId(place_id);
    const { lat, lng } = await getLatLng(results);

    setPositionMap({
      coords: [lat, lng],
      zoom: 15,
    });
  };

  const onKeyDownPlacesAutocomplete = ({ target: { value }, keyCode }) => {
    if (keyCode === 13 && FuseUtils.validateCoordinate(value)) {
      const [lat, lng] = FuseUtils.parseCordinate(value);

      setPositionMap({
        coords: [lat, lng],
        zoom: 15,
      });
    }
  };

  // useEffect(() => {
  //   if (locationIns) {
  //     locationIns.setOptions({ draggable });
  //   }
  // }, [locationIns, draggable]);

  useEffect(() => {
    if (positionMap.setPosition && locationIns?.locationMarker) {
      locationIns.locationMarker.setPosition({
        lat: positionMap.coords[0],
        lng: positionMap.coords[1],
      });
    }
  }, [locationIns?.locationMarker, positionMap]);

  return (
    <div className="w-full h-full">
      {isMapLoaded && isEditing && (
        <div className="absolute w-1/2 m-10 ml-5 z-999">
          <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_MAP_KEY}
            selectProps={{
              value: null,
              onChange: onSelectPlace,
              placeholder: t('SEARCH_PLACE'),
              onKeyDown: onKeyDownPlacesAutocomplete,
            }}
            onLoadFailed={console.log}
          />
        </div>
      )}
      <GoogleMap
        bootstrapURLKeys={{
          key: process.env.REACT_APP_MAP_KEY,
          libraries: ['places'],
          id: 'opta_map_google',
        }}
        zoom={positionMap.zoom}
        center={positionMap.coords}
        // onChange={onChangeMap}
        onDragEnd={onChangeMap}
        options={options}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        yesIWantToUseGoogleMapApiInternals
      />
    </div>
  );
});

export default GoogleMaps;
