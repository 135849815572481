import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import { selectFormById } from 'src/app/pages/modulesApp/assignmentsApp/store/FormsSlice';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function FieldSection() {
  const { t } = useTranslation('assignmentsApp');
  const { formId } = useParams();
  const form = useSelector((state) => selectFormById(state, formId));
  const { control, formState, watch } = useFormContext();
  const { errors } = formState;

  return (
    <>
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full py-5 px-10">
          <div className="flex flex-col flex-1 w-full items-start justify-between">
            <Typography variant="subtitle1" color="inherit">
              <b>{t('FIELD_TYPE')}</b>:{t('SECTION_TITLE')}
            </Typography>
            <Typography variant="caption">{t('SECTION_DEPS')}</Typography>
          </div>
          <img className="w-60" src="assets/images/fields/section.png" alt="text" />
        </Toolbar>
      </AppBar>
      <div className="flex flex-col px-10 py-16 gap-y-20">
        <Controller
          name="title"
          control={control}
          render={({ field: fieldI }) => (
            <TextField
              {...fieldI}
              fullWidth
              size="small"
              variant="outlined"
              id={`textFieldTitle${fieldI.id}`}
              label={t('TITLE')}
              error={!!errors.title}
              helperText={errors?.title?.message}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              fullWidth
              variant="outlined"
              minRows={5}
              spellCheck={false}
              id={`textFieldDescription${field.id}`}
              label={t('DESCRIPTION')}
              error={!!errors.description}
              helperText={errors?.description?.message}
              placeholder={t('OPTIONALY_WRITE_DETAILS_SECTION')}
            />
          )}
        />
        <Divider />
        <Controller
          name="has_conditional"
          control={control}
          render={({ field: { onChange, value, ref, onBlur } }) => (
            <FormControlLabel
              control={
                <Switch
                  id="hasConditionalSwitch"
                  size="small"
                  checked={value || false}
                  onBlur={onBlur}
                  onChange={(ev) => onChange(ev.target.checked)}
                  inputRef={ref}
                />
              }
              label={t('CONDITIONALLY_SHOW_FIELD')}
            />
          )}
        />
        {watch('has_conditional') && (
          <Controller
            render={({ field }) => (
              <FormControl fullWidth size="small">
                {form.data.fields.filter(
                  (fieldI) => fieldI.type === 'select' || fieldI.type === 'ternary'
                ).length ? (
                  <>
                    <InputLabel id="conditional_info">{t('CONDITIONING_FIELD')}</InputLabel>
                    <Select
                      {...field}
                      variant="outlined"
                      fullWidth
                      labelId="conditional_info"
                      id="conditional_info"
                      name="conditional_info"
                      label={t('CONDITIONING_FIELD')}
                      value={field.value?.field_id || ''}
                      onChange={(e) => field.onChange({ field_id: e.target.value })}
                      defaultValue=""
                    >
                      {form.data.fields
                        .filter((fieldI) => fieldI.type === 'select' || fieldI.type === 'ternary')
                        .map((fieldI) => (
                          <MenuItem key={fieldI.id} value={fieldI.id}>
                            <div className="flex justify-between w-full">
                              <Typography variant="caption">{fieldI.title}</Typography>
                              <div className="flex h-20">
                                {fieldI.type === 'ternary' ? (
                                  <img src="assets/images/fields/ternary.png" alt="text" />
                                ) : (
                                  <img src="assets/images/fields/select.png" alt="text" />
                                )}
                              </div>
                            </div>
                          </MenuItem>
                        ))}
                    </Select>
                  </>
                ) : (
                  <Typography variant="caption">{t('NO_CONDITIONING_FIELD')}</Typography>
                )}
              </FormControl>
            )}
            name="conditional_info"
            control={control}
          />
        )}
        {watch('conditional_info')?.field_id && watch('has_conditional') && (
          <Controller
            render={({ field }) => (
              <FormControl fullWidth size="small">
                <InputLabel id="conditional_info_field_id">{t('CONDITIONING_VALUE')}</InputLabel>
                {form.data.fields.find((fieldI) => fieldI.id === field.value?.field_id)?.type ===
                'ternary' ? (
                  <Select
                    {...field}
                    variant="outlined"
                    fullWidth
                    labelId="conditional_info_field_id"
                    id="conditional_info_field_id"
                    name="conditional_info_field_id"
                    label={t('CONDITIONING_VALUE')}
                    value={JSON.stringify(field.value?.value_id) || ''}
                    onChange={(e) =>
                      field.onChange({ ...field.value, value_id: JSON.parse(e.target.value) })
                    }
                  >
                    <MenuItem key="null" value="null">
                      {t('NO_RESPONSE')}
                    </MenuItem>
                    <MenuItem key="false" value="false">
                      {t('NO')}
                    </MenuItem>
                    <MenuItem key="true" value="true">
                      {t('YES')}
                    </MenuItem>
                  </Select>
                ) : (
                  <Select
                    {...field}
                    variant="outlined"
                    fullWidth
                    labelId="conditional_info_field_id"
                    id="conditional_info_field_id"
                    name="conditional_info_field_id"
                    label={t('CONDITIONING_VALUE')}
                    value={field.value?.value_id || ''}
                    onChange={(e) => field.onChange({ ...field.value, value_id: e.target.value })}
                  >
                    {form.data.fields
                      .find((fieldI) => fieldI.id === field.value?.field_id)
                      .select_values.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.value}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              </FormControl>
            )}
            name="conditional_info"
            control={control}
          />
        )}
      </div>
    </>
  );
}
