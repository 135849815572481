import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useEffect, useState } from 'react';
import Socket from 'src/app/services/Socket';

function SocketConnectionIndicator() {
  const [isConnected, setIsConnected] = useState(Socket.isConnected());

  useEffect(() => {
    const onConnect = () => setIsConnected(true);

    const onDisconnect = () => setIsConnected(false);

    try {
      Socket.addListener('connect', onConnect);
      Socket.addListener('disconnect', onDisconnect);
    } catch (error) {
      console.log(error);
    }
    return () => {
      try {
        Socket.removeListener('connect', onConnect);
        Socket.removeListener('disconnect', onDisconnect);
      } catch (error) {
        console.log(error);
      }
    };
  }, []);

  if (isConnected) {
    return (
      <FuseSvgIcon className="text-48" size={24} color="success">
        material-solid:cloud_queue
      </FuseSvgIcon>
    );
  }
  return (
    <FuseSvgIcon className="text-48" size={24} color="action">
      material-solid:cloud_off
    </FuseSvgIcon>
  );
}

export default SocketConnectionIndicator;
