import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getFlagPosition } from '@assets/images/flags/flagsData';
import ApiRoutes from 'src/app/services/ApiRoutes';

export const getCountriesAll = createAsyncThunk(
  'selectsData/countries/getCountriesAll',
  async (params, { dispatch, getState }) => {
    const { isCountriesLoaded } = getState().selectsData.countries;
    if (!isCountriesLoaded) {
      const response = await axios.get(ApiRoutes.countriesEndPoint());
      const { data } = response;

      const countries = await data.map((country) => {
        country.imagenPosition = getFlagPosition(country.iso_alpha2);
        return country;
      });

      dispatch(setIsCountriesLoaded(true));
      return countries;
    }
    return [];
  }
);

export const getTimeZones = createAsyncThunk(
  'selectsData/countries/getTimeZones',
  async (countryCode, { dispatch, getState }) => {
    const country = getState().selectsData.countries.entities[countryCode];
    let timeZones = country?.timeZones;
    if (!timeZones) {
      const response = await axios.get(`${ApiRoutes.timeZonesEndPoint()}?country=${countryCode}`);
      timeZones = response.data;
    }

    return { ...country, timeZones };
  }
);
export const timeZonesLengthSelector =
  ({
    selectsData: {
      countries: { entities },
    },
  }) =>
  (countryCode) =>
    entities[countryCode]?.timeZones?.length || 0;

const countriesAdapter = createEntityAdapter({});

export const { selectAll: selectCountries, selectById: selectCountryById } =
  countriesAdapter.getSelectors((state) => state.selectsData.countries);

const countriesSlice = createSlice({
  name: 'countries',
  initialState: countriesAdapter.getInitialState({
    isCountriesLoaded: false,
  }),
  reducers: {
    setIsCountriesLoaded: (state, action) => {
      state.isCountriesLoaded = action.payload;
    },
  },
  extraReducers: {
    [getCountriesAll.fulfilled]: countriesAdapter.addMany,
    [getTimeZones.fulfilled]: countriesAdapter.setOne,
  },
});

// only use for this self file
export const { setIsCountriesLoaded } = countriesSlice.actions;

export default countriesSlice.reducer;
