import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';
import { t } from 'i18next';
import FuseUtils from '@fuse/utils/FuseUtils';

export const getTeams = createAsyncThunk(
  'managementApp/teams/getTeams',
  async (params, { dispatch, getState }) => {
    const response = await axios.get(`${ApiRoutes.teamsEndPoint(params.wsId)}all/`);

    const data = await response.data;

    return data;
  }
);

export const setTeam = createAsyncThunk(
  'managementApp/teams/setTeam',
  async (params, { dispatch, getState }) => {
    const response = await axios.put(`${ApiRoutes.teamsEndPoint(params.wsId) + params.team.id}/`, {
      ...params.team,
      users: params.team.users_data.map((userData) => ({
        user: userData.user.id,
        role: userData.role,
      })),
    });
    const data = await response.data;

    dispatch(
      showMessage({
        message: t('managementApp:TEAM_UPDATED', { name: data.name }),
        variant: 'success',
      })
    );

    return data;
  }
);

export const addTeam = createAsyncThunk(
  'managementApp/teams/addTeam',
  async (params, { dispatch, getState }) => {
    const response = await axios.post(ApiRoutes.teamsEndPoint(params.wsId), {
      ...params.team,
      users: params.team.users_data.map((userData) => ({
        user: userData.user.id,
        role: userData.role,
      })),
    });
    const data = await response.data;

    const { entities } = getState().managementApp.teams;
    const Teams = Object.keys(entities).map((key) => entities[key]);

    dispatch(
      showMessage({
        message: t('managementApp:TEAM_CREATED', { name: data.name }),
        variant: 'success',
      })
    );

    return [data, ...Teams];
  }
);

export const removeTeam = createAsyncThunk(
  'managementApp/teams/removeTeams',
  async (params, { dispatch, getState }) => {
    await axios.delete(`${ApiRoutes.teamsEndPoint(params.wsId) + params.team.id}/`);

    dispatch(setSidebarProps({ selectedTeamId: null, numOfTab: 0 }));

    dispatch(
      showMessage({
        message: t('managementApp:TEAM_DELETED', { name: params.team.name }),
        variant: 'success',
      })
    );

    return params.team.id;
  }
);

const teamsAdapter = createEntityAdapter({});

export const { selectAll: selectTeams, selectById: selectTeamById } = teamsAdapter.getSelectors(
  (state) => state.managementApp.teams
);
export const selectSearchText = ({ managementApp }) => managementApp.teams.searchText;

export const selectFilteredTeams = createSelector(
  [selectTeams, selectSearchText],
  (teams, searchText) => {
    const metaTeams = teams.map((team) => ({
      id: team.id,
      name: team.name,
      description: team.description,
    }));
    if (searchText.length === 0) {
      return metaTeams;
    }
    return FuseUtils.filterArrayByString(metaTeams, searchText);
  }
);

const initialState = teamsAdapter.getInitialState({
  isLoading: true,
  searchText: '',
  sidebarProps: {
    numOfTab: 0,
  },
  deleteModalProps: {
    open: false,
    teamId: null,
  },
});

const teamsSlice = createSlice({
  name: 'managementApp/teams',
  initialState,
  reducers: {
    setSidebarProps: (state, action) => {
      state.sidebarProps = { ...state.sidebarProps, ...action.payload };
    },
    setTeamsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setDeleteModalProps: (state, action) => {
      state.deleteModalProps = action.payload;
    },
    resetTeamsState: () => initialState,
  },
  extraReducers: {
    [getTeams.fulfilled]: teamsAdapter.setAll,
    [setTeam.fulfilled]: teamsAdapter.setOne,
    [addTeam.fulfilled]: teamsAdapter.setAll,
    [removeTeam.fulfilled]: teamsAdapter.removeOne,
  },
});

export const selectTeamsData = ({ managementApp }) => managementApp.teams;

export const selectSelectedTeam = ({ managementApp }) =>
  managementApp.teams.sidebarProps.selectedTeamId;

export const {
  setSidebarProps,
  setTeamsSearchText,
  setIsLoading,
  setDeleteModalProps,
  resetTeamsState,
} = teamsSlice.actions;

export default teamsSlice.reducer;
