/* eslint-disable camelcase */
import * as yup from 'yup';
import Dialog from '@mui/material/Dialog';
import { FormProvider, useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import NewField from './FieldsSteps/NewField';
import Field from './FieldsSteps/Field';
import FieldPreview from './FieldsSteps/FieldPreview';

function FieldDetails({ open, setOpen, onChange, onDelete, field }) {
  const { t } = useTranslation('assignmentsApp');
  const [step, setStep] = useState(field.type ? 1 : 0);

  /**
   * Form Validation Schema
   */
  const schemaField = {
    text: {
      max_length: yup.number().typeError(t('FIELD_REQUIRED')).min(1, t('INVALID_VALUE')),
    },
    'multi-text': {},
    select: {
      select_values: yup.array().of(
        yup.object().shape({
          value: yup.string().required(t('OPTION_TEXT_REQUIRED')),
        })
      ),
    },
    'select-multiple': {
      select_values: yup.array().of(
        yup.object().shape({
          value: yup.string().required(t('OPTION_TEXT_REQUIRED')),
        })
      ),
    },
    ternary: {},
    photo: {},
    gps: {},
    date: {},
    signature: {},
    barcode: {},
    'print-ticket': {},
    section: {},
  };

  const schema = yup.object().shape({
    ...schemaField[field.type],
    title: yup.string().required(t('TITLE_CANNOT_EMPTY')),
    type: yup.string(),
    has_conditional: yup.boolean(),
    conditional_info: yup
      .object()
      .default({})
      .when('has_conditional', {
        is: true,
        then: yup.object().shape({
          field_id: yup.string().required(),
          value_id: yup.string().defined().nullable(true),
        }),
      }),
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const onCancel = function closeDialog() {
    setOpen(false);
    if (field.type === null) onDelete();
  };

  const onAccept = () => {
    const fieldData = methods.getValues();

    onChange(fieldData, field);

    setOpen(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (field.type) setStep(1);
    methods.reset(field);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Dialog open={open} onClose={onCancel} fullWidth maxWidth="sm" className="z-999 ">
        <FormProvider {...methods}>
          {/* <SwipeableViews disabled index={step} animateHeight> */}

          {step === 0 && (
            <motion.div initial={{ x: '100%' }} animate={{ x: 0, transition: { delay: 0.1 } }}>
              <NewField onCancel={onCancel} setStep={setStep} />
            </motion.div>
          )}
          {step === 1 && (
            <motion.div initial={{ x: '100%' }} animate={{ x: 0, transition: { delay: 0.1 } }}>
              <Field onCancel={onCancel} onAccept={onAccept} setStep={setStep} />
            </motion.div>
          )}
          {step === 2 && (
            <motion.div initial={{ x: '100%' }} animate={{ x: 0, transition: { delay: 0.1 } }}>
              <FieldPreview onCancel={onCancel} onAccept={onAccept} setStep={setStep} />
            </motion.div>
          )}
          {/* </SwipeableViews> */}
        </FormProvider>
      </Dialog>
    </>
  );
}

export default FieldDetails;
