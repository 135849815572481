import { combineReducers } from '@reduxjs/toolkit';
import MapData, { resetMapDataState } from './mapDataSlice';
import Users, { resetUsersState } from './usersSlice';
import Vehicles, { resetVehicles } from './vehicleSlice';
import Geofences, { resetGeofenceState } from './geofencesSlice';
import GeofencesRecords, { resetGeofencesRecordsState } from './geofencesRecordsSlice';
import CheckInOut, { resetCheckInOut } from './checkInOutSlice';
import Points, { resetPointsState } from './pointsSlice';
import Alerts, { resetAlertState } from './alertsSlice';
import DailyRecords, { resetDailyRecordState } from './dailyRecordsSlice';
import Assignments, { resetAssignmentState } from './assignmentsSlice';
import Clients, {
  resetClientsWithCoordsState,
  resetClientsWithoutCoordsState,
} from './clientsSlice';

const reducer = combineReducers({
  Points,
  MapData,
  Users,
  Vehicles,
  Geofences,
  GeofencesRecords,
  CheckInOut,
  Alerts,
  DailyRecords,
  Assignments,
  Clients,
});

export const resetLocationState = (dispatch) => {
  dispatch(resetPointsState());
  dispatch(resetMapDataState());
  dispatch(resetUsersState());
  dispatch(resetVehicles());
  dispatch(resetGeofenceState());
  dispatch(resetGeofencesRecordsState());
  resetCheckInOut(dispatch);
  dispatch(resetAlertState());
  dispatch(resetDailyRecordState());
  dispatch(resetAssignmentState());
  dispatch(resetClientsWithCoordsState());
  dispatch(resetClientsWithoutCoordsState());
};

export default reducer;
