/* eslint-disable camelcase */
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';

const FieldItemDetails = ({ field, className = '' }) => {
  const { t } = useTranslation('assignmentsApp');
  const dateToText = (date) => {
    date.setDate(date.getDate() + 1);

    const dayWeek = format(date, 'EEEE');
    const dayMonth = format(date, 'dd');
    const month = format(date, 'LLLL');
    const year = format(date, 'yyyy');
    return t('DATE_FIELD_VALUE', { dayWeek, dayMonth, month, year });
  };

  const conditionalToText = (conditionalInfo) => {
    if (!conditionalInfo) return '';
    if (conditionalInfo.value_id === true)
      return t('TERNARY_FIELD_NOT', { field_title: conditionalInfo.field_title });

    if (conditionalInfo.value_id === false)
      return t('TERNARY_FIELD_YES', { field_title: conditionalInfo.field_title });

    if (conditionalInfo.value_id === null)
      return t('TERNARY_FIELD_NULL', { field_title: conditionalInfo.field_title });

    if (typeof conditionalInfo.value_id === 'string')
      return t('OPTIONS_FIELD_SELECTED', {
        field_title: conditionalInfo.field_title,
        field_value: conditionalInfo.field_value,
      });

    return '';
  };

  // if (!field.show) return null;
  switch (field.type) {
    case 'text':
      return (
        <div className={className}>
          <Typography className="my-5 font-semibold" color="secondary" variant="body2">
            {field.required && <b className="text-red-600 mx-2">*</b>}
            {field.title}
          </Typography>

          <Typography
            className="bg-white px-2 py-4 rounded-6  font-semibold whitespace-pre-wrap shadow-2 border-black border-1"
            color="GrayText"
            variant="body2"
          >
            {field.value || ' '}
          </Typography>

          <div className="flex gap-5">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
        </div>
      );

    case 'multi-text':
      return (
        <div className={className}>
          <Typography className="my-5 font-semibold" color="secondary" variant="body2">
            {field.required && <b className="text-red-600 mx-2">*</b>}
            {field.title}
          </Typography>
          {field.values.map((value, index) => (
            <Typography
              className="bg-white px-2 py-4 my-6 rounded-6 font-semibold whitespace-pre-wrap border-black border-1"
              color="GrayText"
              variant="body2"
              key={index}
            >
              {value || ' '}
            </Typography>
          ))}
          <div className="flex items-center gap-5">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
        </div>
      );

    case 'select':
      return (
        <div className={className}>
          <Typography className="my-5 font-semibold" color="secondary" variant="body2">
            {field.required && <b className="text-red-600 mx-2">*</b>}
            {field.title}
          </Typography>
          <div className="flex flex-col px-16">
            {field.select_values.map((select) => (
              <FormControlLabel
                key={select.idx}
                className="bg-white rounded-6 my-2 pointer-events-none"
                control={
                  <Checkbox
                    readOnly
                    id={field.id + select.id}
                    checked={select.idx === field.select_default_value}
                    size="small"
                  />
                }
                label={
                  <Typography color="secondary" variant="caption" className="font-600 px-5 text-15">
                    {select.value}
                  </Typography>
                }
              />
            ))}
          </div>
          <div className="flex items-center gap-5">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
        </div>
      );
    case 'select-multiple':
      return (
        <div className={className}>
          <Typography className="my-5 font-semibold" color="secondary" variant="body2">
            {field.required && <b className="text-red-600 mx-2">*</b>}
            {field.title}
          </Typography>
          <div className="flex flex-col px-16">
            {field.select_values.map((select) => (
              <FormControlLabel
                key={select.id}
                className="bg-white rounded-6 my-2 pointer-events-none"
                control={
                  <Checkbox
                    id={field.id + select.id}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    checked={select.checked}
                  />
                }
                label={select.value}
              />
            ))}
          </div>
          <div className="flex items-center gap-5">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
        </div>
      );

    case 'ternary':
      return (
        <div className={className}>
          <Typography className="my-5 font-semibold" color="secondary" variant="body2">
            {field.required && <b className="text-red-600 mx-2">*</b>}
            {field.title}
          </Typography>
          <div className="flex justify-around">
            <Button
              className="p-0 pointer-events-none"
              color={field.value === true ? 'secondary' : 'inherit'}
              size="small"
              variant="contained"
            >
              {t('YES')}
            </Button>
            <Button
              className="p-0 pointer-events-none"
              color={field.value === false ? 'secondary' : 'inherit'}
              size="small"
              variant="contained"
            >
              {t('NO')}
            </Button>
          </div>
          <div className="flex items-center gap-5">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
        </div>
      );
    case 'gps':
      return (
        <div className={className}>
          <Typography className="my-5 font-semibold" color="secondary" variant="body2">
            {field.required && <b className="text-red-600 mx-2">*</b>}
            {field.title}
          </Typography>

          <div className="flex flex-col items-center py-5">
            <FuseSvgIcon className="text-48" size={24} color="action">
              material-solid:location_on
            </FuseSvgIcon>
            <Typography className="my-5 font-semibold" color="GrayText" variant="body2">
              {t('FIELD_TYPE')}:{t('GPS_TITLE')}
            </Typography>
          </div>

          <div className="flex items-center gap-5">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
        </div>
      );

    case 'date':
      return (
        <div className={className}>
          <Typography className="my-5 font-semibold" color="secondary" variant="body2">
            {field.required && <b className="text-red-600 mx-2">*</b>}
            {field.title}
          </Typography>
          <Typography
            className="bg-white px-2 py-4 rounded-6  font-semibold whitespace-pre-wrap"
            color="GrayText"
            variant="body2"
          >
            {field.value ? (
              <>{dateToText(new Date(field.value))}</>
            ) : (
              <div className="flex flex-col items-center py-5">
                <FuseSvgIcon className="text-48" size={24} color="action">
                  material-solid:calendar_today
                </FuseSvgIcon>
                <Typography className="my-5 font-semibold" color="GrayText" variant="body2">
                  {t('FIELD_TYPE')}:{t('DATE_TITLE')}
                </Typography>
              </div>
            )}
          </Typography>
          <div className="flex items-center gap-5">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
        </div>
      );
    case 'signature':
      return (
        <div className={className}>
          <Typography className="my-5 font-semibold" color="secondary" variant="body2">
            {field.required && <b className="text-red-600 mx-2">*</b>}
            {field.title}
          </Typography>

          <div className="flex flex-col items-center py-5">
            <FuseSvgIcon className="text-48" size={24} color="action">
              material-solid:content_paste
            </FuseSvgIcon>
            <Typography className="my-5 font-semibold" color="GrayText" variant="body2">
              {t('FIELD_TYPE')}:{t('SIGNATURE_TITLE')}
            </Typography>
          </div>

          <div className="flex items-center gap-5">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
        </div>
      );
    case 'barcode':
      return (
        <div className={className}>
          <Typography className="my-5 font-semibold" color="secondary" variant="body2">
            {field.required && <b className="text-red-600 mx-2">*</b>}
            {field.title}
          </Typography>
          <div className="flex flex-col items-center">
            <FuseSvgIcon className="text-48" size={24} color="action">
              material-solid:qr_code
            </FuseSvgIcon>
            <Typography
              className=" px-2 py-4 my-4  font-semibold "
              color="GrayText"
              variant="body2"
            >
              {t('FIELD_TYPE')}:{t('BARCODE_TITLE')}
            </Typography>
          </div>
          <div className="flex items-center gap-5">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
        </div>
      );
    case 'print-ticket':
      return (
        <div className={className}>
          <Typography className="my-5 font-semibold" color="secondary" variant="body2">
            {field.required && <b className="text-red-600 mx-2">*</b>}
            {field.title}
          </Typography>
          <div className="px-10 py-5 bg-grey-300 border-dotted border-x-0 border-y-2 border-black">
            <Typography className="font-serif" color="black" variant="body2">
              {field.value || ' '}
            </Typography>
          </div>
          <div className="flex items-center gap-5">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
        </div>
      );
    case 'photo':
      return (
        <div className={className}>
          <div className="flex justify-between items-center">
            <Typography className="my-5 font-semibold" color="secondary" variant="body2">
              {field.required && <b className="text-red-600 mx-2">*</b>}
              {field.title}
            </Typography>
          </div>
          <div className="flex flex-col items-center py-5">
            <FuseSvgIcon className="text-48" size={24} color="action">
              material-solid:camera_alt
            </FuseSvgIcon>
            <Typography className="my-5 font-semibold" color="GrayText" variant="body2">
              {t('FIELD_TYPE')}:{t('PHOTO_TITLE')}
            </Typography>
          </div>
          <div className="flex items-center gap-5">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
        </div>
      );
    case 'section':
      return (
        <div className={className}>
          <Divider
            sx={{
              '&::before, &::after': {
                borderColor: 'secondary.light',
              },
            }}
          >
            <Typography
              color="secondary"
              variant="caption"
              className="font-600 px-5 text-15"
              sx={{ textWrap: 'wrap' }}
            >
              {field.title}
            </Typography>
          </Divider>
          <Typography
            color="secondary"
            variant="caption"
            className="font-600 px-5 text-15"
            sx={{ textWrap: 'wrap' }}
          >
            {field.description}
          </Typography>
        </div>
      );
    default:
      return null;
  }
};

export default FieldItemDetails;
