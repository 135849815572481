import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import history from '@history';
import ApiRoutes from 'src/app/services/ApiRoutes';
import { t } from 'i18next';
import Socket from 'src/app/services/Socket';

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.request.use(
      (config) => {
        config.headers['Web-version'] = '2.0.0';
        config.headers.Authorization = config.DjangoAuth;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (config) => {
        return config;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (err.response?.status === 401 && err.config && !err.config.__isRetryRequest) {
            // if you ever get an unauthorized response, logout the user
            if (err.config.url === ApiRoutes.login.getToken) {
              this.emit('onLoginFailed', t('authApp:INVALID_USER_PASSWORD'));
            } else {
              this.emit('onAutoLogout', t('authApp:INVALID_TOKEN'));
            }
            this.setSession(null);
          } else if (err.response?.status === 403) {
            this.emit('Forbidden', err.response?.data?.detail);
          } else if (err.response?.status === 0) {
            this.emit('onServerError', t('authApp:SERVER_ERROR'));
            history.push('/');
          }

          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const credentials = this.getCredentials();

    if (!credentials.access) {
      this.emit('onNoAccessToken');

      return;
    }

    if (this.isAuthTokenValid(credentials.access)) {
      this.setSession(credentials);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };

  registerUser = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(ApiRoutes.login.registerUser, data)
        .then(resolve)
        .catch(
          ({
            response: {
              data: { error },
            },
          }) => {
            if (error?.email[0]?.includes('Ya existe')) {
              this.emit('onSignUpFailed', t('authApp:ALREADY_EXIST_ACCOUNT'));
            } else {
              this.emit('onSignUpFailed', t('authApp:SIGN_UP_FAILED'));
            }
          }
        );
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          ApiRoutes.login.getToken,
          {
            email,
            password,
          },
          { withCredentials: true }
        )
        .then((response) => {
          if (response.data.user) {
            this.setSession({
              access: response.data.access,
              refresh: response.data.refresh,
            });

            resolve(response.data.user);
            this.emit('onLogin', response.data.user);
          } else {
            reject(response.data.error);
          }
        })
        .catch(reject);
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      // verify if the token is valid and get the userData
      axios
        .get(ApiRoutes.login.getUserInfo, {
          headers: { Authorization: `Bearer ${this.getCredentials().access}` },
        })
        .then((response) => {
          if (response.data) {
            // response.data.role = ['platform_admin'];
            resolve(response.data);
          } else {
            this.logout();
            reject(new Error(t('authApp:INVALID_TOKEN')));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error(t('authApp:INVALID_TOKEN')));
        });
    });
  };

  setSession = (credentials) => {
    if (credentials) {
      // jwt
      localStorage.setItem('jwt_access_token', credentials.access);
      localStorage.setItem('jwt_refresh_token', credentials.refresh);
      // axios
      axios.defaults.DjangoAuth = `Bearer ${credentials.access}`;
    } else {
      // jwt
      localStorage.removeItem('jwt_access_token');
      localStorage.removeItem('jwt_refresh_token');
      // axios
      delete axios.defaults.headers.common.Authorization;
      delete axios.defaults.DjangoAuth;
    }
  };

  logout = () => {
    this.setSession(null);
    Socket.disconnectWorkspace();
    // deleteCookie('csrftoken');
    this.emit('onLogout', 'Logged out');
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  getCredentials = () => {
    return {
      access: window.localStorage.getItem('jwt_access_token'),
      refresh: window.localStorage.getItem('jwt_refresh_token'),
    };
  };
}

const instance = new JwtService();

export default instance;
