import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { selectUser } from 'app/store/userSlice';
import { useTranslation } from 'react-i18next';
import { selectWorkspace } from 'app/store/workspaceSlice';
import { authRoles } from 'src/app/auth';
import Socket from 'src/app/services/Socket';

function UserMenu(props) {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { t } = useTranslation('navigation');
  const worskspace = useSelector(selectWorkspace);

  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const onClickWorkspace = async () => {
    userMenuClose();
    await navigate('/workspaces');
    Socket.disconnectWorkspace();
  };

  const onClickOrganizacion = async () => {
    userMenuClose();
    await navigate(
      authRoles.org.includes(user.role) ? `/org/${user.organizations[0].id}` : '/organizations'
    );
    Socket.disconnectWorkspace();
  };

  return (
    <>
      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
        color="inherit"
      >
        <div className="hidden md:flex flex-col mx-4 items-end">
          <Typography component="span" className="font-semibold flex">
            {user?.name || user?.email}
          </Typography>
          <Typography className="text-11 font-medium" color="text.secondary">
            {t(`ROLE_${user.role.toUpperCase()}`)}
          </Typography>
        </div>
        {user?.name ? (
          <Avatar className="md:mx-4">{user?.name?.charAt(0)}</Avatar>
        ) : (
          <Avatar className="md:mx-4">{user?.email?.charAt(0)}</Avatar>
        )}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        <MenuItem
          component={Link}
          to={`/ws/${worskspace.id}/users/${user.id}`}
          onClick={userMenuClose}
          role="button"
        >
          <ListItemIcon className="min-w-40">
            <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>
          </ListItemIcon>
          <ListItemText primary={t('myProfile')} />
        </MenuItem>
        {!authRoles.user.includes(user.role) && (
          <MenuItem onClick={onClickOrganizacion}>
            <ListItemIcon className="min-w-40">
              <FuseSvgIcon>material-solid:corporate_fare</FuseSvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={t(authRoles.org.includes(user.role) ? 'organization' : 'organizations')}
            />
          </MenuItem>
        )}
        <MenuItem onClick={onClickWorkspace}>
          <ListItemIcon className="min-w-40">
            <FuseSvgIcon>material-solid:workspaces</FuseSvgIcon>
          </ListItemIcon>
          <ListItemText primary={t('workspaces')} />
        </MenuItem>
        <MenuItem
          component={NavLink}
          to="/sign-out"
          onClick={() => {
            userMenuClose();
          }}
        >
          <ListItemIcon className="min-w-40">
            <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
          </ListItemIcon>
          <ListItemText primary={t('SIGN_OFF')} />
        </MenuItem>
      </Popover>
    </>
  );
}

export default UserMenu;
