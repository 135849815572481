import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

export const getPermissionsAll = createAsyncThunk(
  'selectsData/permissions/getPermissionsAll',
  async (params, { dispatch, getState }) => {
    const { isPermissionsLoaded } = getState().selectsData.permissions;
    if (!isPermissionsLoaded) {
      const response = await axios.get(`${ApiRoutes.permissionsEndPoint(params.wsId)}all/`);
      const { data } = await response;

      dispatch(setIsPermissionsLoaded(true));
      return data;
    }
    return [];
  }
);

const permissionsAdapter = createEntityAdapter({ selectId: (permission) => permission.name });

export const { selectAll: selectPermissions, selectById: selectPermissionById } =
  permissionsAdapter.getSelectors((state) => state.selectsData.permissions);

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: permissionsAdapter.getInitialState({
    isPermissionsLoaded: false,
  }),
  reducers: {
    setIsPermissionsLoaded: (state, action) => {
      state.isPermissionsLoaded = action.payload;
    },
  },
  extraReducers: {
    [getPermissionsAll.fulfilled]: permissionsAdapter.addMany,
  },
});

// only use for this self file
export const { setIsPermissionsLoaded } = permissionsSlice.actions;

export default permissionsSlice.reducer;
