import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import es from './i18n/es';
import LeftWorkspace from './LeftWorkspace';

i18next.addResourceBundle('en', 'workspaceApp', en);
i18next.addResourceBundle('es', 'workspaceApp', es);

const Workspaces = lazy(() => import('./workspaces/Workspaces'));
const WorkspacesAppConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: true,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'left-workspace',
      element: <LeftWorkspace />,
    },
    {
      path: 'workspaces',
      element: <Workspaces />,
    },
  ],
};

export default WorkspacesAppConfig;
