import { useSelector } from 'react-redux';
import { Chip } from '@mui/material';
import { selectUserById } from '../usersSlice';

const UserChip = ({ id, chipProps }) => {
  const user = useSelector((state) => selectUserById(state, id));
  return (
    <Chip
      {...chipProps}
      sx={(theme) => ({
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        fontSize: 12,
        '& .MuiChip-deleteIcon': {
          color: theme.palette.secondary.contrastText,
          fontSize: 15,
        },
      })}
      variant="outlined"
      label={`${user?.name}(${user?.email})`}
      id={id}
      key={id}
    />
  );
};

export default UserChip;
