import { useSelector } from 'react-redux';
import { Chip } from '@mui/material';
import { selectTeamById } from '../teamsSlice';

const TeamChip = ({ id, chipProps }) => {
  const team = useSelector((state) => selectTeamById(state, id));
  return (
    <Chip
      {...chipProps}
      sx={(theme) => ({
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        fontSize: 12,
        '& .MuiChip-deleteIcon': {
          color: theme.palette.secondary.contrastText,
          fontSize: 15,
        },
      })}
      variant="outlined"
      label={team?.name}
      id={id}
      key={id}
    />
  );
};

export default TeamChip;
