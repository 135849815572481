import * as React from 'react';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import _ from '@lodash';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { selectCountries } from 'app/store/selectsData/countrySlice';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import { t } from 'i18next';

const CountrySelector = forwardRef(({ value, onChange, className }, ref) => {
  // const { t } = useTranslation('organizationApp');

  const countries = useSelector(selectCountries);
  const country = _.find(countries, { id: value });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div ref={ref}>
      <Button
        className="flex items-center"
        id="country-button"
        aria-controls={open ? 'country-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        disableRipple
      >
        {value ? (
          <>
            <Box
              component="span"
              className="w-24 h-16 overflow-hidden"
              sx={{
                background: "url('/assets/images/flags/flags.png') no-repeat 0 0",
                backgroundSize: '24px 3876px',
                backgroundPosition: country?.imagenPosition,
              }}
            />
            <span className="ml-8 font-medium">{country?.name}</span>
          </>
        ) : (
          <>
            <FuseSvgIcon className="text-48" size={20} color="action">
              material-solid:public
            </FuseSvgIcon>
            <span className="ml-8 font-medium">{t('navigation:SELECT_COUNTRY')}</span>
          </>
        )}
      </Button>
      <Menu
        id="country-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {countries.map((item) => (
          <MenuItem
            onClick={() => {
              onChange(item.id);
              handleClose();
            }}
            disableRipple
            key={item.id}
          >
            <Box
              component="span"
              className="w-24 h-16 overflow-hidden"
              sx={{
                background: "url('/assets/images/flags/flags.png') no-repeat 0 0",
                backgroundSize: '24px 3876px',
                backgroundPosition: item.imagenPosition,
              }}
            />
            <span className="ml-8 font-medium">{item.name}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
});

export default CountrySelector;
