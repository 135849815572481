import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import FieldText from './Fileds/FieldText';
import FieldMultiText from './Fileds/FieldMultiText';
import FieldSelect from './Fileds/FieldSelect';
import FieldSelectMultiple from './Fileds/FieldSelectMultiple';
import FieldTernary from './Fileds/FieldTernary';
import FieldPhoto from './Fileds/FieldPhoto';
import FieldGPS from './Fileds/FieldGPS';
import FieldDate from './Fileds/FieldDate';
import FieldSignature from './Fileds/FieldSignature';
import FieldBarcode from './Fileds/FieldBarcode';
import FieldPrintTicket from './Fileds/FieldPrintTicket';
import FieldSection from './Fileds/FieldSection';

const FieldSelector = ({ type }) => {
  switch (type) {
    case 'text':
      return <FieldText />;
    case 'multi-text':
      return <FieldMultiText />;
    case 'select':
      return <FieldSelect />;
    case 'select-multiple':
      return <FieldSelectMultiple />;
    case 'ternary':
      return <FieldTernary />;
    case 'photo':
      return <FieldPhoto />;
    case 'gps':
      return <FieldGPS />;
    case 'date':
      return <FieldDate />;
    case 'signature':
      return <FieldSignature />;
    case 'barcode':
      return <FieldBarcode />;
    case 'print-ticket':
      return <FieldPrintTicket />;
    case 'section':
      return <FieldSection />;
    default:
      return null;
  }
};

const Field = ({ onAccept, onCancel, setStep }) => {
  const { t } = useTranslation('assignmentsApp');
  const {
    formState: { isValid },
    getValues,
  } = useFormContext();

  const showPreview = () => setStep(2);

  return (
    <div className="flex flex-col">
      <FieldSelector type={getValues('type')} />
      <div className="flex justify-between px-5 py-8">
        <Button onClick={onCancel} size="small" variant="contained">
          {t('CANCEL')}
        </Button>
        <Button onClick={showPreview} size="small" color="secondary" variant="contained">
          {t('PREVIEW')}
        </Button>
        <Button
          onClick={onAccept}
          size="small"
          color="secondary"
          variant="contained"
          disabled={!isValid}
        >
          {t('ACCEPT')}
        </Button>
      </div>
    </div>
  );
};

export default Field;
