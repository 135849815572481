import { combineReducers } from '@reduxjs/toolkit';
import projects, { resetProjectsState } from './projectsSlice';
import alerts, { resetAlertsState } from './alertsSlice';
// eslint-disable-next-line import/no-cycle
import users, { resetUsersState } from './usersSlice';
import tags, { resetTagsState } from './tagsSlice';
import clients, { resetClientsState } from './clientsSlice';
import roles, { resetRolesState } from './rolesSlice';
import teams, { resetTeamsState } from './teamsSlice';
import csvLoads, { resetCsvLoadsState } from './csvLoadsSlice';

const reducer = combineReducers({
  projects,
  alerts,
  users,
  tags,
  clients,
  roles,
  teams,
  csvLoads,
});

export const resetManagementState = (dispatch) => {
  dispatch(resetProjectsState());
  dispatch(resetAlertsState());
  dispatch(resetUsersState());
  dispatch(resetTagsState());
  dispatch(resetClientsState());
  dispatch(resetRolesState());
  dispatch(resetTeamsState());
  dispatch(resetCsvLoadsState());
};

export default reducer;
