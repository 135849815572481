/* eslint-disable camelcase */
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ConfirmAcction from '@fuse/core/FuseModals/ConfirmAcction';
import FormControlLabel from '@mui/material/FormControlLabel';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Divider, IconButton } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FieldDetails from './modals/FieldDetails/FieldDetails';

const FieldItemEdit = ({ field, className = '', onChangeField, onDelete, onConditional }) => {
  const { t } = useTranslation('assignmentsApp');
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(!field.type);

  const dateToText = (date) => {
    date.setDate(date.getDate() + 1);

    const dayWeek = format(date, 'EEEE');
    const dayMonth = format(date, 'dd');
    const month = format(date, 'LLLL');
    const year = format(date, 'yyyy');
    return t('DATE_FIELD_VALUE', { dayWeek, dayMonth, month, year });
  };

  const deleteField = () => {
    onDelete();
    setIsOpenModalDelete(false);
  };

  const conditionalToText = (conditionalInfo) => {
    if (!conditionalInfo) return '';
    if (conditionalInfo.value_id === true)
      return t('TERNARY_FIELD_NOT', { field_title: conditionalInfo.field_title });

    if (conditionalInfo.value_id === false)
      return t('TERNARY_FIELD_YES', { field_title: conditionalInfo.field_title });

    if (conditionalInfo.value_id === null)
      return t('TERNARY_FIELD_NULL', { field_title: conditionalInfo.field_title });

    if (typeof conditionalInfo.value_id === 'string')
      return t('OPTIONS_FIELD_SELECTED', {
        field_title: conditionalInfo.field_title,
        field_value: conditionalInfo.field_value,
      });

    return '';
  };

  const openModalEdit = () => setIsOpenModalEdit(true);

  const openModalDelete = () => setIsOpenModalDelete(true);

  const closeModal = () => setIsOpenModalDelete(false);

  // if (!field.show) return null;
  switch (field.type) {
    case 'text':
      return (
        <div className={className}>
          <div className="flex justify-between">
            <Typography className="my-5 font-semibold" color="secondary" variant="body2">
              {field.required && <b className="text-red-600 mx-2">*</b>}
              {field.title}
            </Typography>
            <div className="flex gap-5 py-5">
              <Tooltip title={t('EDIT_FIELD')} placement="top">
                <IconButton className="min-w-0" color="secondary" onClick={openModalEdit}>
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:edit
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
              <Tooltip title={t('DELETE_FIELD')} placement="top">
                <IconButton
                  className="min-w-0"
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={openModalDelete}
                >
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:delete_sweep
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <Typography
            className="bg-white px-2 py-4 mr-14 rounded-6 font-semibold whitespace-pre-wrap border-black border-1"
            color="GrayText"
            variant="body2"
          >
            {field.value || ' '}
          </Typography>

          <div className="flex gap-5 mb-6">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
          <FieldDetails
            open={isOpenModalEdit}
            setOpen={setIsOpenModalEdit}
            onConditional={onConditional}
            onChange={onChangeField}
            onDelete={deleteField}
            field={field}
          />
          <ConfirmAcction
            title={t('DELETE_FIELD_FORM_TITLE')}
            description={t('DELETE_FIELD_FORM_DESP', { titleField: field.title })}
            cancelText={t('CANCEL')}
            confirmText={t('ACCEPT')}
            open={isOpenModalDelete}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={deleteField}
          />
        </div>
      );

    case 'multi-text':
      return (
        <div className={className}>
          <div className="flex justify-between">
            <Typography className="my-5 font-semibold" color="secondary" variant="body2">
              {field.required && <b className="text-red-600 mx-2">*</b>}
              {field.title}
            </Typography>
            <div className="flex gap-5 py-5">
              <Tooltip title={t('EDIT_FIELD')} placement="top">
                <IconButton className="min-w-0" color="secondary" onClick={openModalEdit}>
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:edit
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
              <Tooltip title={t('DELETE_FIELD')} placement="top">
                <IconButton
                  className="min-w-0"
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={openModalDelete}
                >
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:delete_sweep
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
            </div>
          </div>
          {field.values.map((value, index) => (
            <Typography
              className="bg-white px-2 py-4 my-6 mr-14 rounded-6 font-semibold whitespace-pre-wrap border-black border-1"
              color="GrayText"
              variant="body2"
              key={index}
            >
              {value || ' '}
            </Typography>
          ))}
          <div className="flex items-center gap-5 mb-6">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
          <FieldDetails
            open={isOpenModalEdit}
            setOpen={setIsOpenModalEdit}
            onConditional={onConditional}
            onChange={onChangeField}
            onDelete={deleteField}
            field={field}
          />
          <ConfirmAcction
            title={t('DELETE_FIELD_FORM_TITLE')}
            description={t('DELETE_FIELD_FORM_DESP', { titleField: field.title })}
            cancelText={t('CANCEL')}
            confirmText={t('ACCEPT')}
            open={isOpenModalDelete}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={deleteField}
          />
        </div>
      );

    case 'select':
      return (
        <div className={className}>
          <div className="flex justify-between">
            <Typography className="my-5 font-semibold" color="secondary" variant="body2">
              {field.required && <b className="text-red-600 mx-2">*</b>}
              {field.title}
            </Typography>
            <div className="flex gap-5 py-5">
              <Tooltip title={t('EDIT_FIELD')} placement="top">
                <IconButton className="min-w-0" color="secondary" onClick={openModalEdit}>
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:edit
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
              <Tooltip title={t('DELETE_FIELD')} placement="top">
                <IconButton
                  className="min-w-0"
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={openModalDelete}
                >
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:delete_sweep
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="flex flex-col px-16">
            {field.select_values.map((select) => (
              <FormControlLabel
                key={select.idx}
                className="bg-white rounded-6 my-2 pointer-events-none"
                control={
                  <Checkbox
                    readOnly
                    size="small"
                    id={field.id + select.id}
                    checked={select.idx === field.select_default_value}
                  />
                }
                label={
                  <Typography color="secondary" variant="caption" className="font-600 px-5 text-15">
                    {select.value}
                  </Typography>
                }
              />
            ))}
          </div>
          <div className="flex items-center gap-5 mb-6">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
          <FieldDetails
            open={isOpenModalEdit}
            setOpen={setIsOpenModalEdit}
            onConditional={onConditional}
            onChange={onChangeField}
            onDelete={deleteField}
            field={field}
          />
          <ConfirmAcction
            title={t('DELETE_FIELD_FORM_TITLE')}
            description={t('DELETE_FIELD_FORM_DESP', { titleField: field.title })}
            cancelText={t('CANCEL')}
            confirmText={t('ACCEPT')}
            open={isOpenModalDelete}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={deleteField}
          />
        </div>
      );
    case 'select-multiple':
      return (
        <div className={className}>
          <div className="flex justify-between">
            <Typography className="my-5 font-semibold" color="secondary" variant="body2">
              {field.required && <b className="text-red-600 mx-2">*</b>}
              {field.title}
            </Typography>
            <div className="flex gap-5 py-5">
              <Tooltip title={t('EDIT_FIELD')} placement="top">
                <IconButton className="min-w-0" color="secondary" onClick={openModalEdit}>
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:edit
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
              <Tooltip title={t('DELETE_FIELD')} placement="top">
                <IconButton
                  className="min-w-0"
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={openModalDelete}
                >
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:delete_sweep
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="flex flex-col px-16">
            {field.select_values.map((select) => (
              <FormControlLabel
                key={select.id}
                className="bg-white rounded-6 my-2 pointer-events-none"
                control={
                  <Checkbox
                    id={field.id + select.id}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    checked={select.checked}
                    size="small"
                  />
                }
                label={
                  <Typography color="secondary" variant="caption" className="font-600 px-5 text-15">
                    {select.value}
                  </Typography>
                }
              />
            ))}
          </div>
          <div className="flex items-center gap-5 mb-6">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
          <FieldDetails
            open={isOpenModalEdit}
            setOpen={setIsOpenModalEdit}
            onConditional={onConditional}
            onChange={onChangeField}
            onDelete={deleteField}
            field={field}
          />
          <ConfirmAcction
            title={t('DELETE_FIELD_FORM_TITLE')}
            description={t('DELETE_FIELD_FORM_DESP', { titleField: field.title })}
            cancelText={t('CANCEL')}
            confirmText={t('ACCEPT')}
            open={isOpenModalDelete}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={deleteField}
          />
        </div>
      );

    case 'ternary':
      return (
        <div className={className}>
          <div className="flex justify-between">
            <Typography className="my-5 font-semibold" color="secondary" variant="body2">
              {field.required && <b className="text-red-600 mx-2">*</b>}
              {field.title}
            </Typography>
            <div className="flex gap-5 py-5">
              <Tooltip title={t('EDIT_FIELD')} placement="top">
                <IconButton className="min-w-0" color="secondary" onClick={openModalEdit}>
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:edit
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
              <Tooltip title={t('DELETE_FIELD')} placement="top">
                <IconButton
                  className="min-w-0"
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={openModalDelete}
                >
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:delete_sweep
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="flex justify-around">
            <Button
              className="p-0 pointer-events-none"
              color={field.value === true ? 'secondary' : 'inherit'}
              size="small"
              variant="contained"
            >
              {t('YES')}
            </Button>
            <Button
              className="p-0 pointer-events-none"
              color={field.value === false ? 'secondary' : 'inherit'}
              size="small"
              variant="contained"
            >
              {t('NO')}
            </Button>
          </div>
          <div className="flex items-center gap-5 mb-6">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
          <FieldDetails
            open={isOpenModalEdit}
            setOpen={setIsOpenModalEdit}
            onConditional={onConditional}
            onChange={onChangeField}
            onDelete={deleteField}
            field={field}
          />
          <ConfirmAcction
            title={t('DELETE_FIELD_FORM_TITLE')}
            description={t('DELETE_FIELD_FORM_DESP', { titleField: field.title })}
            cancelText={t('CANCEL')}
            confirmText={t('ACCEPT')}
            open={isOpenModalDelete}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={deleteField}
          />
        </div>
      );
    case 'gps':
      return (
        <div className={className}>
          <div className="flex justify-between">
            <Typography className="my-5 font-semibold" color="secondary" variant="body2">
              {field.required && <b className="text-red-600 mx-2">*</b>}
              {field.title}
            </Typography>
            <div className="flex gap-5 py-5">
              <Tooltip title={t('EDIT_FIELD')} placement="top">
                <IconButton className="min-w-0" color="secondary" onClick={openModalEdit}>
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:edit
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
              <Tooltip title={t('DELETE_FIELD')} placement="top">
                <IconButton
                  className="min-w-0"
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={openModalDelete}
                >
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:delete_sweep
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <div className="flex flex-col items-center py-5">
            <FuseSvgIcon className="text-48" size={24} color="action">
              material-solid:location_on
            </FuseSvgIcon>
            <Typography className="my-5 font-semibold" color="GrayText" variant="body2">
              {t('FIELD_TYPE')}:{t('GPS_TITLE')}
            </Typography>
          </div>

          <div className="flex items-center gap-5 mb-6">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
          <FieldDetails
            open={isOpenModalEdit}
            setOpen={setIsOpenModalEdit}
            onConditional={onConditional}
            onChange={onChangeField}
            onDelete={deleteField}
            field={field}
          />
          <ConfirmAcction
            title={t('DELETE_FIELD_FORM_TITLE')}
            description={t('DELETE_FIELD_FORM_DESP', { titleField: field.title })}
            cancelText={t('CANCEL')}
            confirmText={t('ACCEPT')}
            open={isOpenModalDelete}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={deleteField}
          />
        </div>
      );

    case 'date':
      return (
        <div className={className}>
          <div className="flex justify-between">
            <Typography className="my-5 font-semibold" color="secondary" variant="body2">
              {field.required && <b className="text-red-600 mx-2">*</b>}
              {field.title}
            </Typography>
            <div className="flex gap-5 py-5">
              <Tooltip title={t('EDIT_FIELD')} placement="top">
                <IconButton className="min-w-0" color="secondary" onClick={openModalEdit}>
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:edit
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
              <Tooltip title={t('DELETE_FIELD')} placement="top">
                <IconButton
                  className="min-w-0"
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={openModalDelete}
                >
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:delete_sweep
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
            </div>
          </div>
          {field.value ? (
            <Typography
              className="bg-white px-2 py-4 mr-12 rounded-6 font-semibold whitespace-pre-wrap"
              color="GrayText"
              variant="body2"
            >
              {dateToText(new Date(field.value))}
            </Typography>
          ) : (
            <div className="flex flex-col items-center py-5">
              <FuseSvgIcon className="text-48" size={24} color="action">
                material-solid:calendar_today
              </FuseSvgIcon>
              <Typography className="my-5 font-semibold" color="GrayText" variant="body2">
                {t('FIELD_TYPE')}:{t('DATE_TITLE')}
              </Typography>
            </div>
          )}
          <div className="flex items-center gap-5 mb-6">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
          <FieldDetails
            open={isOpenModalEdit}
            setOpen={setIsOpenModalEdit}
            onConditional={onConditional}
            onChange={onChangeField}
            onDelete={deleteField}
            field={field}
          />
          <ConfirmAcction
            title={t('DELETE_FIELD_FORM_TITLE')}
            description={t('DELETE_FIELD_FORM_DESP', { titleField: field.title })}
            cancelText={t('CANCEL')}
            confirmText={t('ACCEPT')}
            open={isOpenModalDelete}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={deleteField}
          />
        </div>
      );
    case 'signature':
      return (
        <div className={className}>
          <div className="flex justify-between">
            <Typography className="my-5 font-semibold" color="secondary" variant="body2">
              {field.required && <b className="text-red-600 mx-2">*</b>}
              {field.title}
            </Typography>
            <div className="flex gap-5 py-5">
              <Tooltip title={t('EDIT_FIELD')} placement="top">
                <IconButton className="min-w-0" color="secondary" onClick={openModalEdit}>
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:edit
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
              <Tooltip title={t('DELETE_FIELD')} placement="top">
                <IconButton
                  className="min-w-0"
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={openModalDelete}
                >
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:delete_sweep
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <div className="flex flex-col items-center py-5">
            <FuseSvgIcon className="text-48" size={24} color="action">
              material-solid:content_paste
            </FuseSvgIcon>
            <Typography className="my-5 font-semibold" color="GrayText" variant="body2">
              {t('FIELD_TYPE')}:{t('SIGNATURE_TITLE')}
            </Typography>
          </div>

          <div className="flex items-center gap-5 mb-6">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
          <FieldDetails
            open={isOpenModalEdit}
            setOpen={setIsOpenModalEdit}
            onConditional={onConditional}
            onChange={onChangeField}
            onDelete={deleteField}
            field={field}
          />
          <ConfirmAcction
            title={t('DELETE_FIELD_FORM_TITLE')}
            description={t('DELETE_FIELD_FORM_DESP', { titleField: field.title })}
            cancelText={t('CANCEL')}
            confirmText={t('ACCEPT')}
            open={isOpenModalDelete}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={deleteField}
          />
        </div>
      );
    case 'barcode':
      return (
        <div className={className}>
          <div className="flex justify-between">
            <Typography className="my-5 font-semibold" color="secondary" variant="body2">
              {field.required && <b className="text-red-600 mx-2">*</b>}
              {field.title}
            </Typography>
            <div className="flex gap-5 py-5">
              <Tooltip title={t('EDIT_FIELD')} placement="top">
                <IconButton className="min-w-0" color="secondary" onClick={openModalEdit}>
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:edit
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
              <Tooltip title={t('DELETE_FIELD')} placement="top">
                <IconButton
                  className="min-w-0"
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={openModalDelete}
                >
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:delete_sweep
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <FuseSvgIcon className="text-48" size={24} color="action">
              material-solid:qr_code
            </FuseSvgIcon>
            <Typography
              className=" px-2 py-4 my-4  font-semibold "
              color="GrayText"
              variant="body2"
            >
              {t('FIELD_TYPE')}:{t('BARCODE_TITLE')}
            </Typography>
          </div>
          <div className="flex items-center gap-5 mb-6">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
          <FieldDetails
            open={isOpenModalEdit}
            setOpen={setIsOpenModalEdit}
            onConditional={onConditional}
            onChange={onChangeField}
            onDelete={deleteField}
            field={field}
          />
          <ConfirmAcction
            title={t('DELETE_FIELD_FORM_TITLE')}
            description={t('DELETE_FIELD_FORM_DESP', { titleField: field.title })}
            cancelText={t('CANCEL')}
            confirmText={t('ACCEPT')}
            open={isOpenModalDelete}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={deleteField}
          />
        </div>
      );
    case 'print-ticket':
      return (
        <div className={className}>
          <div className="flex justify-between">
            <Typography className="my-5 font-semibold" color="secondary" variant="body2">
              {field.required && <b className="text-red-600 mx-2">*</b>}
              {field.title}
            </Typography>
            <div className="flex gap-5 py-5">
              <Tooltip title={t('EDIT_FIELD')} placement="top">
                <IconButton className="min-w-0" color="secondary" onClick={openModalEdit}>
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:edit
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
              <Tooltip title={t('DELETE_FIELD')} placement="top">
                <IconButton
                  className="min-w-0"
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={openModalDelete}
                >
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:delete_sweep
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="px-10 py-5 mr-12 bg-grey-300 border-dotted border-x-0 border-y-2 border-black">
            <Typography className="font-serif" color="black" variant="body2">
              {field.value || ' '}
            </Typography>
          </div>
          <div className="flex items-center gap-5 mb-6">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
          <FieldDetails
            open={isOpenModalEdit}
            setOpen={setIsOpenModalEdit}
            onConditional={onConditional}
            onChange={onChangeField}
            onDelete={deleteField}
            field={field}
          />
          <ConfirmAcction
            title={t('DELETE_FIELD_FORM_TITLE')}
            description={t('DELETE_FIELD_FORM_DESP', { titleField: field.title })}
            cancelText={t('CANCEL')}
            confirmText={t('ACCEPT')}
            open={isOpenModalDelete}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={deleteField}
          />
        </div>
      );
    case 'photo':
      return (
        <div className={className}>
          <div className="flex justify-between">
            <Typography className="my-5 font-semibold" color="secondary" variant="body2">
              {field.required && <b className="text-red-600 mx-2">*</b>}
              {field.title}
            </Typography>
            <div className="flex gap-5 py-5">
              <Tooltip title={t('EDIT_FIELD')} placement="top">
                <IconButton className="min-w-0" color="secondary" onClick={openModalEdit}>
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:edit
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
              <Tooltip title={t('DELETE_FIELD')} placement="top">
                <IconButton
                  className="min-w-0"
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={openModalDelete}
                >
                  <FuseSvgIcon className="text-15" size={18}>
                    material-solid:delete_sweep
                  </FuseSvgIcon>
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="flex flex-col items-center py-5">
            <FuseSvgIcon className="text-48" size={24} color="action">
              material-solid:camera_alt
            </FuseSvgIcon>
            <Typography className="my-5 font-semibold" color="GrayText" variant="body2">
              {t('FIELD_TYPE')}:{t('PHOTO_TITLE')}
            </Typography>
          </div>
          <div className="flex items-center gap-5 mb-6">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
          <FieldDetails
            open={isOpenModalEdit}
            setOpen={setIsOpenModalEdit}
            onConditional={onConditional}
            onChange={onChangeField}
            onDelete={deleteField}
            field={field}
          />
          <ConfirmAcction
            title={t('DELETE_FIELD_FORM_TITLE')}
            description={t('DELETE_FIELD_FORM_DESP', { titleField: field.title })}
            cancelText={t('CANCEL')}
            confirmText={t('ACCEPT')}
            open={isOpenModalDelete}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={deleteField}
          />
        </div>
      );
    case 'section':
      return (
        <div className={className}>
          <div className="flex justify-end w-full">
            <Tooltip title={t('EDIT_FIELD')} placement="top">
              <IconButton className="min-w-0" color="secondary" onClick={openModalEdit}>
                <FuseSvgIcon className="text-15" size={18}>
                  material-solid:edit
                </FuseSvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title={t('DELETE_FIELD')} placement="top">
              <IconButton
                className="min-w-0"
                variant="contained"
                color="error"
                size="small"
                onClick={openModalDelete}
              >
                <FuseSvgIcon className="text-15" size={18}>
                  material-solid:delete_sweep
                </FuseSvgIcon>
              </IconButton>
            </Tooltip>
          </div>
          <Divider
            sx={{
              '&::before, &::after': {
                borderColor: 'secondary.light',
              },
              marginRight: '25.6px',
            }}
          >
            <Typography
              color="secondary"
              variant="caption"
              className="font-600 px-5 text-15 "
              sx={{ textWrap: 'wrap' }}
            >
              {field.title}
            </Typography>
          </Divider>
          <Typography
            color="secondary"
            variant="caption"
            className="font-600 px-5 text-15"
            sx={{ textWrap: 'wrap' }}
          >
            {field.description}
          </Typography>
          <div className="flex items-center gap-5 mb-6">
            {field.has_conditional && (
              <Typography
                color="GrayText"
                variant="caption"
                className="font-600 px-5 text-11 italic"
              >
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
          <FieldDetails
            open={isOpenModalEdit}
            setOpen={setIsOpenModalEdit}
            onConditional={onConditional}
            onChange={onChangeField}
            onDelete={deleteField}
            field={field}
          />
          <ConfirmAcction
            title={t('DELETE_FIELD_FORM_TITLE')}
            description={t('DELETE_FIELD_FORM_DESP', { titleField: field.title })}
            cancelText={t('CANCEL')}
            confirmText={t('ACCEPT')}
            open={isOpenModalDelete}
            onClose={closeModal}
            onCancel={closeModal}
            onConfirm={deleteField}
          />
        </div>
      );
    case null:
      return (
        <FieldDetails
          open={isOpenModalEdit}
          field={field}
          onConditional={onConditional}
          onChange={onChangeField}
          setOpen={setIsOpenModalEdit}
          onDelete={deleteField}
        />
      );
    default:
      return null;
  }
};

export default FieldItemEdit;
