import { createSlice } from '@reduxjs/toolkit';

const mapSlice = createSlice({
  name: 'map',
  initialState: {
    mapType: 'OpenStreetMap',
  },
  reducers: {
    changeMap: (state, action) => {
      state.mapType = action.payload;
    },
  },
});

export const { changeMap } = mapSlice.actions;

// export const curren = ({ fuse }) => fuse.navbar;

export default mapSlice.reducer;
