import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

export const getGeoAlertsAll = createAsyncThunk(
  'selectsData/alert/getAlertsAll',
  async (wsId, { dispatch, getState }) => {
    const { isGeoAlertsLoaded } = getState().selectsData.alerts;
    if (!isGeoAlertsLoaded) {
      const { data } = await axios.get(ApiRoutes.getAllAlertsByType(wsId, 'geo'));

      dispatch(setIsAlertsLoaded(true));
      return data;
    }
    return [];
  }
);

export const upsertAlert = createAsyncThunk(
  'selectsData/alert/upsertAlert',
  async (alert, { dispatch, getState }) => {
    return alert;
  }
);

const alertsAdapter = createEntityAdapter();

export const { selectAll: selectAlerts, selectById: selectAlertById } = alertsAdapter.getSelectors(
  (state) => state.selectsData.alerts
);

const initialState = alertsAdapter.getInitialState({
  isAlertsLoaded: false,
  isGeoAlertsLoaded: false,
});

const alertsSlice = createSlice({
  name: 'alerts',
  initialState: alertsAdapter.getInitialState(initialState),
  reducers: {
    setIsAlertsLoaded: (state, action) => {
      state.isAlertsLoaded = action.payload;
    },
    setIsGeoAlertsLoaded: (state, action) => {
      state.isGeoAlertsLoaded = action.payload;
    },
    resetAlertsState: () => initialState,
  },
  extraReducers: {
    [getGeoAlertsAll.fulfilled]: alertsAdapter.upsertMany,
    [upsertAlert.fulfilled]: alertsAdapter.upsertOne,
  },
});

export const { setIsAlertsLoaded, setIsGeoAlertsLoaded, resetAlertsState } = alertsSlice.actions;

export default alertsSlice.reducer;
