import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { t } from 'i18next';

import { setPosition } from './mapDataSlice';

export const getVehicles = createAsyncThunk(
  'LocationApp/MapData/vehicles/getVehicles',
  async (ws, { dispatch, getState }) => {
    const response = await axios.get(ApiRoutes.getVehiclesLocation(ws));
    const data = await response.data.map((e) => ({ ...e, geometry: { type: 'Vehicle' } }));

    if (data.length > 0) {
      dispatch(setPosition({ coords: [data[0].lat, data[0].lng], zoom: 12, setPosition: true }));
    } else {
      dispatch(showMessage({ message: t('locationApp:NO_VEHICLES_FOUND'), variant: 'warning' }));
    }
    return data;
  }
);

const vehiclesAdapter = createEntityAdapter({});

export const { selectAll: selectVehicles } = vehiclesAdapter.getSelectors(
  (state) => state.LocationApp.Vehicles
);
const initialState = vehiclesAdapter.getInitialState({});
const vehiclesSlice = createSlice({
  name: 'LocationApp/MapData/vehicles',
  initialState: vehiclesAdapter.getInitialState({}),
  reducers: {
    resetVehicles: () => initialState,
  },
  extraReducers: {
    [getVehicles.fulfilled]: vehiclesAdapter.setAll,
  },
});

export const { resetVehicles } = vehiclesSlice.actions;

export default vehiclesSlice.reducer;
