/* eslint-disable camelcase */
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { AppBar, Divider, IconButton, Toolbar } from '@mui/material';

const FieldSelector = ({ field, className = '' }) => {
  const { t } = useTranslation('assignmentsApp');
  const dateToText = (date) => {
    date.setDate(date.getDate() + 1);

    const dayWeek = format(date, 'EEEE');
    const dayMonth = format(date, 'dd');
    const month = format(date, 'LLLL');
    const year = format(date, 'yyyy');
    return t('DATE_FIELD_VALUE', { dayWeek, dayMonth, month, year });
  };

  const conditionalToText = (conditionalInfo) => {
    if (!conditionalInfo) return '';
    if (conditionalInfo.value_id === true)
      return t('TERNARY_FIELD_NOT', { field_title: conditionalInfo.field_title });

    if (conditionalInfo.value_id === false)
      return t('TERNARY_FIELD_YES', { field_title: conditionalInfo.field_title });

    if (conditionalInfo.value_id === null)
      return t('TERNARY_FIELD_NULL', { field_title: conditionalInfo.field_title });

    if (typeof conditionalInfo.value_id === 'string')
      return t('OPTIONS_FIELD_SELECTED', {
        field_title: conditionalInfo.field_title,
        field_value: conditionalInfo.field_value,
      });

    return '';
  };

  // if (!field.show) return null;
  switch (field.type) {
    case 'text':
      return (
        <div className={className}>
          <Typography className="my-5 font-semibold" color="secondary" variant="body2">
            {field.required && <b className="text-red-600 mx-2">*</b>}
            {field.title}
          </Typography>

          <Typography
            className="bg-white px-2 py-4 rounded-6  font-semibold whitespace-pre-wrap shadow-2"
            color="GrayText"
            variant="body2"
          >
            {field.value || ' '}
          </Typography>

          <div className="flex gap-5">
            {field.has_conditional && (
              <Typography color="GrayText" variant="caption" className="font-600 px-5 text-11">
                {conditionalToText(field?.conditional_info)}
              </Typography>
            )}
          </div>
        </div>
      );

    case 'multi-text':
      return (
        <div className={className}>
          <div className="flex justify-between items-center">
            <Typography className="my-5 font-semibold" color="secondary" variant="body2">
              {field.required && <b className="text-red-600 mx-2">*</b>}
              {field.title}
            </Typography>
            <FuseSvgIcon className="text-48" size={20} color="secondary">
              heroicons-solid:plus-circle
            </FuseSvgIcon>
          </div>
          {field.values.map((value, index) => (
            <Typography
              className="bg-white px-2 py-4 my-6 rounded-6 font-semibold whitespace-pre-wrap flex justify-between"
              color="GrayText"
              variant="body2"
              key={index}
            >
              {value || ' '}
              {field.values.length > 1 && (
                <FuseSvgIcon className="text-48" size={18} color="secondary">
                  heroicons-solid:minus-circle
                </FuseSvgIcon>
              )}
            </Typography>
          ))}
        </div>
      );

    case 'select':
      return (
        <div className={className}>
          <Typography className="my-5 font-semibold" color="secondary" variant="body2">
            {field.required && <b className="text-red-600 mx-2">*</b>}
            {field.title}
          </Typography>
          <div className="flex flex-col px-16">
            {field.select_values.map((select) => (
              <FormControlLabel
                key={select.idx}
                className="bg-white rounded-6 my-2 pointer-events-none font-800"
                control={
                  <Checkbox
                    readOnly
                    id={field.id + select.id}
                    checked={select.idx === field.select_default_value}
                    size="small"
                  />
                }
                label={
                  <Typography color="secondary" variant="caption" className="font-600 px-5 text-15">
                    {select.value}
                  </Typography>
                }
              />
            ))}
          </div>
        </div>
      );
    case 'select-multiple':
      return (
        <div className={className}>
          <Typography className="my-5 font-semibold" color="secondary" variant="body2">
            {field.required && <b className="text-red-600 mx-2">*</b>}
            {field.title}
          </Typography>
          <div className="flex flex-col px-16">
            {field.select_values.map((select) => (
              <FormControlLabel
                key={select.id}
                className="bg-white rounded-6 my-2 pointer-events-none"
                control={
                  <Checkbox
                    id={field.id + select.id}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    checked={select.checked}
                    size="small"
                  />
                }
                label={
                  <Typography color="secondary" variant="caption" className="font-600 px-5 text-15">
                    {select.value}
                  </Typography>
                }
              />
            ))}
          </div>
        </div>
      );

    case 'ternary':
      return (
        <div className={className}>
          <Typography className="my-5 font-semibold" color="secondary" variant="body2">
            {field.required && <b className="text-red-600 mx-2">*</b>}
            {field.title}
          </Typography>
          <div className="flex justify-around">
            <Button
              className="p-0 pointer-events-none font-700 "
              color={field.value === true ? 'secondary' : 'inherit'}
              size="small"
              variant="contained"
            >
              {t('YES')}
            </Button>
            <Button
              className="p-0 pointer-events-none"
              color={field.value === false ? 'secondary' : 'inherit'}
              size="small"
              variant="contained"
            >
              {t('NO')}
            </Button>
          </div>
        </div>
      );
    case 'gps':
      return (
        <div className={className}>
          <Typography className="my-5 font-semibold" color="secondary" variant="body2">
            {field.required && <b className="text-red-600 mx-2">*</b>}
            {field.title}
          </Typography>

          <div className="flex flex-col py-5">
            <div className="flex justify-between w-full">
              <Typography className="my-5 font-semibold" color="secondary" variant="body2">
                <p className="text-grey-800 mx-2">{t('LATITUDE')}:</p>15.78347
              </Typography>
              <Typography className="my-5 font-semibold" color="secondary" variant="body2">
                <p className="text-grey-800 mx-2">{t('LONGITUDE')}:</p>-90.2307
              </Typography>
              <FuseSvgIcon className="text-48" size={24} color="secondary">
                material-solid:share
              </FuseSvgIcon>
            </div>
            <Typography className="my-5 font-semibold" color="secondary" variant="body2">
              <p className="text-grey-800 mx-2">{t('ACCURACY')}:</p>21.58 mts
            </Typography>
            <Button
              className="rounded-8 pointer-events-none"
              color="secondary"
              variant="contained"
              size="small"
              startIcon={
                <FuseSvgIcon className="text-48" size={20} color="inherit">
                  material-outline:location_on
                </FuseSvgIcon>
              }
            >
              {t('TAKE_MY_LOCATION')}
            </Button>
          </div>
        </div>
      );

    case 'date':
      return (
        <div className={className}>
          <Typography className="my-5 font-semibold" color="secondary" variant="body2">
            {field.required && <b className="text-red-600 mx-2">*</b>}
            {field.title}
          </Typography>
          <Button
            className="w-full rounded-8 pointer-events-none"
            color="secondary"
            variant="contained"
            size="small"
            startIcon={
              <FuseSvgIcon className="text-48" size={20} color="inherit">
                material-solid:calendar_today
              </FuseSvgIcon>
            }
          >
            {field.value ? dateToText(new Date(field.value)) : t('SELECT_DATE')}
          </Button>
        </div>
      );
    case 'signature':
      return (
        <div className={className}>
          <div className="flex justify-between">
            <Typography className="my-5 font-semibold" color="secondary" variant="body2">
              {field.required && <b className="text-red-600 mx-2">*</b>}
              {field.title}
            </Typography>
            <FuseSvgIcon className="text-48" size={20} color="secondary">
              heroicons-solid:pencil
            </FuseSvgIcon>
          </div>

          <img
            className="p-5 bg-white rounded-6 w-full"
            src="assets/images/placeholder/signature.png"
            alt="signature"
          />
        </div>
      );
    case 'barcode':
      return (
        <div className={className}>
          <Typography className="my-5 font-semibold" color="secondary" variant="body2">
            {field.required && <b className="text-red-600 mx-2">*</b>}
            {field.title}
          </Typography>
          <Button
            className=" w-full rounded-8 pointer-events-none"
            color="secondary"
            variant="contained"
            size="small"
            startIcon={
              <FuseSvgIcon className="text-48" size={20} color="inherit">
                material-solid:qr_code_scanner
              </FuseSvgIcon>
            }
          >
            {t('SCAN')}
          </Button>
        </div>
      );
    case 'print-ticket':
      return (
        <div className={className}>
          <Typography className="my-5 font-semibold" color="secondary" variant="body2">
            {field.required && <b className="text-red-600 mx-2">*</b>}
            {field.title}
          </Typography>

          <Typography
            className="bg-white px-2 py-4 my-3 rounded-6  whitespace-pre-wrap shadow-2"
            color="GrayText"
            variant="body2"
          >
            {field.value || ' '}
          </Typography>

          <Button
            className=" w-full rounded-8 pointer-events-none"
            color="secondary"
            variant="contained"
            size="small"
            startIcon={
              <FuseSvgIcon className="text-48" size={20} color="inherit">
                material-solid:local_printshop
              </FuseSvgIcon>
            }
          >
            {t('PRINT')}
          </Button>
        </div>
      );
    case 'photo':
      return (
        <div className={className}>
          <div className="flex justify-between">
            <Typography className="my-5 font-semibold" color="secondary" variant="body2">
              {field.required && <b className="text-red-600 mx-2">*</b>}
              {field.title}
            </Typography>
            <FuseSvgIcon className="text-48" size={20} color="secondary">
              material-solid:fullscreen
            </FuseSvgIcon>
          </div>
          <div className="w-full flex justify-center m-5">
            <img className=" w-1/2" src="assets/images/placeholder/photo.png" alt="signature" />
          </div>
          <Button
            className=" w-full rounded-8 pointer-events-none"
            color="secondary"
            variant="contained"
            size="small"
            startIcon={
              <FuseSvgIcon className="text-48" size={20} color="inherit">
                material-outline:camera_alt
              </FuseSvgIcon>
            }
          >
            {t('OPEN_CAMERA')}
          </Button>
        </div>
      );
    case 'section':
      return (
        <div className={className}>
          <Divider
            sx={{
              '&::before, &::after': {
                borderColor: 'secondary.light',
              },
            }}
          >
            <Typography
              color="secondary"
              variant="caption"
              className="font-600 px-5 text-15 "
              sx={{ textWrap: 'wrap' }}
            >
              {field.title}
            </Typography>
          </Divider>
          <Typography
            color="secondary"
            variant="caption"
            className="font-600 px-5 text-15"
            sx={{ textWrap: 'wrap' }}
          >
            {field.description}
          </Typography>
        </div>
      );
    default:
      return null;
  }
};

const FieldPreview = ({ onAccept, onCancel, setStep }) => {
  const { t } = useTranslation('assignmentsApp');
  const {
    formState: { isValid },
    getValues,
  } = useFormContext();

  const backStep = () => setStep(1);

  return (
    <div className="flex flex-col">
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full ">
          <IconButton className="mx-2" onClick={backStep} aria-label="delete" color="inherit">
            <FuseSvgIcon className="text-48" size={20}>
              material-solid:arrow_back
            </FuseSvgIcon>
          </IconButton>

          <Typography variant="subtitle1" color="inherit">
            {t('PREVIEW_FIELD')}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className="w-full flex justify-center bg-gray-200 py-10">
        <div className="w-1/2">
          <FieldSelector field={getValues()} />
        </div>
      </div>
      <div className="flex justify-between px-5 py-8">
        <Button onClick={onCancel} size="small" variant="contained">
          {t('CANCEL')}
        </Button>
        <div className="flex gap-10">
          <Button
            onClick={onAccept}
            size="small"
            color="secondary"
            variant="contained"
            disabled={!isValid}
          >
            {t('ACCEPT')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FieldPreview;
