const locale = {
  ALERT_DEMO: 'Demo',
  alerts: 'Alertas',
  assignmentList: 'Lista de Tareas',
  assignments: 'Tareas',
  clients: 'Clientes',
  COMPANY_LOGO_CAN_HERE: 'Aquí puede ir el logo de tu empresa',
  csv_load: 'Carga de CSV',
  forms: 'Formularios',
  loading: 'Cargando',
  location: 'Ubicación',
  management: 'Gestión',
  map: 'Mapa',
  myProfile: 'Mi perfil',
  organization: 'Organización',
  organizations: 'Organizaciones',
  projects: 'Proyectos',
  ROLE_ORG_ADMIN: 'Administrador',
  ROLE_ORG_READ: 'Lector',
  ROLE_ORG_SUPER_ADMIN: 'Director',
  ROLE_PLATFORM_ADMIN: 'Platform Admin',
  ROLE_PLATFORM_SUPPORT: 'Soporte',
  ROLE_WORKSPACE: '',
  scheduled_reports: 'Reportes programados',
  SELECT_COUNTRY: 'Selecciona un país',
  SELECT_LANGUAGE: 'Selecciona un lenguaje',
  SELECT_TIME_ZONE: 'Selecciona una zona horaria',
  DOWNLOAD_CSV_FILE: 'Descargar archivo CSV',
  DOWNLOAD_ERROR: 'Error de Descarga',
  DOWNLOAD_XLSX_FILE: 'Descargar archivo Excel',
  settings: 'Configuración',
  SIGN_OFF: 'Cerrar sesión',
  SUPPORT_MESSAGE: 'Hola! necesito soporte',
  SUPPORT_MESSAGE_WS: 'Hola!+necesito+soporte+para+el+escritorio+"{{name}}"',
  tags: 'Etiquetas',
  users: 'Usuarios',
  workspaces: 'Escritorios',
};

export default locale;
