import 'leaflet/dist/leaflet.css';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import MapComponent from './maps/MapIndex';
import LeafletMap from './maps/LeafletMap';
import GoogleMaps from './maps/GoogleMaps';

function MapFieldPosition(props) {
  const { mapType } = useSelector(({ fuse }) => fuse.map);

  const {
    iconType,
    locationMarker,
    positionMap,
    isEditing,
    setPositionMap = () => {},
    setLocationMarker = () => {},
    extraMakers = [],
    scrollable = false,
    showPlacesMakers = false,
    labelMarker = '',
  } = props;

  const GetMap = () => {
    if (mapType === 'GoogleMaps') {
      return GoogleMaps;
    }
    return LeafletMap;
  };

  const GetIcon = () => {
    switch (iconType) {
      case 'user':
        return (
          <img
            className="absolute z-999 bottom-1/2 left-0 right-0 mr-auto ml-auto"
            src="assets/images/markers/PersonIcon.png"
            alt=""
            height={27}
            width={18}
          />
        );
      case 'client':
        return (
          <img
            className="absolute z-999 bottom-1/2 left-0 right-0 mr-auto ml-auto"
            src="assets/images/markers/clientIcon.png"
            alt=""
            height={36}
            width={27}
          />
        );
      case 'branch_office':
        return (
          <img
            className="absolute z-999 bottom-1/2 left-0 right-0 mr-auto ml-auto"
            src="assets/images/markers/branch_officeIcon.png"
            alt=""
            height={27}
            width={27}
          />
        );
      case 'warehouse':
        return (
          <img
            className="absolute z-999 bottom-1/2 left-0 right-0 mr-auto ml-auto"
            src="assets/images/markers/warehouseIcon.png"
            alt=""
            height={27}
            width={27}
          />
        );
      case 'headquarters':
        return (
          <img
            className="absolute z-999 bottom-1/2 left-0 right-0 mr-auto ml-auto"
            src="assets/images/markers/headquartersIcon.png"
            alt=""
            height={27}
            width={27}
          />
        );
      case 'auxiliary_office':
        return (
          <img
            className="absolute z-999 bottom-1/2 left-0 right-0 mr-auto ml-auto"
            src="assets/images/markers/auxiliary_officeIcon.png"
            alt=""
            height={27}
            width={27}
          />
        );
      case 'factory':
        return (
          <img
            className="absolute z-999 bottom-1/2 left-0 right-0 mr-auto ml-auto"
            src="assets/images/markers/factoryIcon.png"
            alt=""
            height={27}
            width={27}
          />
        );
      case 'store':
        return (
          <img
            className="absolute z-999 bottom-1/2 left-0 right-0 mr-auto ml-auto"
            src="assets/images/markers/storeIcon.png"
            alt=""
            height={27}
            width={27}
          />
        );
      case 'parking_lot':
        return (
          <img
            className="absolute z-999 bottom-1/2 left-0 right-0 mr-auto ml-auto"
            src="assets/images/markers/parking_lotIcon.png"
            alt=""
            height={27}
            width={27}
          />
        );
      case 'construction_sites':
        return (
          <img
            className="absolute z-999 bottom-1/2 left-0 right-0 mr-auto ml-auto"
            src="assets/images/markers/construction_sitesIcon.png"
            alt=""
            height={27}
            width={27}
          />
        );

      case 'other':
        return (
          <img
            className="absolute z-999 bottom-1/2 left-0 right-0 mr-auto ml-auto"
            src="assets/images/markers/otherIcon.png"
            alt=""
            height={27}
            width={27}
          />
        );
      default:
        return (
          <img
            className="absolute z-999 bottom-1/2 left-0 right-0 mr-auto ml-auto"
            src="assets/images/markers/locationPin.png"
            alt=""
            height={27}
            width={18}
          />
        );
    }
  };

  return (
    <div className="relative w-full h-full">
      {isEditing && (
        <>
          <div className="absolute h-1/2 w-full z-999 border-b-2 !border-red-400 pointer-events-none " />
          <div className="absolute h-full w-1/2 z-999 border-r-2 !border-red-400 pointer-events-none" />
          <GetIcon />
        </>
      )}
      <MapComponent
        map={GetMap()}
        positionMap={positionMap}
        setPositionMap={setPositionMap}
        locationMarker={locationMarker}
        labelMarker={labelMarker}
        setLocationMarker={setLocationMarker}
        iconType={iconType}
        isEditing={isEditing}
        extraMakers={extraMakers}
        scrollable={Boolean(scrollable)}
        showPlacesMakers={Boolean(showPlacesMakers)}
      />
    </div>
  );
}

export default memo(MapFieldPosition);
