import { io } from 'socket.io-client';
import JwtService from '../auth/services/jwtService';

class Socket {
  static instance = new Socket();

  socket;

  constructor() {
    if (Socket.instance) {
      return Socket.instance;
    }
    Socket.instance = this;
  }

  connectWorkspace(shortid, userId) {
    return new Promise((resolve, reject) => {
      this.disconnectWorkspace();

      const token = JwtService.getCredentials().access;

      this.socket = io(`${process.env.REACT_APP_WEBSOCKED_HOST}/ws-${shortid}`, {
        auth: {
          userId,
          token,
        },
      });
      this.socket.connect();

      this.socket.on('connect', resolve);
      this.socket.on('connect_error', reject);
      this.socket.on('disconnect', () => console.log('Disconnect Socket'));
    });
  }

  disconnectWorkspace() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket.off('disconnect', () => console.log('Disconnect Socket'));

      this.socket.off('connect', () => console.log('Connect Socket'));

      this.socket = null;
    }
  }

  joinRoom(roomType) {
    this.socket.emit('join-room', roomType);
  }

  leaveRoom(roomType) {
    this.socket.emit('leave-room', roomType);
  }

  addListener(listener, callback = () => {}) {
    this.socket.on(listener, callback);
  }

  removeListener(listener, callback = () => {}) {
    this.socket.off(listener, callback);
  }

  isConnected() {
    return this.socket?.connected;
  }
}
export default Socket.instance;
