import FuseUtils from '@fuse/utils/FuseUtils';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
// eslint-disable-next-line import/no-cycle
import { updateUser } from 'app/store/userSlice';
import axios from 'axios';
import { t } from 'i18next';
import ApiRoutes from 'src/app/services/ApiRoutes';

export const getUsers = createAsyncThunk(
  'managementApp/users/getUsers',
  async (params, { dispatch, getState }) => {
    const response = await axios.get(ApiRoutes.usersEndPoint(params.wsId));

    const data = await response.data;

    return data;
  }
);

export const getUserRoles = createAsyncThunk(
  'managementApp/users/getUserRoles',
  async (params, { dispatch, getState }) => {
    const user = getState().managementApp.users.entities[params.userId];
    const response = await axios.get(ApiRoutes.getUserRole(params.wsId, params.userId));

    const data = await { ...response.data, ...user };
    return data;
  }
);

export const setUserRoles = createAsyncThunk(
  'managementApp/users/setUserRoles',
  async (params, { dispatch, getState }) => {
    const response = await axios.put(ApiRoutes.setUserRole(params.wsId), {
      user: params.user.id,
      roles: params.user.roles.map((rol) => rol.id),
    });

    const data = await { roles: response.data, ...params.user };

    dispatch(
      showMessage({
        message: t('managementApp:USER_UPDATED', { email: data.email }),
        variant: 'success',
      })
    );

    return data;
  }
);

export const setUser = createAsyncThunk(
  'managementApp/users/setUser',
  async (params, { dispatch, getState }) => {
    const { id } = getState().user;

    const response = await axios.put(
      ApiRoutes.updateUser(params.wsId, params.user.id),
      params.user
    );
    const data = await { ...params.user, ...response.data };

    if (data.id === id) dispatch(updateUser(data));

    dispatch(
      showMessage({
        message: t('managementApp:USER_UPDATED', { email: data.email }),
        variant: 'success',
      })
    );
    return data;
  }
);

export const addUser = createAsyncThunk(
  'managementApp/users/addUsers',
  async (params, { dispatch, getState }) => {
    delete params.user.code;
    delete params.user.method;
    const response = await axios.post(ApiRoutes.AddUser(params.wsId), params.user);
    const user = await response.data.user;

    dispatch(
      showMessage({
        message: t('managementApp:USER_ADDED', { email: user.email }),
        variant: 'success',
      })
    );
    return user;
  }
);

export const inviteUser = async (params) => {
  const response = await axios.post(ApiRoutes.inviteUser(params.wsId), params.user);
  const user = await response.data;

  return user;
};

export const setSuperUser = async (params) => {
  await axios.put(ApiRoutes.setSuperUser(params.wsId), {
    user: params.user.id,
    is_superuser: params.user.is_superuser,
  });
};

export const removeUser = createAsyncThunk(
  'managementApp/users/removeUsers',
  async (params, { dispatch, getState }) => {
    await axios.put(ApiRoutes.removeUser(params.wsId), { user: params.user.id });

    // dispatch(setSelectedUser(null));

    dispatch(
      showMessage({
        message: t('managementApp:USER_REMOVED', { email: params.user.email }),
        variant: 'success',
      })
    );

    const user = await params.user.id;

    return user;
  }
);

const usersAdapter = createEntityAdapter({});

export const selectSearchText = ({ managementApp }) => managementApp.users.searchText;

export const { selectAll: selectUsers, selectById: selectUserById } = usersAdapter.getSelectors(
  (state) => state.managementApp.users
);

export const selectFilteredUsers = createSelector(
  [selectUsers, selectSearchText],
  (users, searchText) => {
    if (searchText.length === 0) {
      return users;
    }
    const metaUsers = users.map((user) => ({
      id: user.id,
      email: user.email,
      name: user.name,
      last_name: user.last_name,
      phone: user.phone,
    }));
    return FuseUtils.filterArrayByString(metaUsers, searchText);
  }
);

export const selectGroupedFilteredUsers = createSelector([selectFilteredUsers], (users) => {
  return users
    .sort((a, b) => a.email.localeCompare(b.email, 'es', { sensitivity: 'base' }))
    .reduce((r, e) => {
      // get first letter of email of current element
      const group = e.email[0].toLowerCase();
      // if there is no property in accumulator with this letter create it
      if (!r[group]) r[group] = { group, children: [e] };
      // if there is push current element to children array for that letter
      else r[group].children.push(e);
      // return accumulator
      return r;
    }, {});
});

const initialState = usersAdapter.getInitialState({
  isLoading: true,
  searchText: '',
  newUserDialog: {
    props: {
      open: false,
    },
  },
  removeUserDialog: {
    props: {
      open: false,
    },
    userToDelete: null,
  },
  changePasswordDialog: {
    props: {
      open: false,
    },
    userToChangePassword: null,
  },
});

const itemsSlice = createSlice({
  name: 'managementApp/users',
  initialState,
  reducers: {
    openUserDialog: (state, action) => {
      state.newUserDialog = {
        props: {
          open: true,
        },
      };
    },
    closeUserDialog: (state, action) => {
      state.newUserDialog = {
        props: {
          open: false,
        },
      };
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setUsersSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
    },
    openRequestremoveUser: (state, action) => {
      state.removeUserDialog = {
        props: {
          open: true,
        },
        userToDelete: action.payload,
      };
    },
    closeRequestRemoveUser: (state, action) => {
      state.removeUserDialog = {
        props: {
          open: false,
        },
        userToDelete: null,
      };
    },
    openChangePassword: (state, action) => {
      state.changePasswordDialog = {
        props: {
          open: true,
        },
        userToChangePassword: action.payload,
      };
    },
    closeChangePassword: (state, action) => {
      state.changePasswordDialog = {
        props: {
          open: false,
        },
        userToChangePassword: null,
      };
    },
    resetUsersState: () => initialState,
  },
  extraReducers: {
    [getUsers.fulfilled]: usersAdapter.setAll,
    [getUserRoles.fulfilled]: usersAdapter.setOne,
    [setUserRoles.fulfilled]: usersAdapter.setOne,
    [setUser.fulfilled]: usersAdapter.setOne,
    [addUser.fulfilled]: usersAdapter.setOne,
    [removeUser.fulfilled]: usersAdapter.removeOne,
  },
});

export const selectSelectedUser = ({ managementApp }) => managementApp.users.selectedUserId;

export const {
  setUsersSearchText,
  setIsLoading,
  closeUserDialog,
  openUserDialog,
  openRequestremoveUser,
  closeRequestRemoveUser,
  openChangePassword,
  closeChangePassword,
  resetUsersState,
} = itemsSlice.actions;

export default itemsSlice.reducer;
