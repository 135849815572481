/* eslint-disable camelcase */
export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
export function getLabel({ email, name, last_name }) {
  if (name && last_name) {
    return name[0] + last_name[0];
  }
  if (name) {
    return name[0] + name.substring(1).replace(/[aeiou]/g, '')[0];
  }
  if (last_name) {
    return last_name[0] + last_name.substring(1).replace(/[aeiou]/g, '')[0];
  }
  if (email) {
    return email[0] + (email.substring(1).replace(/[aeiou]|@.*/g, '')[0] || '');
  }
  return '';
}
export function stringAvatar(user, style) {
  return {
    sx: {
      backgroundColor: stringToColor(user.email),
      ...style,
    },
    children: getLabel(user),
  };
}

export const colorValidator = /^#([0-9a-f]{3}){1,2}$/i;
