/* eslint-disable camelcase */
import AppContext from 'app/AppContext';
import { Component } from 'react';
import { matchRoutes } from 'react-router-dom';
import withRouter from '@fuse/core/withRouter';
import history from '@history';
import { authRoles } from 'src/app/auth';
import { checkAccess } from 'src/app/auth/authRoles';

let loginRedirectUrl = null;

class FuseAuthorization extends Component {
  constructor(props, context) {
    super(props);
    const { routes } = context;
    const { userRole } = props;

    this.state = {
      accessGranted: true,
      routes,
    };

    if (authRoles.user.includes(userRole)) {
      this.defaultLoginRedirectUrl = 'workspaces';
    } else if (authRoles.platform.includes(userRole)) {
      this.defaultLoginRedirectUrl = 'organizations';
    } else {
      this.defaultLoginRedirectUrl = '/';
    }
  }

  componentDidMount() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.accessGranted !== this.state.accessGranted;
  }

  componentDidUpdate() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const {
      location,
      userPermission: { is_superuser, permissions },
      userRole,
      workspace,
    } = props;
    const { pathname } = location;
    const matchedRoutes = matchRoutes(state.routes, pathname);
    const matched = matchedRoutes ? matchedRoutes[0] : false;

    if (
      matched.route.moduleActiveRequire &&
      workspace.modules?.[matched.route.moduleActiveRequire].active === false
    ) {
      return { accessGranted: false };
    }

    // verify if is super user when is required
    if (matched.route.superUserRequired) {
      return {
        accessGranted: checkAccess([], { is_superuser, permissions }, userRole),
      };
    }

    // verify if  user have the required role
    if (matched.route.roleRequired) {
      return { accessGranted: matched.route.roleRequired.includes(userRole) };
    }

    return {
      accessGranted: checkAccess(
        matched.route.permissionsRequired,
        { is_superuser, permissions },
        userRole
      ),
    };
  }

  redirectRoute() {
    const { location, userRole, workspace } = this.props;
    const { pathname } = location;
    const redirectUrl = loginRedirectUrl || this.defaultLoginRedirectUrl;

    /*
        User is guest
        Redirect to Login Page
        */
    if (userRole === 'guest' || userRole.length === 0) {
      setTimeout(() => history.push('/login'), 0);
      loginRedirectUrl = pathname;
    } else {
      if (workspace.id) {
        setTimeout(() => history.push(`/ws/${workspace.id}/pages/error`), 0);
      } else {
        setTimeout(() => history.push('/ws-404'), 0);
      }

      loginRedirectUrl = this.defaultLoginRedirectUrl;
    }
  }

  render() {
    // console.info('Fuse Authorization rendered', this.state.accessGranted);
    return this.state.accessGranted ? <>{this.props.children}</> : null;
  }
}

FuseAuthorization.contextType = AppContext;

export default withRouter(FuseAuthorization);
