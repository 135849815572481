import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Input from '@mui/material/Input';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formSelector, getForms, setIsLoading, setPage } from '../store/FormsSlice';

function FormsHeader(props) {
  const dispatch = useDispatch();
  const { wsId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('assignmentsApp');
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState(
    searchParams.get('search') ? searchParams.get('search') : ''
  );

  const { numOfPages, page } = useSelector(formSelector);

  const onChangePage = async (e, n) => {
    const navigateParams = { pathname: `/ws/${wsId}/forms`, search: { page: n } };

    if (searchParams.get('search')) navigateParams.search.search = searchParams.get('search');
    navigateParams.search = `?${createSearchParams(navigateParams.search)}`;

    navigate(navigateParams);
  };

  const makeSearch = async () => {
    dispatch(setIsLoading(true));
    await dispatch(getForms({ wsId, paramsApi: { page: 1, search } }));

    const navigateParams = { pathname: `/ws/${wsId}/forms` };
    if (search) navigateParams.search = `?${createSearchParams({ search })}`;

    navigate(navigateParams);

    dispatch(setPage(1));
    dispatch(setIsLoading(false));
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      makeSearch();
    }
  };

  useEffect(() => {
    const urlPage = searchParams.get('page') ? parseInt(searchParams.get('page'), 10) : 1;
    dispatch(setPage(urlPage));
    dispatch(getForms({ wsId, paramsApi: { page: urlPage, search: searchParams.get('search') } }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get('page')]);

  return (
    <div className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 w-full items-center justify-between pt-32 pb-12 px-24 md:px-32">
      <div className="flex flex-col ">
        <Typography
          component={motion.span}
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="text-24 md:text-32 font-extrabold tracking-tight"
        >
          {t('FORMS')}
        </Typography>
        <Typography
          component={motion.span}
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          variant="caption"
        >
          {t('FORM_DESC')}
        </Typography>
      </div>
      <div className="flex flex-col items-center sm:items-end justify-center">
        <div className="flex flex-col w-full sm:w-auto sm:flex-row space-y-16 sm:space-y-0 flex-1 items-center justify-end space-x-8">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full sm:max-w-256 space-x-8 px-16 rounded-full border-1 shadow-0"
          >
            <FuseSvgIcon onClick={makeSearch} color="disabled">
              heroicons-solid:search
            </FuseSvgIcon>
            <Input
              id="search"
              autoComplete="off"
              placeholder={t('SEARCH_FORM')}
              className="flex flex-1"
              disableUnderline
              fullWidth
              value={search}
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={(ev) => setSearch(ev.target.value)}
              onKeyDown={keyPress}
            />
          </Paper>
        </div>

        {numOfPages > 1 && (
          <div className="sm:items-self-center pt-8">
            <Pagination count={numOfPages} color="secondary" onChange={onChangePage} page={page} />
          </div>
        )}
      </div>
    </div>
  );
}

export default FormsHeader;
