/* eslint-disable no-case-declarations */
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import { MapContainer } from 'react-leaflet/MapContainer';
import { TileLayer } from 'react-leaflet/TileLayer';
import { useMapEvents, Marker, useMap, Tooltip } from 'react-leaflet';
import { forwardRef, memo, useEffect, useImperativeHandle } from 'react';
import { LatLng, LatLngBounds, icon, divIcon } from 'leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { getIconClientPath, getIconPlacePath } from '@assets/images/markers/IconVectors';

const getIconPerson = (isWhite) => {
  let className = '';
  if (isWhite) {
    className = 'filter-grey';
  }

  return icon({
    iconUrl: 'assets/images/markers/PersonIcon.png',
    iconRetinaUrl: 'assets/images/markers/PersonIcon.png',
    iconSize: [19, 40], // size of the icon
    iconAnchor: [10, 40], // point of the icon which will correspond to marker's location
    popupAnchor: [-1, -22],
    className,
  });
};

const getIconClient = () =>
  divIcon({
    iconSize: [50, 50], // size of the icon
    iconAnchor: [15, 26], // point of the icon which will correspond to marker's location
    popupAnchor: [-1, -22], // point from which the popup should open relative to the iconAnchor
    className: 'border-none',
    html: `
            <svg
              viewBox="0 0 980 942" 
              width="50" height="50"
              version="1.1"
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="${getIconClientPath()}" fill="#2C57EE"></path>
            </svg>`,
  });

const getIconPlace = (placeType) =>
  divIcon({
    iconSize: [50, 50], // size of the icon
    iconAnchor: [15, 26], // point of the icon which will correspond to marker's location
    popupAnchor: [-1, -22], // point from which the popup should open relative to the iconAnchor
    className: 'border-none',
    html: `
            <svg
              viewBox="0 0 980 942" 
              width="50" height="50"
              version="1.1"
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="${getIconPlacePath(placeType)}" fill="#2C57EE"></path>
            </svg>`,
  });

const iconPoint = icon({
  iconUrl: 'assets/images/markers/locationPin.png',
  iconRetinaUrl: 'assets/images/markers/locationPin.png',
  iconSize: [20, 29], // size of the icon
  iconAnchor: [10, 30], // point of the icon which will correspond to marker's location  popupAnchor: [-1, -22], // point from which the popup should open relative to the iconAnchor
});
const worldBounds = new LatLngBounds(new LatLng(-88, -180), new LatLng(88, 180));

const Search = (props) => {
  const map = useMap(); // access to leaflet map
  const { provider } = props;

  useEffect(() => {
    const searchControl = new GeoSearchControl({
      provider,
      style: 'bar',
      showMarker: false,
      searchLabel: '',
      autoClose: true,
    });

    map.addControl(searchControl); // this is how you add a control in vanilla leaflet
    return () => map.removeControl(searchControl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return null; // don't want anything to show up from this comp
};

const LeafletMap = forwardRef((props, ref) => {
  const {
    positionMap,
    setPositionMap,
    locationMarker,
    labelMarker,
    iconType,
    isEditing,
    extraMakers,
    scrollable,
  } = props;

  const getItem = (type) => {
    switch (type) {
      case 'user':
        return getIconPerson(isEditing);
      case 'client':
        return getIconClient();
      case 'branch_office':
        return getIconPlace('branch_office');
      case 'warehouse':
        return getIconPlace('warehouse');
      case 'headquarters':
        return getIconPlace('headquarters');
      case 'auxiliary_office':
        return getIconPlace('auxiliary_office');
      case 'factory':
        return getIconPlace('factory');
      case 'store':
        return getIconPlace('store');
      case 'parking_lot':
        return getIconPlace('parking_lot');
      case 'construction_sites':
        return getIconPlace('construction_sites');
      case 'other':
        return getIconPlace('other');
      default:
        return iconPoint;
    }
  };

  const ListenerMap = memo(() => {
    const mapEvents = useMapEvents({
      zoomend: () => {
        const { coords } = positionMap;
        setPositionMap({ coords, zoom: mapEvents.getZoom() });
      },
      dragend: () => {
        const coords = mapEvents.getCenter();
        const { zoom } = positionMap;
        setPositionMap({ coords: [coords.lat, coords.lng], zoom });
      },
      drag: () => {
        mapEvents.panInsideBounds(worldBounds, { animate: true });
        mapEvents.invalidateSize();
      },
    });

    return null;
  });

  function ControlerMap({ state }) {
    const map = useMap();
    if (state.setPosition) {
      map.flyTo(state.coords, state.zoom, {
        animate: true,
        duration: 1,
      });
    }
    return null;
  }
  useImperativeHandle(ref, () => ({}));

  return (
    <MapContainer
      center={positionMap.coords}
      zoom={positionMap.zoom}
      minZoom={3}
      className="w-full h-full"
      scrollWheelZoom={scrollable}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attributionControl={false}
      />
      {isEditing && <Search provider={new OpenStreetMapProvider()} />}
      <ListenerMap />
      <ControlerMap state={positionMap} />
      {locationMarker && (
        <Marker position={[...locationMarker]} icon={getItem(iconType)}>
          <Tooltip direction="center" permanent className="tooltip-trasparent">
            <p className="markerLabel mb-80">{labelMarker}</p>
          </Tooltip>
        </Marker>
      )}
      {extraMakers.map((maker) => (
        <Marker position={[...maker.coords]} icon={getItem(maker.type)} key={maker.id}>
          <Tooltip direction="center" permanent className="tooltip-trasparent">
            <p className="markerLabel mb-80">{maker.label}</p>
          </Tooltip>
        </Marker>
      ))}
    </MapContainer>
  );
});

export default LeafletMap;
