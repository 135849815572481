import { createRef } from 'react';

function MapComponent({
  map: Map,
  locationMarker,
  setLocationMarker,
  positionMap,
  setPositionMap,
  iconType,
  isEditing,
  extraMakers,
  scrollable,
  showPlacesMakers,
  labelMarker,
}) {
  const refContainer = createRef();
  const divRef = createRef(null);

  // useEffect(() => {
  //   DomEvent.disableClickPropagation(divRef.current);
  // });

  return (
    <div ref={divRef} className="w-full h-full">
      <Map
        ref={refContainer}
        positionMap={positionMap}
        setPositionMap={setPositionMap}
        locationMarker={locationMarker}
        setLocationMarker={setLocationMarker}
        iconType={iconType}
        isEditing={isEditing}
        extraMakers={extraMakers}
        scrollable={scrollable}
        showPlacesMakers={showPlacesMakers}
        labelMarker={labelMarker}
      />
    </div>
  );
}

export default MapComponent;
