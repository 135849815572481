import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectClientById, setlDeletePlaceModalProps } from '../../store/clientsSlice';

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function DeletePlace() {
  const dispatch = useDispatch();
  const { t } = useTranslation('managementApp');
  const { clientId } = useParams();

  const { open, placeIndex } = useSelector(
    ({ managementApp }) => managementApp.clients.deletePlacesModalProps
  );
  const client = useSelector((state) => selectClientById(state, clientId));
  const { setValue } = useFormContext();

  const closeDeleteModal = async () =>
    dispatch(setlDeletePlaceModalProps({ open: false, placeIndex: null }));

  const deletePlace = async () => {
    setValue(`places_data.${placeIndex}.delete`, true);

    closeDeleteModal();
  };
  return (
    <Modal
      open={open}
      onClose={closeDeleteModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleModal}>
        <Typography variant="subtitle1" className="mb-5 text-justify">
          {t('ARE_SURE_DELETE_PLACE', { name: client?.places_data?.[placeIndex]?.name })}
        </Typography>

        <div className="flex justify-end">
          <Button onClick={closeDeleteModal} className="flex-auto mx-2">
            {t('CANCEL')}
          </Button>

          <Button
            className="flex-auto mx-2"
            color="secondary"
            variant="contained"
            onClick={deletePlace}
          >
            {t('ACCEPT')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default DeletePlace;
