/* eslint-disable camelcase */
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage, selectCurrentLanguage, selectLanguages } from 'app/store/i18nSlice';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';
import { selectWorkspace } from 'app/store/workspaceSlice';
import { getFlagPosition } from '@assets/images/flags/flagsData';

function LanguageSwitcher(props) {
  const { country_data } = useSelector(selectWorkspace);
  const currentLanguage = useSelector(selectCurrentLanguage);
  const languages = useSelector(selectLanguages);
  const [menu, setMenu] = useState(null);
  const dispatch = useDispatch();

  const langMenuClick = (event) => {
    setMenu(event.currentTarget);
  };

  const langMenuClose = () => {
    setMenu(null);
  };

  function handleLanguageChange(lng) {
    dispatch(changeLanguage(lng.id));

    axios.put(ApiRoutes.setLanguaje(), { language: lng.id });

    langMenuClose();
  }

  return (
    <>
      {country_data && (
        <Box
          component="span"
          className="w-24 h-16 overflow-hidden"
          sx={{
            background: "url('/assets/images/flags/flags.png') no-repeat 0 0",
            backgroundSize: '24px 3876px',
            backgroundPosition: getFlagPosition(country_data.iso_alpha2),
          }}
        />
      )}
      <Button className="h-40 w-64" onClick={langMenuClick}>
        <Typography className="mx-4 font-semibold uppercase" color="text.secondary">
          {currentLanguage.id}
        </Typography>
      </Button>

      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={langMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {languages.map((lng) => (
          <MenuItem key={lng.id} onClick={() => handleLanguageChange(lng)}>
            {/* <ListItemIcon className="min-w-40">
              <img
                className="min-w-20"
                src={`assets/images/flags/${lng.flag}.svg`}
                alt={lng.title}
              />
            </ListItemIcon> */}
            <ListItemText primary={lng.title} />
          </MenuItem>
        ))}

        {/* <MenuItem
          component={Link}
          to="/documentation/configuration/multi-language"
          onClick={langMenuClose}
          role="button"
        >
          <ListItemText primary="Learn More" />
        </MenuItem> */}
      </Popover>
    </>
  );
}

export default LanguageSwitcher;
