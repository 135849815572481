import _ from '@lodash';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { t } from 'i18next';

import GoogleIcon from '@mui/icons-material/Google';
import SpaIcon from '@mui/icons-material/Spa';
import { changeMap } from 'app/store/fuse/mapSlice';
import { selectWorkspace } from 'app/store/workspaceSlice';
import { useSearchParams } from 'react-router-dom';

const MapSettings = ({ registerInUrl, className = '' }) => {
  const dispatch = useDispatch();
  const [mapSetting, setSettingMap] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const { mapType } = useSelector(({ fuse }) => fuse.map);
  const tracking = useSelector(selectWorkspace)?.modules?.tracking;

  const mapSettingClick = (event, path) => {
    setSettingMap((e) => {
      _.set(e, `${path}.main`, event.currentTarget);
      return { ...e };
    });
  };
  const mapSettingClose = () => {
    setSettingMap({});
  };
  const ChangeMaps = () => {
    dispatch(changeMap('GoogleMaps'));
    if (registerInUrl) {
      searchParams.set('mapType', 'GoogleMaps');
      setSearchParams(searchParams);
    }
    setSettingMap({});
  };
  const ChangeLeaflet = () => {
    dispatch(changeMap('OpenStreetMap'));
    if (registerInUrl) {
      searchParams.set('mapType', 'OpenStreetMap');
      setSearchParams(searchParams);
    }
    setSettingMap({});
  };

  if (!(tracking?.map_types?.length > 0)) return null;

  return (
    <div className={className}>
      <Tooltip title={t('locationApp:MAP_SETTING')}>
        <FuseSvgIcon
          className="text-48"
          size={24}
          color="secondary"
          onClick={(e) => mapSettingClick(e, 'settings')}
        >
          material-twotone:settings
        </FuseSvgIcon>
      </Tooltip>
      <Popover
        open={Boolean(mapSetting?.settings?.main)}
        anchorEl={mapSetting?.settings?.main}
        onClose={mapSettingClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        elevation={0}
        PaperProps={{
          style: { borderRadius: 0 },
        }}
      >
        <MenuItem
          onClick={(e) => mapSettingClick(e, 'settings.mapType')}
          role="button"
          style={{ border: 'solid', borderWidth: 0.5 }}
        >
          <ListItemIcon className="min-w-40">
            <FuseSvgIcon className="text-48" size={24} color="action">
              material-twotone:map
            </FuseSvgIcon>
          </ListItemIcon>
          <ListItemText primary={t('locationApp:MAP_TYPE')} />
        </MenuItem>
      </Popover>
      <Popover
        open={Boolean(mapSetting?.settings?.mapType?.main)}
        anchorEl={mapSetting?.settings?.mapType?.main}
        onClose={mapSettingClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        elevation={0}
        PaperProps={{
          style: { borderRadius: 0 },
        }}
      >
        {tracking?.map_types.includes('GoogleMaps') && (
          <MenuItem
            role="button"
            style={{ border: 'solid', borderWidth: 0.5 }}
            onClick={ChangeMaps}
          >
            <ListItemIcon className="min-w-40">
              <GoogleIcon />
            </ListItemIcon>
            <ListItemText primary="Google Maps" />
            {mapType === 'GoogleMaps' && (
              <FuseSvgIcon className="text-48" size={16} color="action">
                heroicons-outline:check
              </FuseSvgIcon>
            )}
          </MenuItem>
        )}
        <MenuItem
          role="button"
          style={{ border: 'solid', borderWidth: 0.5 }}
          onClick={ChangeLeaflet}
        >
          <ListItemIcon className="min-w-40">
            <SpaIcon />
          </ListItemIcon>
          <ListItemText primary="Open Street Map" />
          {mapType === 'OpenStreetMap' && (
            <FuseSvgIcon className="text-48" size={16} color="action">
              heroicons-outline:check
            </FuseSvgIcon>
          )}
        </MenuItem>
      </Popover>
    </div>
  );
};

export default MapSettings;
