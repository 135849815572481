import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { selectUsers } from 'app/store/selectsData/usersSlice';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';

const TextFieldSearch = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 50,
      },
    },
  },
})(TextField);

const TextFieldMini = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 35,
      minHeight: 0,
      '& input': {
        fontSize: '1.25rem',
        padding: '0 12px',
      },
      '& fieldset': {
        borderRadius: 50,
        height: 35,
      },
    },
    '& .MuiFormLabel-root': {
      fontSize: '1.25rem',
      lineHeight: '1.02rem',
    },
  },
})(TextField);

const TextFieldRole = (props) => {
  const [userRole, setUserRole] = useState(props.value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => props.onChange(userRole, props.index), [userRole]);

  const onChange = ({ target: { value } }) => setUserRole(value);

  return <TextFieldMini {...props} onChange={onChange} value={userRole} />;
};

function UsersTab(props) {
  const methods = useFormContext();
  const users = useSelector(selectUsers);
  const { t } = useTranslation('managementApp');
  const { getValues, setValue, watch } = methods;
  const [searchText, setSearchtext] = useState('');

  const usersFiltered = useMemo(() => {
    if (!searchText) {
      return users;
    }
    return users.filter(
      (item) =>
        item.name.match(searchText) ||
        item.last_name.match(searchText) ||
        item.email.match(searchText)
    );
  }, [users, searchText]);

  const onCheck = ({ target: { checked } }, user) => {
    const usersIds = getValues('users');
    const usersData = getValues('users_data');
    if (checked) {
      usersIds.push(user.id);
      usersData.push({ user, role: '' });
    } else {
      const indexID = usersIds.indexOf(user.id);
      const indexData = usersData.map((userData) => userData.user.id).indexOf(user.id);

      if (indexID > -1) {
        usersIds.splice(indexID, 1);
      }

      if (indexData > -1) {
        usersData.splice(indexData, 1);
      }
    }
    setValue('users', usersIds);
    setValue('users_data', usersData);
  };

  const onCheckAll = ({ target: { checked } }) => {
    setValue('users', checked ? usersFiltered.map((user) => user.id) : []);
    setValue('users_data', checked ? usersFiltered.map((user) => ({ user, role: '' })) : []);
  };

  const onChangeRole = (text, userIndex) => setValue(`users_data.${userIndex}.role`, text);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '10%', paddingX: 1 }}>
        <div className="w-5/12">
          <Typography className=" font-bold flex-1" variant="subtitle1">
            {t('USER_IN_TEAM')}
          </Typography>
        </div>
        <div className="w-6/12">
          <TextFieldSearch
            onChange={({ target: { value } }) => setSearchtext(value)}
            value={searchText}
            size="small"
            placeholder={t('SEARCH_USERS')}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FuseSvgIcon className="text-48" size={24} color="action">
                    material-solid:search
                  </FuseSvgIcon>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-1/12 flex justify-center">
          <Checkbox
            edge="start"
            checked={watch('users').length === usersFiltered.length}
            disableRipple
            size="small"
            onChange={onCheckAll}
          />
        </div>
      </Box>
      <List
        className="w-full overflow-hidden overflow-y-scroll pb-64"
        sx={{
          bgcolor: 'background.paper',
          height: '90%',
        }}
      >
        {usersFiltered.map((user) => {
          const userIndex = watch('users_data').findIndex(
            (userItem) => user.id === userItem.user.id
          );
          return (
            <ListItem
              key={user.id}
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <FuseSvgIcon className="text-48" size={24} color="action">
                    material-solid:person
                  </FuseSvgIcon>
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton role={undefined} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={userIndex !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': user.id }}
                    onChange={(e) => onCheck(e, user)}
                  />
                </ListItemIcon>
                <div className="w-full">
                  <ListItemText
                    id={user.id}
                    primary={`${user.name} ${user.last_name}`}
                    secondary={user.email}
                  />
                  {userIndex !== -1 && (
                    <TextFieldRole
                      className="mt-8"
                      label={t('POSITION_TEAM')}
                      variant="outlined"
                      fullWidth
                      size="small"
                      onChange={onChangeRole}
                      value={getValues(`users_data.${userIndex}.role`)}
                      index={userIndex}
                    />
                  )}
                </div>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}

export default UsersTab;
