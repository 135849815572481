import * as yup from 'yup';
import Fab from '@mui/material/Fab';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import { FormProvider, useForm } from 'react-hook-form';
import { styled } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';

import InformationTab from './tabs/InformationTab';
import UsersTab from './tabs/UsersTab';
import {
  addRole,
  selectRoleById,
  setDeleteModalProps,
  setRole,
  setSidebarProps,
} from '../../store/rolesSlice';
import PermisionsTab from './tabs/PermissionsTab';

const Header = styled('div')(({ theme }) => ({
  width: '100%',
  borderBottomWidth: '1px',
  display: 'flex',
  justifyContent: 'space-between',
}));

const Content = styled('div')(({ theme }) => ({
  width: '100%',
  height: '80%',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
}));

function DetailSidebarRoles(props) {
  const defaultValues = {
    id: uuidv4(),
    name: '',
    description: '',
    permissions: [],
    users: [],
  };
  const dispatch = useDispatch();
  const { t } = useTranslation('managementApp');
  const navigate = useNavigate();
  const { roleId, wsId } = useParams();

  const openDeleteModal = () => dispatch(setDeleteModalProps({ open: true, roleId }));

  const { numOfTab } = useSelector(({ managementApp }) => managementApp.roles.sidebarProps);
  const role = useSelector((state) => selectRoleById(state, roleId));

  const onClose = () => {
    dispatch(setSidebarProps({ numOfTab: 0 }));
    navigate('..');
  };

  const onChangeTab = (e, numTab) => dispatch(setSidebarProps({ numOfTab: numTab }));

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    name: yup.string().required(t('NAME_CANNOT_EMPTY')),
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid } = methods.formState;

  const saveRole = () => dispatch(setRole({ wsId, role: methods.getValues() }));

  const createRole = async () => {
    const {
      payload: [s, { id }],
    } = await dispatch(addRole({ wsId, role: methods.getValues() }));
    navigate(`../${id}`, { replace: true });
  };

  useEffect(() => {
    if (roleId === 'new') {
      methods.reset({ workspace: wsId, ...defaultValues });
    } else {
      methods.reset(role);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId, role]);

  if (!roleId) {
    return null;
  }

  return (
    <div className=" file-details flex flex-col justify-around h-fullVH w-fullVW sm:h-full sm:w-full">
      <Header>
        <Tabs
          value={numOfTab}
          onChange={onChangeTab}
          indicatorColor="secondary"
          textColor="secondary"
          className="h-full"
        >
          <Tab className="h-full" label={t('INFORMATION')} />
          <Tab className="h-full" label={t('USERS')} />
          <Tab className="h-full" label={t('PERMISSIONS')} />
        </Tabs>
        <IconButton onClick={onClose} className="text-48 mr-4 self-center">
          <FuseSvgIcon size={24} color="action">
            heroicons-solid:x
          </FuseSvgIcon>
        </IconButton>
      </Header>
      <Content>
        <FormProvider
          {...methods}
          noValidate
          className="flex flex-col justify-center w-full h-full mt-32"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {numOfTab === 0 && <InformationTab />}

          {numOfTab === 1 && <UsersTab />}

          {numOfTab === 2 && <PermisionsTab />}
        </FormProvider>
      </Content>
      <div className="absolute right-12 bottom-12 flex gap-10">
        {roleId === 'new' ? (
          <Tooltip title={t('CREATE')} placement="top">
            <Fab
              color="secondary"
              size="small"
              aria-label="edit"
              onClick={createRole}
              disabled={!isValid}
            >
              <FuseSvgIcon size={20}>material-solid:save</FuseSvgIcon>
            </Fab>
          </Tooltip>
        ) : (
          <>
            <Tooltip title={t('DELETE')} placement="top">
              <Fab color="error" size="small" aria-label="remove" onClick={openDeleteModal}>
                <FuseSvgIcon size={20}>material-solid:delete</FuseSvgIcon>
              </Fab>
            </Tooltip>
            <Tooltip title={t('SAVE')} placement="top">
              <Fab
                color="secondary"
                size="small"
                aria-label="edit"
                onClick={saveRole}
                disabled={!isValid}
              >
                <FuseSvgIcon size={20}>material-solid:save</FuseSvgIcon>
              </Fab>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
}

export default DetailSidebarRoles;
