const locale = {
  ACCEPT: 'Aceptar',
  ACCURACY: 'Exactitud',
  ACTIVE_STATUS: 'Activa',
  ADD_FIELD: 'Agregar campo',
  ADDRESS: 'Dirección',
  ALL: 'Todos',
  ANY_ClIENT: 'Cualquier cliente',
  APPLIED_FILTERS: 'Filtros aplicados',
  APPLY_FILTERS: 'Aplicar filtros',
  ARE_SURE_DELETE_FORM: '¿Esta seguro de eliminar el formulario "{{name}}"?',
  ARE_SURE_REMOVE_ASSIGNMENT: '¿Estás seguro de eliminar esta tarea ({{title}})?',
  ARE_SURE_REMOVE_FILTERS: '¿Esta seguro de remover todos los filtros?',
  ASSIGN_TO: 'Asignar a',
  ASSIGNED_STATUS: 'Asignada',
  ASSIGNMENT_DONE_DUE_DATE: 'La tarea fue completada fuera de la fecha de vencimiento',
  ASSIGNMENT_NOT_FOUND: '404 Tarea no encontrada',
  ASSIGNMENTS: 'Tareas',
  BARCODE_DEPS:
    'Se mostrará un botón que el presionarlo se abrirá la cámara con una funcionalidad especial para detectar códigos QR y códigos de barra. El resultado se mostrará en un texto.',
  BARCODE_TITLE: 'Escaner para códigos de barra o códigos QR',
  BOOKMARK: 'Marcadas',
  CANCEL: 'Cancelar',
  CANCEL_STATUS: 'Cancelada',
  CHECK_BY_DEFAULT: 'Marcado por defecto',
  CLIENT: 'Cliente',
  CLIENTS: 'Clientes',
  CLOSE: 'Cerrar',
  CONDITIONALLY_SHOW_FIELD: 'Mostrar condicionalmente',
  CONDITIONING_FIELD: 'Campo Condicionante',
  CONDITIONING_VALUE: 'Valor Condicionante',
  CREATE_ASSIGNMENT: 'Crear tarea',
  CREATED_BY: 'Creado por',
  CREATION_DATE: 'Fecha de creación',
  DATE: 'Fecha',
  DATE_CREATED: 'Fecha de creación',
  DATE_DEPS: 'Se mostrará al usuario un calendario donde podrá elegir una fecha como respuesta.',
  DATE_DUE: 'Fecha de vencimiento',
  DATE_FIELD_VALUE: '{{dayWeek}}, {{dayMonth}} de {{month}} del {{year}}',
  DATE_STATUS: 'Fecha de cambio de estado',
  DATE_TITLE: 'Fecha',
  DATES: 'Fechas',
  DEFAULT_VALUE: 'Valor por Defecto',
  DELETE: 'Eliminar',
  DELETE_ASSIGNMENT: 'Eliminar Tarea',
  DELETE_FIELD: 'Eliminar campo',
  DELETE_FIELD_FORM_DESP: '¿Esta Seguro de eliminar el campo "{{titleField}}" del formulario?',
  DELETE_FIELD_FORM_TITLE: 'Eliminar campo de formulario',
  DELETE_FORM: 'Eliminar formulario',
  DESCRIPTION: 'Descripción',
  DETAILS: 'Detalles',
  DONE_STATUS: 'Completada',
  EDIT_ASSIGNMENT: 'Editar Tarea',
  EDIT_FIELD: 'Editar campo',
  EDIT_FORM: 'Editar formulario',
  EDITION_DATE: 'Fecha de edición',
  ERROR_LOADING_IMAGE: 'Error al cargar imagen',
  FAILED_STATUS: 'Fallada',
  FIELD_REQUIRED: 'Este campo es requerido',
  FIELD_TYPE: 'Tipo de campo',
  FIELDS: 'Campos',
  FILTER_BY: 'Filtrar por',
  FILTER_BY_DATE_CREATED: 'Filtrar por fecha de creación',
  FORM_DESC:
    'Utiliza los formularios para agrupar las preguntas más comunes. Estos formularios los puedes utilizar al crear tareas.',
  FORMS: 'Formularios',
  FROM_DATE: 'Desde',
  GENERATE_PDF: 'Generar PDF',
  GPS_DEPS:
    'Se le mostrará al usuario un botón, que al presionarlo se activará la función GPS del dispositivo, el resultado va ser un par de coordenadas geográficas de la ubicación actual del usuario.',
  GPS_TITLE: 'Localización (GPS)',
  INVALID_VALUE: 'Valor no válido',
  INVOICE_NAME: 'Facturar a nombre de',
  INVOICE_TAX: 'Código tributario',
  JUSTIFICATION: 'Justificación',
  KEYBOARD_TYPE: 'Tipo de teclado',
  LAST_EDITION: 'Última edición',
  LATITUDE: 'Latitud',
  UPLOAD_BY_CSV: 'Cargar por CSV',
  LONGITUDE: 'Longitud',
  MAXIMUM_NUMBER_CHARACTERS: 'Número máximo de caracteres',
  MOVE_DOWN_OPTION: 'Mover Opción hacia abajo',
  MOVE_FIELD: 'Mover campo',
  MOVE_UP_OPTION: 'Mover Opción hacia arriba',
  MULTI_TEXT_DEPS:
    'El usuario podrá escribir varias respuestas separadas a la misma pregunta. Por ejemplo para ingresar una lista de productos de interés para el cliente.',
  MULTI_TEXT_TITLE: 'Texto con varias respuestas',
  NAME: 'Nombre',
  NEW_FIELD_DESP: 'Seleciona el tipo de campo que nesesites',
  NEW_FIELD_TITLE: 'Nuevo campo',
  NEW_FORM: 'Nuevo Formulario',
  NO: 'No',
  NO_BARCODE_YET: 'Aún no se captura un código',
  NO_CLIENTS_SHOW: 'No hay clientes para mostrar',
  NO_CONDITIONING_FIELD:
    'No hay campos condicionantes, Agregue campos tipo Selección o tipo si/no para poder inidicar las reglas para mostrar este Campo',
  NO_DATE_YET: 'Aún no se ha tomado la fecha',
  NO_LOCATION_YET: 'Aún no hay ubicación',
  NO_FORMS_SHOW: 'No hay formularios para mostrar',
  NO_OPTION: 'Sin opciones',
  NO_PHOTO_YET: 'Aún no se ha tomado Foto',
  NO_RESPONSE: 'Sin respuesta',
  NO_SIGNATURE_YET: 'Aún no se ha firmado',
  NO_TASKS_TO_SHOW: 'No hay tareas para mostrar',
  NUMERICAL: 'Numérico',
  OBSERVATION: 'Observación',
  ONCHANGE_FILTERS_MESSEAGE: 'Cuando estés listo presiona "Aplicar filtros"',
  OPEN_CAMERA: 'Abrir cámara',
  OPTION_TEXT_REQUIRED: 'El texto de la opción es requerido',
  OPTIONAL: 'Opcional',
  OPTIONALY_WRITE_DETAILS_SECTION:
    'Opcionalmente, puede escribir más detalles aquí acerca de lo que desee mostrar a los usuarios.',
  OPTIONS_FIELD_SELECTED:
    'Este campo se mostrará cuando el campo "{{field_title}}" tenga seleccionado la opción "{{field_value}}"',
  OTHER_DATA: 'Otros datos',
  PAGINATION_LABEL: '{{from}} - {{to}} de {{count}}',
  PHOTO_DEPS:
    'Se le pedirá al usuario tomar una foto con las cámaras disponibles en el dispositivo. No se le permite elegir una foto de la galería, solo las que tome en ese momento.',
  PHOTO_TITLE: 'Foto',
  PLACE: 'Lugar',
  PLUS_CODE: 'Código Plus',
  PREVIEW: 'Vista previa',
  PREVIEW_FIELD: 'Vista previa de campo en la App',
  PRINT: 'Imprimir',
  PRINT_TICKET_DEPS:
    'Esta funcionalidad permite enviar a una impresora bluetooth portátil el texto indicado.',
  PRINT_TICKET_TITLE: 'Impresora',
  PROJECT: 'Proyecto',
  REFRESH: 'Refrescar',
  REMOVE: 'Eliminar',
  REMOVE_DATES: 'Remover Fechas',
  REMOVE_FIELD: 'Remover Campo',
  REMOVE_FILTERS: 'Remover filtros',
  REMOVE_OPTION: 'Remover Opción',
  REQUIRED: 'Requerido',
  SAVE_ASSINGMENT: 'Guardar Tarea',
  SAVE_FORM: 'Guardar formulario',
  SCAN: 'Escanear',
  SEARCH: 'Buscar',
  SEARCH_ASSIGNMENTS_BY: 'Buscar por título, descripción y observación',
  SEARCH_CLIENT_BY: 'Buscar por nombre, código interno, dirección, código tributario o contacto',
  SEARCH_FORM: 'Buscar formulario',
  SECTION_DEPS:
    'Este campo simplemente permite indicar un texto que se mostrará debajo de una línea para indicar la separación de una sección del formulario. El usuario no interactúa con el campo, solo le sirve para notar una separación o bien para leer instrucciones a seguir.',
  SECTION_TITLE: 'Separador',
  SEE_APPLIED_FILTERS: 'Ver filtros aplicados',
  SELECT_DATE: 'Seleccionar Fecha',
  SELECT_DEPS:
    'Se le mostrar al usuario una lista de opciones de la cual solo podrá elegir una como respuesta.',
  SELECT_MULTIPLE_DEPS:
    'Se le mostrar al usuario una lista de opciones de la cual podrá elegir varias respuestas.',
  SELECT_MULTIPLE_TITLE: 'Selección Multiple',
  SELECT_TITLE: 'Selección',
  SELECT_USER: 'Selecciona un usuario',
  SELECTED_CLIENT: 'Cliente seleccionado',
  SELECTED_USER: 'Usuario Selecionado',
  SELLER: 'Vendedor',
  SHORTID: 'ID corto',
  SIGNATURE_DEPS:
    'En la pantalla del dispositivo se mostrará un lienzo en blanco donde se podrá trazar una firma usando un dedo de la mano.',
  SIGNATURE_TITLE: 'Firma Digital',
  STATE_CHANGES: 'Cambios de estado',
  STATUS: 'Estado',
  TAGS: 'Etiquetas',
  TAKE_MY_LOCATION: 'Tomar mi ubicación',
  TEAM: 'Equipo',
  TEAMS: 'Equipos',
  TELEPHONIC: 'Telefónico',
  TERNARY_DEPS:
    'Con este campo puede mostrar una pregunta cuya respuesta sea simplemente un Si o un No. Si el campo es obligatorio entonces el usuario debe de presionar uno de los botones, si no es obligatorio entonces el usuario puede continuar sin responder.',
  TERNARY_FIELD_NOT: 'Este campo se mostrará cuando el campo "{{field_title}}" sea Afirmativo',
  TERNARY_FIELD_NULL: 'Este campo se mostrará cuando el campo "{{field_title}}" no sea respondido',
  TERNARY_FIELD_YES: 'Este campo se mostrará cuando el campo "{{field_title}}" sea Negativo',
  TERNARY_TITLE: 'Si / No',
  TEXT_DEPS:
    'El usuario va poder escribir una respuesta a lo solicitado en el título, se le puede mostrar tres tipos de teclado: númerico, telefónico y cualquier texto.',
  TEXT_TITLE: 'Texto',
  TEXT_TO_PRINT: 'Texto a imprimir',
  TEXTUAL: 'Textual',
  TITLE: 'Título',
  TITLE_CANNOT_EMPTY: 'El título no puede estar vacío',
  TITLE_REQUIRED: 'Una tarea debe llevar un título',
  TO_DATE: 'Hasta',
  USER: 'Usuario',
  USER_IN_CHARGE: 'Usuario a cargo',
  VALUE: 'Valor',
  WRITE_HERE_THE_OPTION: 'Escribe el título de la opción aquí',
  WRITE_TEXT_TO_PRINT: 'Escribe aquí el texto que deseas enviar a la impresora',
  YES: 'Si',
  DUPLICATE_ASSIGNMENT: 'Duplicar Tarea',
  ARE_SURE_DUPLICATE_ASSIGNMENT: '¿Estás seguro de duplicar esta tarea ({{title}})?',
};

export default locale;
