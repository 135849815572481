import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

export const getFormsAll = createAsyncThunk(
  'selectsData/forms/getFormAll',
  async (wsId, { dispatch, getState }) => {
    const { isFormsLoaded } = getState().selectsData.forms;
    if (!isFormsLoaded) {
      const response = await axios.get(ApiRoutes.formsEndPoint(wsId, { noPagination: true }));
      const data = await response.data;
      dispatch(setIsFormsLoaded(true));
      return data;
    }
    return [];
  }
);

const formsAdapter = createEntityAdapter({});

export const { selectAll: selectForms, selectById: selectFormById } = formsAdapter.getSelectors(
  (state) => state.selectsData.forms
);

const initialState = formsAdapter.getInitialState({
  isFormsLoaded: false,
});

const formSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    setIsFormsLoaded: (state, action) => {
      state.isFormsLoaded = action.payload;
    },
    resetFormsState: () => initialState,
  },
  extraReducers: {
    [getFormsAll.fulfilled]: formsAdapter.addMany,
  },
});

export const { setIsFormsLoaded, resetFormsState } = formSlice.actions;

export default formSlice.reducer;
