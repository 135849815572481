import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { selectWorkspace } from 'app/store/workspaceSlice';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useTranslation } from 'react-i18next';

const Root = styled('div')(({ theme }) => ({
  '& .username, & .email': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },

  '& .avatar': {
    background: theme.palette.background.default,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    bottom: 0,
    '& > img': {
      borderRadius: '50%',
    },
  },
}));

function ClientNavbarHeader(props) {
  const workspace = useSelector(selectWorkspace);
  const { t } = useTranslation('navigation');

  return (
    <Root className="user relative flex flex-col items-center justify-center p-16 pb-14 shadow-0">
      {workspace?.aux_data?.brand_image && (
        <div className="flex items-center justify-center mb-24">
          <Avatar
            sx={{
              backgroundColor: 'background.paper',
              color: 'text.secondary',
            }}
            className="avatar text-32 font-bold w-96 h-96"
            src={workspace.aux_data.brand_image.url}
          >
            <Tooltip title={t('COMPANY_LOGO_CAN_HERE')}>
              <FuseSvgIcon>heroicons-solid:search</FuseSvgIcon>
            </Tooltip>
          </Avatar>
        </div>
      )}
      <Typography className="username text-14 whitespace-nowrap font-medium">
        {workspace?.name}
      </Typography>
      {/* <Typography className="email text-13 whitespace-nowrap font-medium" color="text.secondary">
        {workspace.shortid}
      </Typography> */}
    </Root>
  );
}

export default ClientNavbarHeader;
