import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  // p: 4,
};

export default function ConfirmAcction({
  open = true,
  onConfirm = () => {},
  onCancel = () => {},
  onClose = () => {},
  confirmText = '',
  cancelText = '',
  title = '',
  description = '',
  className = '',
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className={className}>
        <div className="p-12">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {description}
          </Typography>
        </div>
        <div className="flex w-full justify-end gap-10 py-8 px-4">
          <Button variant="contained" onClick={onCancel}>
            {cancelText}
          </Button>
          <Button variant="contained" color="secondary" onClick={onConfirm}>
            {confirmText}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
