import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { getUsersAll, selectUsers } from 'app/store/selectsData/usersSlice';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@mui/styles';

const TexTFieldSearch = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 50,
      },
    },
  },
})(TextField);

function UsersTab(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation('managementApp');
  const { wsId } = useParams();
  const methods = useFormContext();
  const { getValues, setValue, watch } = methods;
  const { isUsersLoaded } = useSelector((state) => state.selectsData.users);
  const users = useSelector(selectUsers);
  const [searchText, setSearchtext] = useState('');

  const usersFiltered = useMemo(() => {
    if (!searchText) {
      return users;
    }
    return users.filter(
      (item) =>
        item.name.toLowerCase().match(searchText.toLowerCase()) ||
        item.last_name.toLowerCase().match(searchText.toLowerCase()) ||
        item.email.toLowerCase().match(searchText.toLowerCase())
    );
  }, [users, searchText]);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!isUsersLoaded) {
      dispatch(getUsersAll(wsId)).then(() => setLoading(false));
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onCheck = ({ target: { checked } }, userId) => {
    const usersIds = getValues('users');
    if (checked) {
      usersIds.push(userId);
    } else {
      const index = usersIds.indexOf(userId);
      if (index > -1) {
        usersIds.splice(index, 1);
      }
    }
    setValue('users', usersIds);
  };

  const onCheckAll = ({ target: { checked } }) =>
    setValue('users', checked ? usersFiltered.map((user) => user.id) : []);

  if (isLoading) {
    return (
      <div className="h-full flex-1 flex items-center">
        <FuseLoading />
      </div>
    );
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '10%', paddingX: 1 }}>
        <div className="w-5/12">
          <Typography className=" font-bold flex-1" variant="subtitle1">
            {t('USERS_WITH_THIS_ROLE')}
          </Typography>
        </div>
        <div className="w-6/12">
          <TexTFieldSearch
            onChange={({ target: { value } }) => setSearchtext(value)}
            value={searchText}
            size="small"
            placeholder={t('SEARCH_USERS')}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FuseSvgIcon className="text-48" size={24} color="action">
                    material-solid:search
                  </FuseSvgIcon>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-1/12 flex justify-center">
          <Checkbox
            edge="start"
            checked={watch('users').length === usersFiltered.length}
            disableRipple
            size="small"
            onChange={onCheckAll}
          />
        </div>
      </Box>
      <List
        className="w-full overflow-hidden overflow-y-scroll pb-64"
        sx={{
          bgcolor: 'background.paper',
          height: '90%',
        }}
      >
        {usersFiltered.map((user) => {
          return (
            <ListItem
              key={user.id}
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <FuseSvgIcon className="text-48" size={24} color="action">
                    material-solid:person
                  </FuseSvgIcon>
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton role={undefined} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={watch('users').includes(user.id)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': user.id }}
                    onChange={(e) => onCheck(e, user.id)}
                  />
                </ListItemIcon>
                <ListItemText
                  id={user.id}
                  primary={`${user.name} ${user.last_name}`}
                  secondary={user.email}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}

export default UsersTab;
