import { useParams } from 'react-router-dom';

import DetailSidebarSuperusers from './RolesSidebarSuperusers';
import DetailSidebarRoles from './RolesSidebar';

function RolesSidebarContent(props) {
  const { roleId } = useParams();

  if (roleId === 'superusers') return <DetailSidebarSuperusers />;
  return <DetailSidebarRoles />;
}
export default RolesSidebarContent;
