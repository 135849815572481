/* eslint-disable camelcase */
import Fab from '@mui/material/Fab';
import FuseLoading from '@fuse/core/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import TextField from '@mui/material/TextField';
import { permissionChecker } from 'app/store/userWorkspacePermissionsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

import {
  addClient,
  selectClientById,
  selectClientData,
  setClient,
  setRightSidebarProps,
  syncClientPlaces,
} from '../../../store/clientsSlice';

function UnknownLocationClient(props) {
  const { t } = useTranslation('managementApp');
  const { wsId, clientId } = useParams();
  const client = useSelector((state) => selectClientById(state, clientId));
  const { editMode, IsLoading } = useSelector(selectClientData).rightSiderbar;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkPermission = useSelector(permissionChecker);

  const { control, getValues, formState, setValue, reset } = useFormContext();
  const { errors, isValid } = formState;

  const createClient = async () => {
    dispatch(setRightSidebarProps({ IsLoading: true }));

    const clientData = getValues();

    try {
      const [{ id }] = await dispatch(addClient({ wsId, client: clientData })).unwrap();
      if (clientData.places_data)
        await dispatch(
          syncClientPlaces({ wsId, clientId: id, places_data: clientData.places_data })
        );
      navigate(`../${id}${window.location.search}`, { replace: true });
      dispatch(setRightSidebarProps({ IsLoading: false, editMode: false }));
    } catch (error) {
      dispatch(setRightSidebarProps({ IsLoading: false, editMode: true }));
    }
  };

  const saveClient = async () => {
    dispatch(setRightSidebarProps({ IsLoading: true }));

    const clientData = getValues();

    await dispatch(setClient({ wsId, client: clientData })).unwrap();
    await dispatch(
      syncClientPlaces({ wsId, clientId: clientData.id, places_data: clientData.places_data })
    ).unwrap();

    dispatch(setRightSidebarProps({ IsLoading: false, editMode: false }));
  };

  const onEdit = () => {
    reset(client);
    dispatch(setRightSidebarProps({ editMode: true }));
  };

  if (IsLoading) {
    return (
      <div className="h-full flex-1 flex items-center">
        <FuseLoading />
      </div>
    );
  }

  const onChangeTabMap = (e, newTab) => {
    dispatch(setRightSidebarProps({ tab: 'map' }));
    setValue('location_mode', 'through-client-coordinate');
  };

  const onChangeTabPlaces = (e, newTab) => {
    dispatch(setRightSidebarProps({ tab: 'places' }));
    setValue('location_mode', 'through-client-places');
  };

  const onChangeTabUnknown = (e, newTab) => {
    dispatch(setRightSidebarProps({ tab: 'no_location' }));
    setValue('location_mode', '');
  };

  return (
    <div className="flex flex-col flex-1 w-full h-full">
      {editMode ? (
        <div className=" my-8 mx-4">
          {/* <Controller
          name="location_mode"
          control={control}
          render={({ field: { onChange, value, onBlur, ref } }) => (
            <FormControl>
              <FormControlLabel
                label={t('CLIENT_MORE_ONE_LOCATION')}
                control={
                  <Checkbox
                    checked={value === 'through-client-places'}
                    onBlur={onBlur}
                    onChange={(ev) => onChange(onChangeLocationMode())}
                    inputRef={ref}
                  />
                }
              />
              <FormHelperText>{t('IF_CLIENT_MULTIPLE_LOCATION')}</FormHelperText>
            </FormControl>
          )}
        /> */}
          <div>
            <div className="flex justify-end py-5">
              <ButtonGroup disableElevation color="secondary" size="small">
                <Button
                  variant="contained"
                  className="gap-5 flex justify-start"
                  onClick={onChangeTabUnknown}
                >
                  <FuseSvgIcon size={18}>material-solid:location_off</FuseSvgIcon>
                  <Typography className="text-12">{t('UNKNOWN')}</Typography>
                </Button>

                <Button
                  variant="outlined"
                  className="gap-5 flex justify-start"
                  onClick={onChangeTabMap}
                >
                  <FuseSvgIcon size={18}>material-solid:location_on</FuseSvgIcon>
                  <Typography className="text-12">{t('SINGLE_LOCATION')}</Typography>
                </Button>

                <Button
                  variant="outlined"
                  className="gap-5 flex justify-start"
                  onClick={onChangeTabPlaces}
                >
                  <FuseSvgIcon size={18}>material-solid:location_city</FuseSvgIcon>
                  <Typography className="text-12">{t('MANY_LOCATION')}</Typography>
                </Button>
              </ButtonGroup>
            </div>
          </div>

          <div className=" my-8 mx-4">
            <Typography className=" text-grey-400 text-12">{t('OPTIONAL_GIVE_ADDRESS')}</Typography>
            <div className="flex items-center justify-between gap-5 ">
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!checkPermission('edit_client')}
                    label={t('ADDRESS')}
                    error={!!errors.address}
                    helperText={errors?.address?.message}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </div>

            {checkPermission('edit_client') && (
              <div className="absolute right-12 bottom-12 flex gap-10">
                <Fab
                  onClick={createClient}
                  size="small"
                  color="secondary"
                  aria-label="save"
                  disabled={!isValid}
                >
                  <FuseSvgIcon className="text-white" size={20}>
                    material-solid:save
                  </FuseSvgIcon>
                </Fab>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-between py-8">
          <Typography variant="body2">
            <strong>{t('ADDRESS')}: </strong>
            {client.address}
          </Typography>
          {checkPermission('edit_client') && (
            <Fab onClick={onEdit} size="small" color="secondary" aria-label="add">
              <FuseSvgIcon className="text-white" size={20}>
                heroicons-solid:pencil
              </FuseSvgIcon>
            </Fab>
          )}
        </div>
      )}
    </div>
  );
}

export default UnknownLocationClient;
