/* eslint-disable camelcase */
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import MapFieldPosition from '@fuse/core/FuseMaps/MapFieldPosition';
import MapSettings from '@fuse/core/FuseMaps/MapSettingIcon/MapSetting';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import { selectWorkspace } from 'app/store/workspaceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OpenLocationPlusCode from 'src/app/services/OpenLocationPlusCode';

import {
  selectClientById,
  selectClientData,
  setlocatePlaceModalProps,
} from '../../store/clientsSlice';

function LocatePlaceModal(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation('managementApp');
  const { clientId } = useParams();
  const { editMode } = useSelector(selectClientData).rightSiderbar;
  const { aux_data } = useSelector(selectWorkspace);
  const client = useSelector((state) => selectClientById(state, clientId));
  const { getValues, setValue, watch } = useFormContext();
  const placesData = editMode ? watch('places_data') : client?.places_data;
  const { placeIndex, open } = useSelector(
    ({ managementApp }) => managementApp.clients.locatePlaceModalProps
  );
  const [positionMap, setPositionMap] = useState({ coords: [0, 0], zoom: 18 });

  const onPositionMap = (e) => setPositionMap(e);

  const closeMapModal = () => dispatch(setlocatePlaceModalProps({ open: false, placeIndex: null }));

  const onChangePlaceLocation = () => {
    setValue(`places_data.${placeIndex}.latitude`, positionMap.coords[0], { shouldValidate: true });
    setValue(`places_data.${placeIndex}.longitude`, positionMap.coords[1], {
      shouldValidate: true,
    });
    setValue(
      `places_data.${placeIndex}.plus_code`,
      OpenLocationPlusCode.encode(positionMap.coords[0], positionMap.coords[1])
    );
    const place = getValues(`places_data.${placeIndex}`);

    if (!place.create && !place.delete && !place.update)
      setValue(`places_data.${placeIndex}.update`, true);

    closeMapModal();
  };

  useEffect(() => {
    if (placeIndex !== null) {
      const placeSelected = placesData?.[placeIndex];
      if (placeSelected.latitude && placeSelected.longitude)
        setPositionMap({
          coords: [placeSelected.latitude, placeSelected.longitude],
          zoom: 18,
          setPosition: true,
        });
      else {
        setPositionMap({
          coords: [aux_data.coordinates.latitude, aux_data.coordinates.longitude],
          zoom: aux_data.coordinates.zoom,
          setPosition: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeIndex]);

  return (
    <Dialog open={open} className="p-20 sm:p-60" fullScreen onClose={closeMapModal} scroll="body">
      <div className="flex flex-col h-full w-full">
        <MapSettings className="absolute z-999 right-0 m-5 mt-80" />

        <AppBar position="static" elevation={0}>
          <Toolbar className="flex justify-between pr-5 w-full">
            <Typography variant="subtitle1" color="inherit">
              {t('LOCATION_PLACES')}
            </Typography>
            <div>
              {editMode && (
                <Button
                  size="small"
                  color="secondary"
                  variant="contained"
                  endIcon={
                    <FuseSvgIcon className="text-48" size={20}>
                      material-solid:edit_location
                    </FuseSvgIcon>
                  }
                  onClick={onChangePlaceLocation}
                >
                  {t('SET_LOCATION_HERE')}
                </Button>
              )}
              <IconButton onClick={closeMapModal} aria-label="delete" color="inherit">
                <FuseSvgIcon className="text-48" size={20}>
                  material-solid:close
                </FuseSvgIcon>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <div className="flex-auto h-0">
          <MapFieldPosition
            scrollable
            showPlacesMakers
            positionMap={positionMap}
            setPositionMap={onPositionMap}
            isEditing={editMode}
            iconType={placesData?.[placeIndex]?.type}
            extraMakers={placesData?.map((place) => ({
              coords: [place.latitude, place.longitude],
              label: place.name,
              type: place.type,
            }))}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default LocatePlaceModal;
