import * as yup from 'yup';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { format, parseISO } from 'date-fns';
import { motion } from 'framer-motion';
import { permissionChecker } from 'app/store/userWorkspacePermissionsSlice';
import { roleChecker } from 'app/store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  addProject,
  selectProjectById,
  setDeleteModalProps,
  setIsEditing,
  setProject,
} from '../store/projectsSlice';

const defaultValues = {
  name: '',
  description: '',
};

function ProjectsSidebarContent(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkPermission = useSelector(permissionChecker);
  const checkRole = useSelector(roleChecker);

  const { t } = useTranslation('managementApp');
  const { projectId, wsId } = useParams();
  const project = useSelector((state) => selectProjectById(state, projectId));
  const { isEditing } = useSelector(({ managementApp }) => managementApp.projects);

  const onClose = () => navigate(`..${window.location.search}`);

  const onEdit = () => {
    reset(project);
    dispatch(setIsEditing(true));
  };

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    name: yup.string().required(t('NAME_CANNOT_EMPTY')),
  });

  const { control, formState, handleSubmit, setError, getValues, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const saveProject = () => {
    dispatch(setProject({ wsId, project: getValues() }));
    dispatch(setIsEditing(false));
  };

  const createProject = async () => {
    const {
      payload: [{ id }],
    } = await dispatch(addProject({ wsId, project: getValues() }));
    navigate(`../${id}${window.location.search}`, { replace: true });
    dispatch(setIsEditing(false));
  };

  const openDeleteModal = () => dispatch(setDeleteModalProps({ open: true, projectId }));

  useEffect(() => {
    if (projectId === 'new') {
      reset(defaultValues);
    } else if (isEditing) {
      reset(project);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, projectId, isEditing]);

  const { isValid, dirtyFields, errors } = formState;

  if (!project && projectId !== 'new') {
    return null;
  }

  return (
    <motion.div
      initial={{ y: 50, opacity: 0.8 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.3 } }}
      className="file-details px-24 sm:px-32"
    >
      <div className="flex items-center justify-end w-full ">
        <IconButton className="" size="large" onClick={onClose}>
          <FuseSvgIcon>heroicons-solid:x</FuseSvgIcon>
        </IconButton>
      </div>
      {!isEditing && Boolean(project) ? (
        <div className="mt-32">
          <Typography className="text-18 font-medium">{project?.name}</Typography>

          {project?.description && (
            <>
              {/* <div className="text-16 font-medium mt-32 pb-16 border-b">Description</div> */}
              <Divider className="my-32" />
              <Typography variant="caption" className="py-12">
                {project.description}
              </Typography>
            </>
          )}
          {checkRole('platform_support') && (
            <>
              <div className="w-full py-4">
                <Divider />
              </div>
              <div>
                <Typography variant="caption" className="font-medium">
                  {t('CREATION_DATE')}:
                </Typography>
                <Typography variant="caption">
                  {format(parseISO(project?.date_created), 'dd/MM/y hh:mm aaaa ')}
                </Typography>
              </div>
              <div>
                <Typography variant="caption" className="font-medium">
                  {t('EDITION_DATE')}:
                </Typography>
                <Typography variant="caption">
                  {format(parseISO(project?.date_edited), 'dd/MM/y hh:mm aaaa ')}
                </Typography>
              </div>
              <div>
                <Typography variant="caption" className="font-medium">
                  ID:
                </Typography>
                <Typography variant="caption">{project?.id}</Typography>
              </div>
            </>
          )}
          <div className="absolute right-24 bottom-24 flex gap-10">
            {checkPermission('edit_project') && (
              <Tooltip title={t('EDIT')} placement="top">
                <Fab color="secondary" size="small" aria-label="edit" onClick={onEdit}>
                  <FuseSvgIcon size={20}>heroicons-solid:pencil</FuseSvgIcon>
                </Fab>
              </Tooltip>
            )}
            {checkPermission('delete_project') && (
              <Tooltip title={t('DELETE')} placement="top">
                <Fab color="error" size="small" aria-label="remove" onClick={openDeleteModal}>
                  <FuseSvgIcon size={20}>material-solid:delete</FuseSvgIcon>
                </Fab>
              </Tooltip>
            )}
          </div>
        </div>
      ) : (
        <form
          name="projectForm"
          noValidate
          className="flex flex-col justify-center w-full mt-32"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="tag-name"
                disabled={!checkPermission('edit_project')}
                className="mb-24"
                label={t('NAME')}
                autoFocus
                // type="email"
                error={!!errors.name}
                helperText={errors?.name?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />

          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!checkPermission('edit_project')}
                className="mb-24"
                label={t('DESCRIPTION')}
                multiline
                // type="password"
                error={!!errors.description}
                helperText={errors?.description?.message}
                variant="outlined"
                fullWidth
              />
            )}
          />

          <div className="grid grid-cols-2 gap-16 w-full">
            {projectId === 'new' && checkPermission('create_project') ? (
              <div className="absolute right-12 bottom-12 flex gap-10">
                <Tooltip title={t('CREATE')} placement="top">
                  <Fab
                    color="secondary"
                    size="small"
                    aria-label="edit"
                    onClick={createProject}
                    disabled={!isValid}
                  >
                    <FuseSvgIcon size={20}>material-solid:save</FuseSvgIcon>
                  </Fab>
                </Tooltip>
              </div>
            ) : (
              <>
                {checkPermission('edit_project') && (
                  <div className="absolute right-12 bottom-12 flex gap-10">
                    <Tooltip title={t('SAVE')} placement="top">
                      <Fab
                        color="secondary"
                        size="small"
                        aria-label="edit"
                        onClick={saveProject}
                        disabled={!isValid}
                      >
                        <FuseSvgIcon size={20}>material-solid:save</FuseSvgIcon>
                      </Fab>
                    </Tooltip>
                  </div>
                )}
              </>
            )}
          </div>
        </form>
      )}
    </motion.div>
  );
}

export default ProjectsSidebarContent;
