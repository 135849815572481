import Box from '@mui/material/Box';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { lighten } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { permissionChecker } from 'app/store/userWorkspacePermissionsSlice';
import { roleChecker } from 'app/store/userSlice';
import { setDeleteModalProps, setIsEditing } from '../store/FormsSlice';

function FormItem(props) {
  const { form } = props;
  const { t } = useTranslation('managementApp');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const checkPermission = useSelector(permissionChecker);
  const checkRole = useSelector(roleChecker);
  const [optionPopUp, setOptionsVisibility] = useState(null);

  const openPopUpOption = Boolean(optionPopUp);
  const idOptionsPopUp = openPopUpOption ? 'Options-PopUp' : undefined;

  const openPopOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOptionsVisibility(optionPopUp ? null : event.currentTarget);
  };

  const closePopOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOptionsVisibility(false);
  };

  const infoForm = (e) => {
    navigate(`./${form.id}${window.location.search}`);
    dispatch(setIsEditing(false));
    closePopOver(e);
  };

  const editForm = (e) => {
    navigate(`./${form.id}${window.location.search}`);
    dispatch(setIsEditing(true));
    closePopOver(e);
  };

  const deleteForm = (e) => {
    dispatch(setDeleteModalProps({ open: true, formId: form.id }));
    closePopOver(e);
  };

  if (!form) {
    return null;
  }

  return (
    <>
      <Box
        sx={(theme) => ({
          backgroundColor:
            theme.palette.mode === 'light'
              ? lighten(theme.palette.background.default, 0.4)
              : lighten(theme.palette.background.default, 0.02),
          width: '27vw',
          [theme.breakpoints.down('lg')]: {
            width: '100%',
          },
        })}
        className="flex items-center h-100 m-8 p-8 shadow-xl  rounded-16"
        onClick={infoForm}
      >
        <FuseSvgIcon size={25}>material-solid:assignment</FuseSvgIcon>

        <div className="flex flex-col h-full w-full">
          <div className="flex shrink flex-col justify-center text-center">
            <Typography className="truncate text-14 font-medium">{form.name}</Typography>
          </div>
        </div>
        <IconButton onClick={openPopOver} size="small">
          <FuseSvgIcon size={20}>material-solid:more_vert</FuseSvgIcon>
        </IconButton>
      </Box>
      <Popover
        id={idOptionsPopUp}
        open={openPopUpOption}
        onClose={closePopOver}
        anchorEl={optionPopUp}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={0}
        PaperProps={{
          style: { borderRadius: 0 },
        }}
      >
        <MenuItem role="button" style={{ border: 'solid', borderWidth: 0.5 }} onClick={infoForm}>
          <ListItemIcon className="min-w-40">
            <FuseSvgIcon className="text-48" size={24} color="action">
              material-solid:info
            </FuseSvgIcon>
          </ListItemIcon>
          <ListItemText primary={t('INFORMATION')} />
        </MenuItem>

        {(checkRole('platform_admin') ||
          checkRole('platform_support') ||
          checkPermission('edit_form')) && (
          <>
            <MenuItem
              role="button"
              style={{ border: 'solid', borderWidth: 0.5 }}
              onClick={editForm}
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon className="text-48" size={24} color="action">
                  material-solid:mode_edit
                </FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary={t('EDIT')} />
            </MenuItem>
          </>
        )}

        {(checkRole('platform_admin') ||
          checkRole('platform_support') ||
          checkPermission('delete_form')) && (
          <>
            <MenuItem
              role="button"
              style={{ border: 'solid', borderWidth: 0.5 }}
              onClick={deleteForm}
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon className="text-48" size={24} color="action">
                  material-solid:delete
                </FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary={t('DELETE')} />
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
}

export default FormItem;
