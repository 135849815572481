import { createSelector, createSlice } from '@reduxjs/toolkit';
import i18n from 'src/i18n';
import { setDefaultOptions } from 'date-fns';

import { es, enUS } from 'date-fns/locale';
// eslint-disable-next-line import/no-cycle
import { setDefaultSettings } from './fuse/settingsSlice';

export const changeLanguage = (language) => (dispatch, getState) => {
  const { direction } = getState().fuse.settings.defaults;

  const newLangDirection = i18n.dir(language);

  /*
    If necessary, change theme direction
     */
  if (newLangDirection !== direction) {
    dispatch(setDefaultSettings({ direction: newLangDirection }));
  }

  if (language === 'en') {
    setDefaultOptions({ locale: enUS });
  } else {
    setDefaultOptions({ locale: es });
  }

  /*
    Change Language
     */
  return i18n.changeLanguage(language).then(() => {
    dispatch(i18nSlice.actions.languageChanged(language));
  });
};

const i18nSlice = createSlice({
  name: 'i18n',
  initialState: {
    language: i18n.options.lng,
    languages: [
      { id: 'en', title: 'English', flag: 'US' },
      // { id: 'tr', title: 'Turkish', flag: 'TR' },
      // { id: 'ar', title: 'Arabic', flag: 'SA' },
      { id: 'es', title: 'Español', flag: 'ES' },
    ],
  },
  reducers: {
    languageChanged: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const selectCurrentLanguageId = ({ i18n: _i18n }) => _i18n.language;

export const selectLanguages = ({ i18n: _i18n }) => _i18n.languages;

export const selectCurrentLanguageDirection = createSelector([selectCurrentLanguageId], (id) => {
  return i18n.dir(id);
});

export const selectCurrentLanguage = createSelector(
  [selectCurrentLanguageId, selectLanguages],
  (id, languages) => {
    return languages.find((lng) => lng.id === id);
  }
);

export default i18nSlice.reducer;
