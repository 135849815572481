import { lazy } from 'react';
import i18next from 'i18next';
import authRoles from '../../../auth/authRoles';
import en from '../i18n/en';
import es from '../i18n/es';

const SignUpPage = lazy(() => import('./SignUpPage'));

i18next.addResourceBundle('en', 'authApp', en);
i18next.addResourceBundle('es', 'authApp', es);

const SignUpConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'sign-up',
      element: <SignUpPage />,
    },
  ],
};

export default SignUpConfig;
