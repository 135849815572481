import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

export const getForms = createAsyncThunk(
  'assignmentsApp/forms/getForms',
  async (params, { dispatch, getState }) => {
    dispatch(setIsLoading(true));
    const response = await axios.get(ApiRoutes.formsEndPoint(params.wsId, params.paramsApi));
    dispatch(setIsLoading(false));
    dispatch(setNumOfPages(Math.ceil(response.data.count / 100 || 0)));
    const data = await response.data.results;

    return data;
  }
);

export const setForm = createAsyncThunk(
  'assignmentsApp/forms/setForms',
  async ({ wsId, form }, { dispatch, getState }) => {
    const response = await axios.put(`${ApiRoutes.formsEndPoint(wsId) + form.id}/`, form);
    const data = await response.data;

    return data;
  }
);

export const addForm = createAsyncThunk(
  'assignmentsApp/forms/addForms',
  async (params, { dispatch, getState }) => {
    const response = await axios.post(ApiRoutes.formsEndPoint(params.wsId), params.form);
    const data = await response.data;

    const { entities } = getState().assignmentsApp.forms;
    const Forms = Object.keys(entities).map((key) => entities[key]);

    return [data, ...Forms];
  }
);

export const removeForm = createAsyncThunk(
  'assignmentsApp/forms/removeForms',
  async (params, { dispatch, getState }) => {
    await axios.delete(`${ApiRoutes.formsEndPoint(params.wsId) + params.form.id}/`);

    return params.form.id;
  }
);

export const formSelector = ({ assignmentsApp }) => assignmentsApp.forms;

const formsAdapter = createEntityAdapter({});

export const { selectAll: selectForms, selectById: selectFormById } =
  formsAdapter.getSelectors(formSelector);

const initialState = formsAdapter.getInitialState({
  numOfPages: 0,
  page: 1,
  isLoading: true,
  isEditing: false,
  deleteModalProps: {
    open: false,
    formId: null,
  },
});

const formsSlice = createSlice({
  name: 'assignmentsApp/forms',
  initialState,
  reducers: {
    setNumOfPages: (state, action) => {
      state.numOfPages = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsEditing: (state, action) => {
      state.isEditing = action.payload;
    },
    setDeleteModalProps: (state, action) => {
      state.deleteModalProps = action.payload;
    },
    resetFormsState: () => initialState,
  },
  extraReducers: {
    [getForms.fulfilled]: formsAdapter.setAll,
    [setForm.fulfilled]: formsAdapter.setOne,
    [addForm.fulfilled]: formsAdapter.setAll,
    [removeForm.fulfilled]: formsAdapter.removeOne,
  },
});

export const {
  setNumOfPages,
  setIsLoading,
  setIsEditing,
  setPage,
  setDeleteModalProps,
  resetFormsState,
} = formsSlice.actions;

export default formsSlice.reducer;
