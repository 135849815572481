import i18next from 'i18next';
import { lazy } from 'react';

import en from './i18n/en';
import es from './i18n/es';

const SettingsApp = lazy(() => import('./Settings'));
i18next.addResourceBundle('en', 'settingsApp', en);
i18next.addResourceBundle('es', 'settingsApp', es);

const SettingsAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/ws/:wsId/settings',
      element: <SettingsApp />,
      superUserRequired: true,
    },
  ],
};

export default SettingsAppConfig;
