import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { t } from 'i18next';
import { showMessage } from './fuse/messageSlice';

import ApiRoutes from '../services/ApiRoutes';

const initialState = { billing: {}, contacts: {}, users: [] };
// main get
export const getOrganization = createAsyncThunk(
  'organization/getOrganization',
  async (orgId, { dispatch, getState }) => {
    const response = await axios.get(ApiRoutes.organizationEndPoint() + orgId);

    const data = await response.data;

    return { ...initialState, ...data };
  }
);

export const setOrganization = createAsyncThunk(
  'organization/setOrganization',
  async (org, { dispatch, getState }) => {
    const response = await axios.put(`${ApiRoutes.organizationEndPoint() + org.id}/`, org);

    const data = await response.data;

    dispatch(
      showMessage({
        message: t('organizationApp:CHANGES_SAVED'),
        variant: 'success',
      })
    );

    return { ...org, ...data };
  }
);

export const setOrgBilling = createAsyncThunk(
  'organization/setOrgBilling',
  async (org, { dispatch, getState }) => {
    const response = await axios.put(
      ApiRoutes.setOrganizationBilling(org.id, org.billing.id),
      org.billing
    );

    const billing = await response.data;

    dispatch(
      showMessage({
        message: t('organizationApp:CHANGES_SAVED'),
        variant: 'success',
      })
    );

    return { ...org, billing };
  }
);

export const setOrgContact = createAsyncThunk(
  'organization/setOrgContact',
  async ({ org, contact }, { dispatch, getState }) => {
    const response = await axios.put(ApiRoutes.setOrganizationContact(org.id, contact.id), contact);

    dispatch(
      showMessage({
        message: t('organizationApp:CHANGES_SAVED'),
        variant: 'success',
      })
    );

    return { ...org, contacts: { ...org.contacts, [contact.id]: response.data } };
  }
);

export const getOrgWorkspaces = createAsyncThunk(
  'organization/getOrgWorkspaces',
  async (org, { dispatch, getState }) => {
    const response = await axios.get(`${ApiRoutes.organizationEndPoint() + org.id}/workspaces`);
    const workspaces = await response.data;

    return { ...org, workspaces };
  }
);

export const addOrgWorkspace = createAsyncThunk(
  'organization/addOrgWorkspace',
  async (workspace, { rejectWithValue, getState }) => {
    const { organization } = getState();
    try {
      const response = await axios.post(ApiRoutes.workspaceEndPoint(), workspace);
      const data = await response.data;
      return { ...organization, workspaces: [data, ...organization.workspaces] };
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const getOrgBilling = createAsyncThunk(
  'organization/getOrgBilling',
  async (org, { dispatch, getState }) => {
    const response = await axios.get(ApiRoutes.getOrganizationBilling(org.id));

    const billing = await response.data;

    return { ...org, billing };
  }
);

export const getOrgContacts = createAsyncThunk(
  'organization/getOrgContacts',
  async (org, { dispatch, getState }) => {
    const response = await axios.get(ApiRoutes.getOrganizationContacts(org.id));

    const contacts = await response.data.reduce((a, v) => ({ ...a, [v.id]: v }), {});

    return { ...org, contacts };
  }
);

export const getOrgUsers = createAsyncThunk(
  'organization/getOrgUsers',
  async (org, { dispatch, getState }) => {
    const response = await axios.get(ApiRoutes.getOrganizationUsers(org.id));

    const users = await response.data;

    return { ...org, users };
  }
);

export const selectOrganization = ({ organization }) => organization;

const organizationsSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    organizationReset: (state, action) => initialState,
    setDataOrganization: (state, action) => ({ ...initialState, ...action.payload }),
  },
  extraReducers: {
    [getOrganization.fulfilled]: (state, action) => action.payload,
    [getOrgBilling.fulfilled]: (state, action) => action.payload,
    [getOrgContacts.fulfilled]: (state, action) => action.payload,
    [getOrgWorkspaces.fulfilled]: (state, action) => action.payload,
    [addOrgWorkspace.fulfilled]: (state, action) => action.payload,
    [getOrgUsers.fulfilled]: (state, action) => action.payload,
    [setOrganization.fulfilled]: (state, action) => action.payload,
    [setOrgBilling.fulfilled]: (state, action) => action.payload,
    [setOrgContact.fulfilled]: (state, action) => action.payload,
  },
});

export const { organizationReset, setDataOrganization } = organizationsSlice.actions;

export default organizationsSlice.reducer;
