import withReducer from 'app/store/withReducer';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { Outlet, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import reducer from '../store';
import FormsHeader from './FormsHeader';
import FormsList from './FormList';
import DeleteForm from './modals/DeleteForm';

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-sidebarWrapper.permanent': {
    transitionProperty: 'width',
    transitionDuration: '0.5s',
  },
  '& . FusePageCarded-sidebarContent': {
    position: 'relative',
  },
  '& . MuiPaper-root': {
    width: '100%',
  },
}));

function Forms() {
  const { formId } = useParams();
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);

  useEffect(() => {
    setRightSidebarOpen(Boolean(formId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId]);

  return (
    <>
      <Root
        header={<FormsHeader />}
        content={<FormsList />}
        rightSidebarContent={<Outlet />}
        rightSidebarOpen={rightSidebarOpen}
        rightSidebarOnClose={() => setRightSidebarOpen(false)}
        rightSidebarWidth={400}
        scroll="content"
      />
      <DeleteForm />
    </>
  );
}

export default withReducer('assignmentsApp', reducer)(Forms);
