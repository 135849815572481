import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';
import { t } from 'i18next';
import ApiRoutes from 'src/app/services/ApiRoutes';

export const getAssignments = createAsyncThunk(
  'assignmentsApp/assignments/getAssignments',
  async ({ wsId, params }, { getState, dispatch }) => {
    await dispatch(setIsLoading(true));
    const { data } = await axios.get(ApiRoutes.assignmentsEndPoint(wsId, params));
    dispatch(setPage(params.page));
    dispatch(setNumOfPages(Math.ceil(data.count / 100 || 0)));
    dispatch(setCount(data.count));
    await dispatch(setIsLoading(false));
    return data.results;
  }
);

export const getAssignment = createAsyncThunk(
  'assignmentsApp/assignments/getAssignment',
  async ({ wsId, id }, { getState, dispatch }) => {
    const { entities } = getState().assignmentsApp.assignments;

    if (entities[id]) return entities[id];

    const { data } = await axios.get(ApiRoutes.assignmentsEndPoint(wsId) + id);

    return data;
  }
);

export const getFullDataAssignment = createAsyncThunk(
  'assignmentsApp/assignments/getFullDataAssignment',
  async ({ wsId, id }, { getState, dispatch }) => {
    // await dispatch(setIsLoadingSidebar(true));

    const { data } = await axios.get(ApiRoutes.assignmentsEndPoint(wsId) + id);

    // await dispatch(setIsLoadingSidebar(false));

    return { id, changes: { ...data, fullData: true } };
  }
);

export const setAssignment = createAsyncThunk(
  'assignmentsApp/assignments/setAssignment',
  async ({ wsId, assignment }, { getState, dispatch }) => {
    await dispatch(setIsLoadingSidebar(true));

    try {
      const { data } = await axios.put(`${ApiRoutes.assignmentsEndPoint(wsId) + assignment.id}/`, {
        title: assignment.title,
        user: assignment.user,
        status: assignment.status,
        description: assignment.description,
        observation: assignment.observation,
        forms: assignment.forms,
        project: assignment.project,
        client: assignment.client,
        place: assignment.place,
        date_due: assignment.date_due,
        tags: assignment.tags,
      });

      await dispatch(setIsLoadingSidebar(false));

      return { id: assignment.id, changes: data };
    } catch ({ response: { data } }) {
      await dispatch(setIsLoadingSidebar(false));
      dispatch(showMessage({ message: t(`assignmentApp:${data.title[0]}`), variant: 'error' }));
      throw new Error(':(');
    }
  }
);

export const duplicateAssignment = createAsyncThunk(
  'assignmentsApp/assignments/duplicateAssignment',
  async ({ wsId, assignmentId, newAssignmentId }, { getState, dispatch }) => {
    await dispatch(setIsLoadingSidebar(true));

    try {
      const { data } = await axios.put(
        `${ApiRoutes.assignmentsEndPoint(wsId) + assignmentId}/duplicate/`,
        {
          new_id: newAssignmentId,
        }
      );

      await dispatch(setIsLoadingSidebar(false));

      const { entities } = getState().assignmentsApp.assignments;
      const assignments = Object.keys(entities).map((key) => entities[key]);

      return [data, ...assignments];
    } catch ({ response: { data } }) {
      await dispatch(setIsLoadingSidebar(false));
      dispatch(showMessage({ message: t(`assignmentApp:${data.title[0]}`), variant: 'error' }));
      throw new Error(':(');
    }
  }
);

export const addAssignment = createAsyncThunk(
  'assignmentsApp/assignments/addAssignment',
  async ({ wsId, assignment }, { getState, dispatch }) => {
    await dispatch(setIsLoadingSidebar(true));

    try {
      const { data } = await axios.post(ApiRoutes.assignmentsEndPoint(wsId), {
        id: assignment.id,
        user: assignment.user,
        title: assignment.title,
        status: assignment.status,
        description: assignment.description,
        observation: assignment.observation,
        forms: assignment.forms,
        project: assignment.project,
        client: assignment.client,
        place: assignment.place,
        date_due: assignment.date_due,
        tags: assignment.tags,
      });

      await dispatch(setIsEditingSidebar(false));
      await dispatch(setIsLoadingSidebar(false));
      const { entities } = getState().assignmentsApp.assignments;
      const assignments = Object.keys(entities).map((key) => entities[key]);

      return [data, ...assignments];
    } catch ({ response: { data } }) {
      await dispatch(setIsLoadingSidebar(false));
      dispatch(showMessage({ message: t(`assignmentApp:${data.title[0]}`), variant: 'error' }));
      throw new Error(':(');
    }
  }
);

export const deleteAssignment = createAsyncThunk(
  'assignmentsApp/assignments/deleteAssignment',
  async ({ wsId, assignmentId }, { getState, dispatch }) => {
    await dispatch(setIsLoadingSidebar(true));

    await axios.delete(`${ApiRoutes.assignmentsEndPoint(wsId) + assignmentId}/`);
    await dispatch(setIsLoadingSidebar(false));

    return assignmentId;
  }
);

const assignmentsAdapter = createEntityAdapter({});

export const selectAssignmentsData = ({ assignmentsApp }) => assignmentsApp.assignments;

export const {
  selectAll: selectAssignments,
  selectEntities: selectAssignmentsEntities,
  selectById: selectAssignmentsById,
} = assignmentsAdapter.getSelectors(selectAssignmentsData);

const initialState = assignmentsAdapter.getInitialState({
  isLoading: true,
  rightSidebarProps: {
    isLoading: false,
    isEditing: false,
  },
  numOfPages: 0,
  count: 0,
  page: 1,
  filterByDateProps: {
    open: false,
  },
  filterByClientProps: {
    open: false,
  },
  removeFiltersConfirmProps: {
    open: false,
  },
  deleteAssignmentConfirmProps: {
    open: false,
    assignmentId: null,
  },
  duplicateAssignmentConfirmProps: {
    open: false,
    assignmentId: null,
  },
  appliedFiltersProps: {
    open: false,
  },
  selectClientProps: {
    open: false,
  },
  selectUserProps: {
    open: false,
  },
});

const asssignmentsSlice = createSlice({
  name: 'assignmentsApp/assignments',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsLoadingSidebar: (state, action) => {
      state.rightSidebarProps.isLoading = action.payload;
    },
    setIsEditingSidebar: (state, action) => {
      state.rightSidebarProps.isEditing = action.payload;
    },
    setFilterByDateProps: (state, action) => {
      state.filterByDateProps = action.payload;
    },
    setFilterByClientProps: (state, action) => {
      state.filterByClientProps = action.payload;
    },
    setNumOfPages: (state, action) => {
      state.numOfPages = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    openRemoveFiltersConfirmModal: (state, action) => {
      state.removeFiltersConfirmProps = {
        open: true,
      };
    },
    closeRemoveFiltersConfirmModal: (state, action) => {
      state.removeFiltersConfirmProps = {
        open: false,
      };
    },
    openDeleteAssignmentConfirmModal: (state, action) => {
      state.deleteAssignmentConfirmProps = {
        open: true,
        assignmentId: action.payload,
      };
    },
    closeDeleteAssignmentConfirmModal: (state, action) => {
      state.deleteAssignmentConfirmProps = {
        open: false,
        assignmentId: null,
      };
    },
    openDuplicateAssignmentConfirmModal: (state, action) => {
      state.duplicateAssignmentConfirmProps = {
        open: true,
        assignmentId: action.payload,
      };
    },
    closeDuplicateAssignmentConfirmModal: (state, action) => {
      state.duplicateAssignmentConfirmProps = {
        open: false,
        assignmentId: null,
      };
    },
    openAppliedFiltersModal: (state, action) => {
      state.appliedFiltersProps = {
        open: true,
      };
    },
    closeAppliedFiltersModal: (state, action) => {
      state.appliedFiltersProps = {
        open: false,
      };
    },
    openSelectClientModal: (state, action) => {
      state.selectClientProps = {
        open: true,
      };
    },
    closeSelectClientModal: (state, action) => {
      state.selectClientProps = {
        open: false,
      };
    },
    openSelectUserModal: (state, action) => {
      state.selectUserProps = {
        open: true,
      };
    },
    closeSelectUserModal: (state, action) => {
      state.selectUserProps = {
        open: false,
      };
    },
    resetAssignments: () => initialState,
  },
  extraReducers: {
    [getAssignments.fulfilled]: assignmentsAdapter.setAll,
    [getAssignment.fulfilled]: assignmentsAdapter.addOne,
    [duplicateAssignment.fulfilled]: assignmentsAdapter.setAll,
    [setAssignment.fulfilled]: assignmentsAdapter.updateOne,
    [addAssignment.fulfilled]: assignmentsAdapter.setAll,
    [getFullDataAssignment.fulfilled]: assignmentsAdapter.updateOne,
    [deleteAssignment.fulfilled]: assignmentsAdapter.removeOne,
  },
});

export const {
  setIsLoading,
  setIsLoadingSidebar,
  setFilterByDateProps,
  setFilterByClientProps,
  setNumOfPages,
  setPage,
  setCount,
  openRemoveFiltersConfirmModal,
  closeRemoveFiltersConfirmModal,
  openAppliedFiltersModal,
  closeAppliedFiltersModal,
  resetAssignments,
  openSelectClientModal,
  closeSelectClientModal,
  openSelectUserModal,
  closeSelectUserModal,
  setIsEditingSidebar,
  closeDeleteAssignmentConfirmModal,
  openDeleteAssignmentConfirmModal,
  closeDuplicateAssignmentConfirmModal,
  openDuplicateAssignmentConfirmModal,
} = asssignmentsSlice.actions;

export default asssignmentsSlice.reducer;
